import {createStore} from "vuex";
import users from "./user/users"
import notebooks from "./notebook/notebooks"
import notes from "./note/notes";
import sections from "./section/sections"
import images from "./images/images"
import folders from "./folder/folders"

export default createStore({
    modules: {
        users,
        folders,
        notebooks,
        notes,
        sections,
        images
    }
})
