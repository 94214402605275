<template>
  <editor-content :editor="editor"/>

  <div>
    {{ getHtml() }}
  </div>
</template>

<script>
import StarterKit from '@tiptap/starter-kit'
import Document from '@tiptap/extension-document'
import Text from '@tiptap/extension-text'
import {Editor, EditorContent} from '@tiptap/vue-3'

import FabricNode from './FabricNode'

export default {
  components: {
    EditorContent,
  },

  props: {
    contentValue: {
      type: String,
      default: '<p>abcde</p> <div data-type="fabric"></div>',
      // default: '<p>abcde</p>',
    },
  },

  data() {
    return {
      editor: null,
    }
  },

  watch: {
    contentValue(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.htmlEditor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Text,
        // Document.extend({
        //   content: 'fabric',
        // }),
        FabricNode,
      ],
      content: this.contentValue,
    })

    this.editor.setEditable(true)
  },

  beforeUnmount() {
    this.editor.destroy()
  },

  methods: {
    getHtml() {
      return this.editor ? this.editor.getHTML() : ''
    }
  }
}
</script>
