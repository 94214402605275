<template>

  <div ref="tableMenuRef"
       class="table-menu absolute bg-transparent"
       :style='{display: appearance.wrapper.display,
       left: appearance.wrapper.left,
       top: appearance.wrapper.top,
       width: appearance.wrapper.width,
       height: appearance.wrapper.height}'
       @resize="onResize">

    <div class="w-full inline-block"
         :style="{width: appearance.horizontalMenu.width}"
         @click.stop="">
      <div data-drag-handle
           class="table-drag-handle cursor-move bg-gray-200 border-gray-200 rounded-tl"
           contenteditable="false"
           draggable="true"
           style="float: left; display: block; width: 1.5rem; height: 1.5rem">
      </div>
      <div class="flex border-l border-gray-200">
        <div v-for="(colRect, index) in appearance.columns"
             class="table-menu-button bg-gray-100 border-b border-t border-r border-gray-200 gn-v-center cursor-pointer"
             style="float: left; display: block; height: 1.5rem;"
             :style="{width: colRect.width, display: colRect.width > 0 ? 'flex' : 'none'}"
             @click.stop="onClickColumn(index)">
          <div v-if="index === 0" class="table-menu-button table-add-col-row-btn -ml-2"
               @click.stop="onAddColumnClicked(-1)">
            <font-awesome-icon icon="circle-plus" class="pointer-events-none"/>
          </div>
          <div class="table-menu-button table-add-col-row-btn -mr-2 ml-auto"
               @click.stop="onAddColumnClicked(index)">
            <font-awesome-icon icon="circle-plus" class="pointer-events-none"/>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-green-100 border-t border-gray-200 cursor-pointer"
         style="width: 1.5rem"
         :style="{height: appearance.verticalMenu.height}">
      <div v-for="(rowRect, index) in appearance.rows"
           class="table-menu-button bg-gray-100 border-l border-b border-r border-gray-200 justify-center cursor-pointer"
           style="width: 1.5rem; display: block"
           :style="{height: rowRect.height, display: rowRect.height > 0 ? 'flex' : 'none'}"
           @click.stop="onClickRow(index)">
        <div v-if="index === 0" class="table-menu-button table-add-col-row-btn absolute -mt-2"
             @click.stop="onAddRowClicked(-1)">
          <font-awesome-icon icon="circle-plus" class="pointer-events-none"/>
        </div>
        <div class="table-menu-button table-add-col-row-btn -mb-2 mt-auto"
             @click.stop="onAddRowClicked(index)">
          <font-awesome-icon icon="circle-plus" class="pointer-events-none"/>
        </div>
      </div>
    </div>

  </div>

</template>

<script setup>

import {ref, reactive, computed, onMounted, defineComponent} from "vue";
import {findAllCell} from "@/components/HtmlEditor/extensions/table/TableNodeViewEx";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {convertRemToPixels} from "@/utils/util"

const props = defineProps({
  editor: null,
  node: null,
  getPos: null, // function. table node 의 position 을 구할 수 있다. 다만 + 1 해야 한다. 현재 사용처는 없음
  cellMinWidth: 25,
})

const tableMenuRef = ref(null)

const isShown = computed(() => {
  return appearance.wrapper.display === 'block'
});

const dummyRect = {
  left: 0, top: 0, width: 0, height: 0
}

const menuHeight = convertRemToPixels(1.5)
const dragHandleWidth = convertRemToPixels(1.5)

const appearance = reactive({
  wrapper: {
    display: 'none',
    left: '',
    top: '',
    width: '',
    height: '',
  },
  horizontalMenu: {
    width: '',
  },
  verticalMenu: {
    height: '',
  },
  // TODO 10개로 하드코딩 되어 있음. 동적으로 늘어나게 수정 필요
  columns: [dummyRect, dummyRect, dummyRect, dummyRect, dummyRect, dummyRect, dummyRect, dummyRect, dummyRect, dummyRect],
  rows: [dummyRect, dummyRect, dummyRect, dummyRect, dummyRect, dummyRect, dummyRect, dummyRect, dummyRect, dummyRect]
})

onMounted(() => {
  props.editor.on('blur', () => hide())
  props.editor.on('noTableSelected', () => hide())
  props.editor.on('tableSelected', ({editor, wrapperDomId, $cellPos, cellDom}) => {
    const tableWrapperDom = getTableWrapperDom()
    if (tableWrapperDom && tableWrapperDom.id === wrapperDomId) {
      show()
    } else {
      hide()
    }
  })
})

function getTableWrapperDom() {
  return tableMenuRef.value.parentElement
}

function getTableDom() {
  const tableWrapperDom = getTableWrapperDom()
  if (!tableWrapperDom) return;

  return tableWrapperDom.getElementsByTagName('table')[0]
}

function getTbodyDom() {
  const tableWrapperDom = getTableWrapperDom()
  if (tableWrapperDom) {
    return tableWrapperDom.getElementsByTagName('tbody')[0]
  }
}

function show() {
  if (isShown.value) return;

  const tableDom = getTableDom()
  if (!tableDom) return

  const tbodyDom = getTbodyDom()

  // drag handle : 높이 1.5 rem, 너비 1.5 rem
  // 가로 메뉴 : 높이 1.5rem 너비 drag handle + 테이블 가로
  const editorRect = props.editor.view.dom.getBoundingClientRect()

  const tableRect = tableDom.getBoundingClientRect()
  const tableWidth = tableRect.width + dragHandleWidth
  const tableHeight = tableRect.height + menuHeight

  appearance.wrapper.display = 'block'
  appearance.wrapper.left = (tableRect.left - editorRect.left - dragHandleWidth) + 'px'
  appearance.wrapper.top = (tableRect.top - editorRect.top - (menuHeight)) + 'px'
  appearance.wrapper.width = tableWidth + 'px'
  appearance.wrapper.height = tableHeight + 'px'

  appearance.verticalMenu.height = tableHeight - menuHeight + 'px'

  setHorizontalMenu(tbodyDom)
  setVerticalMenu(tbodyDom)
}

function setHorizontalMenu(tbodyDom) {
  const firstRow = tbodyDom.children[0]

  appearance.horizontalMenu.width = 0
  let totalWidth = 0
  for (let i = 0; i < firstRow.children.length; i++) {
    const td = firstRow.children[i]
    const cellRect = td.getBoundingClientRect()
    const rect = {
      left: cellRect.left,
      top: cellRect.top,
      width: cellRect.width,
      height: cellRect.height
    }

    totalWidth += rect.width

    appearance.columns[i] = rect
  }

  appearance.horizontalMenu.width = dragHandleWidth + totalWidth
}

function setVerticalMenu(tbodyDom) {
  for (let i = 0; i < tbodyDom.children.length; i++) {
    const row = tbodyDom.children[i]
    const rect = row.getBoundingClientRect()
    appearance.rows[i] = rect
  }
}

function onResize() {
  if (!isShown.value) return;
  resizeTableMenu()
}

function resizeTableMenu() {
  const tbodyDom = getTbodyDom()
  if (tbodyDom) {
    setHorizontalMenu(tbodyDom)
    setVerticalMenu(tbodyDom)
  }
}

function hide() {
  appearance.wrapper.display = 'none'
}


function onClickColumn(index) {
  selectColumns(index)
}

function selectColumns(index) {
  if (props.editor.state.selection && props.editor.state.selection.$anchor) {
    const cellPos = findAllCell(props.editor, index, true)
    if (cellPos.length >= 1) {
      props.editor.commands.setCellSelection({anchorCell: cellPos[0], headCell: cellPos[cellPos.length - 1]})
    }
  }
}

function selectRows(index) {
  if (props.editor.state.selection && props.editor.state.selection.$anchor) {
    const cellPos = findAllCell(props.editor, index, false)
    if (cellPos.length >= 1) {
      props.editor.commands.setCellSelection({anchorCell: cellPos[0], headCell: cellPos[cellPos.length - 1]})
    }
  }
}

function onClickRow(index) {
  selectRows(index)
}

function onAddColumnClicked(index) {
  if (props.editor.state.selection && props.editor.state.selection.$anchor) {
    const addAfter = index >= 0
    const targetColumnIndex = addAfter ? index : 0
    const cellPos = findAllCell(props.editor, targetColumnIndex, true)
    if (cellPos.length >= 1) {
      props.editor.commands.setCellSelection({anchorCell: cellPos[0]})
      let ret
      if (addAfter) {
        ret = props.editor.commands.addColumnAfter()
      } else {
        ret = props.editor.commands.addColumnBefore()
      }

      if (ret) {
        const tableDom = getTableDom()
        if (tableDom) {
          const tableWidth = tableDom.getBoundingClientRect().width
          tableDom.style.width = (tableWidth + props.cellMinWidth) + 'px'
        }
      }

      setCursorToCell(cellPos[0])
      resizeTableMenu()
    }
  }
}

function onAddRowClicked(index) {
  if (props.editor.state.selection && props.editor.state.selection.$anchor) {
    const addAfter = index >= 0
    const targetColumnIndex = addAfter ? index : 0
    const cellPos = findAllCell(props.editor, targetColumnIndex, false)
    if (cellPos.length >= 1) {
      props.editor.commands.setCellSelection({anchorCell: cellPos[0]})
      if (addAfter) {
        props.editor.commands.addRowAfter()
      } else {
        props.editor.commands.addRowBefore()
      }
      setCursorToCell(cellPos[0])
      resizeTableMenu()
    }
  }
}

function setCursorToCell(pos) {
  props.editor.commands.setTextSelection(pos + 2) // pos 는 tr 이고 +2 해야 cell
}

</script>

<style lang="scss" scoped>

.table-drag-handle {
  width: 1.5rem;
  height: 1.5rem;
  //background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path fill-opacity="0.2" d="M4 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2" /></svg>');
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>');
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  background-position: center;
}

</style>
