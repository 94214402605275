import './tailwind.css';
import './main/custom.css';
import {createApp} from 'vue'
import App from './App.vue'
import {router} from './router/index.js'
import store from "./store";
import vue3GoogleLogin from 'vue3-google-login'

import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import {Icon, library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import '@mdi/font/css/materialdesignicons.css'
import axios from "./main/axios.js"
import vuetify from "./main/useVuetify"
import 'tippy.js/dist/tippy.css';

library.add(fas, far);

const app = createApp(App)

app.config.globalProperties.$http = axios;
app.component('font-awesome-icon', FontAwesomeIcon)
    .use(router)
    .use(store)
    .use(vue3GoogleLogin, {
        clientId: '396231924972-edobt20a7g26na24j68hbai8rcifnblc.apps.googleusercontent.com'
    })
    .use(vuetify)
    .mount('#app')
