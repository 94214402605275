import {Node} from 'prosemirror-model';
import {Decoration, DecorationSet, NodeView} from 'prosemirror-view';
import {findParentNodeClosestToPos, NodeView as TiptapNodeView} from '@tiptap/core'
import {CellAttrs, cellAround} from 'prosemirror-tables';
import {Editor} from "@tiptap/core"
import {v4 as uuid} from "uuid"
import {Component} from "vue";
import {VueRenderer, VueRendererOptions} from '@tiptap/vue-3'
import {NodeViewRendererProps} from "@tiptap/core/src/types";
import {lowerFirst, throttle} from "lodash-es";
import {TableMap, CellSelection} from "prosemirror-tables";
import {types} from "sass";
import Boolean = types.Boolean;

/**
 * @public
 */
export class TableNodeViewEx implements NodeView {
    editor: Editor;
    node: Node;
    public dom: HTMLDivElement;
    public table: HTMLTableElement;
    public colgroup: HTMLTableColElement;
    public contentDOM: HTMLTableSectionElement;
    getPos: Function
    resizeObserver: any
    tbody: HTMLTableSectionElement

    constructor(params,
                public cellMinWidth: number,
                menuComponent: Component) {
        this.editor = params.editor
        this.node = params.node
        this.getPos = params.getPos

        this.dom = document.createElement('div');
        this.dom.setAttribute('data-node-view-wrapper', '')
        this.dom.className = 'table-wrapper'
        this.dom.id = uuid()

        this.addMenu(menuComponent)

        this.table = this.dom.appendChild(document.createElement('table'));
        this.table.setAttribute('data-node-view-content', '')

        this.colgroup = this.table.appendChild(document.createElement('colgroup'));
        updateColumnsOnResize(this.node, this.colgroup, this.table, cellMinWidth);

        this.tbody = document.createElement('tbody')
        // contentDOM 아래에 <tr> 이 붙는다.
        this.contentDOM = this.table.appendChild(this.tbody);

        this.initResizeObserver(this.tbody)
    }

    addMenu(menuComponent) {
        const menuComponentRender = new VueRenderer(menuComponent,
            {
                props: {
                    editor: this.editor,
                    node: this.node,
                    getPos: this.getPos,
                    cellMinWidth: this.cellMinWidth
                },
                editor: this.editor
            }
        )

        const menuDom = menuComponentRender.element as HTMLElement

        const dragHandle = menuDom.getElementsByClassName('table-drag-handle')[0] as HTMLElement

        dragHandle.addEventListener('dragend', (event) => {
            this.onDropTable(event)
        })

        this.dom.appendChild(menuDom)
    }

    private initResizeObserver(tbody) {
        this.resizeObserver = new ResizeObserver(
            throttle(() => {
                this.onTableResize()
            }, 10)
        )

        this.resizeObserver.observe(tbody)
    }

    private onTableResize() {
        const tableWrapperDom = this.table.parentElement
        if (tableWrapperDom) {
            const tableMenu = tableWrapperDom.getElementsByClassName('table-menu')[0] as HTMLElement
            const event = new CustomEvent('resize')
            tableMenu.dispatchEvent(event)
        }
    }

    onDropTable(event) {
        const found = this.editor.view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
        });

        if (!found) return
        const {pos} = found
        const targetPos = pos
        const orgPos = this.getPos()

        if (targetPos === orgPos) {
            console.log('not into same table')
            return
        }

        // console.log(editor.view.state.doc.resolve(pos))
        // console.log('prev ' + this.getPos() + ' cur ' + targetPos)
        // console.log(this.editor.view.state.doc.resolve(targetPos))
        const $cellInDropPos = cellAround(this.editor.view.state.doc.resolve(targetPos));
        // console.log($cellInDropPos)
        // const currentTableRange = {start: this.getPos(), end: this.getPos() + this.node.nodeSize}
        // const newTableRange = {start: pos, end: pos + this.node.nodeSize}
        // console.log(currentTableRange)
        // console.log(newTableRange)
        //
        // const overlapWithCurrentTable = !(newTableRange.end < currentTableRange.start) || !(newTableRange.start > currentTableRange.end)
        // console.log(pos + ' ' + this.getPos() + ' ' + (this.getPos()+this.node.nodeSize))
        // console.log(overlapWithCurrentTable)
        // table 이 아닌 곳에만 drop
        if (!$cellInDropPos) {
            const slice = this.editor.state.selection.content()
            const sliceSize = slice.content.size
            const dropPos = (targetPos < orgPos) ? targetPos : (targetPos - sliceSize)
            const tr = this.editor.state.tr
            tr.delete(orgPos, orgPos + sliceSize)
            tr.insert(dropPos, slice.content)
            this.editor.view.dispatch(tr)
        } else {
            console.log('not into table')
        }
    }

    update(node: Node): boolean {
        if (node.type != this.node.type) return;
        this.node = node;
        // 여기서 updateColumnsOnResize 호출 안하면 column 추가하고 resize 핸들러 거드리면 table node 가 다시 생성된다.
        updateColumnsOnResize(node, this.colgroup, this.table, this.cellMinWidth);
        return true;
    }

    ignoreMutation(record: MutationRecord): boolean {
        return (
            record.type == 'attributes' &&
            (record.target == this.table || this.dom.contains(record.target))
        );
    }

    destroy() {
        const menuDom = this.dom.getElementsByClassName('table-menu')[0] as HTMLElement
        if (menuDom) {
            menuDom.removeEventListener('dragend', this.onDropTable)
        }

        if (this.resizeObserver) {
            this.resizeObserver.unobserve(this.tbody as HTMLElement)
        }
    }
}

export function updateColumnsOnResize(
    node: Node,
    colgroup: HTMLTableColElement,
    table: HTMLTableElement,
    cellMinWidth: number,
    overrideCol?: number,
    overrideValue?: number,
): void {
    let totalWidth = 0;
    // let fixedWidth = true;
    let nextDOM = colgroup.firstChild as HTMLElement;
    const row = node.firstChild;
    if (!row) return;

    for (let i = 0, col = 0; i < row.childCount; i++) {
        const {colspan, colwidth} = row.child(i).attrs as CellAttrs;
        for (let j = 0; j < colspan; j++, col++) {
            const hasWidth = overrideCol == col ? overrideValue : colwidth && colwidth[j];
            const cssWidth = hasWidth ? hasWidth + 'px' : '';
            totalWidth += hasWidth || cellMinWidth;
            // if (!hasWidth) fixedWidth = false;
            if (!nextDOM) {
                colgroup.appendChild(document.createElement('col')).style.width = cssWidth;
            } else {
                if (nextDOM.style.width != cssWidth) nextDOM.style.width = cssWidth;
                nextDOM = nextDOM.nextSibling as HTMLElement;
            }
        }
    }

    while (nextDOM) {
        const after = nextDOM.nextSibling;
        nextDOM.parentNode?.removeChild(nextDOM);
        nextDOM = after as HTMLElement;
    }

    // fixed table width.
    // table width 가 설정되어 있고
    // table-layout: fixed 로 설정하여 (HtmlEditor tiptap.table 속성에 넣었음)
    // 셀에 텍스트 입력하면서 셀이 가로로 늘어나지 않도록 한다.
    table.style.width = totalWidth + 'px';
    table.style.minWidth = '';

    // if (fixedWidth) {
    //     table.style.width = totalWidth + 'px';
    //     table.style.minWidth = '';
    // } else {
    //     table.style.width = '';
    //     table.style.minWidth = totalWidth + 'px';
    // }

    // onUpdateColumnWidth(table)
}

export function findAllCell(editor: Editor, index: Number, findColumn: Boolean) {
    const {node, start} =
        findParentNodeClosestToPos(
            editor.state.selection.$anchor,
            (node) => node.type.name === "table"
        )

    if (!node) {
        return []
    }

    const table = node
    const tableStart = start

    const map = TableMap.get(table)

    // console.log(node)
    // node.content.forEach((childNode, childOffset) => {
    //     console.log(childNode)
    //     console.log(childOffset)
    // })
    const ret = []

    // 0 : 0 1 2 3 4
    // 1 : 0 1 2 3 4
    // 2 : 0 1 2 3 4
    for (let i = 0; i < map.height; i++) {
        for (let j = 0; j < map.width; j++) {
            if (findColumn) {
                if (j === index) {
                    const cellPos = map.map[i * map.width + j]
                    ret.push(tableStart + cellPos)
                }
            } else {
                // find row
                if (i == index) {
                    const cellPos = map.map[i * map.width + j]
                    ret.push(tableStart + cellPos)
                }
            }
        }
    }

    return ret
}

export function getSelectedCell(state) {
    if (state.selection instanceof CellSelection) {
        return [
            state.selection.$anchorCell.pos,
            state.selection.$headCell.pos
        ]
    } else {
        return []
    }
}

export function findSelectedCells(editor) {
    const {node, start} =
        findParentNodeClosestToPos(
            editor.state.selection.$anchor,
            (node) => node.type.name === "table"
        )

    const table = node
    const map = TableMap.get(table);
    const tableStart = start

    for (let i = 0; i < map.height; i++) {
        for (let j = 0; j < map.width; j++) {
            const cellPos = map.map[i * map.width + j]
            console.log(table.nodeAt(cellPos)!.className)
        }
    }
}

export function selectCell(editor, $cellPos) {
    // const state = editor.state
    // if (!(state.selection instanceof CellSelection)) {
    //     editor.commands.setCellSelection( {anchorCell: state.$cellPos.pos})
    // } else {
    //     const cells = []
    //     state.selection.forEachCell((node, pos) => {
    //         cells.push(
    //             Decoration.node(pos, pos + node.nodeSize, { class: 'selectedCell' }),
    //         );
    //     })
    //     DecorationSet.create(editorState.doc, cells)
    // }
}

// export function findConcealMarkInCellNode(cellNode: Node) {
//     let hasConceal = false
//     cellNode.descendants((node, pos, parent, index) => {
//         console.log(node)
//         if (node.marks.find(mark => mark.type.name === 'conceal')) {
//             hasConceal = true
//             return false
//         }
//     })
//
//     return hasConceal
// }
