import {fabric} from 'fabric';
import BaseHandler from "@/components/ImageEditor/controls/draw/BaseHandler";

class PanHandler extends BaseHandler {
    private isDragging: Boolean
    lastPointX: any
    lastPointY: any

    constructor(canvas: fabric.Canvas) {
        super(canvas, null)
        this.isDragging = false
    }

    prepare() {
        this.canvas.isDrawingMode = false
    }

    mouseDown(ie) {
        this.isDragging = true;
        this.canvas.selection = false;
        this.lastPointX = ie.e.clientX
        this.lastPointY = ie.e.clientY
    }

    mouseMove(ie) {
        const { canvas } = this

        if (this.isDragging) {
            const delta = new fabric.Point(ie.e.movementX, ie.e.movementY);
            canvas.relativePan(delta);

            let vpt = canvas.viewportTransform!!;
            // const curPointX = e.clientX
            // const curPointY = e.clientY
            // vpt[4] += ((curPointX - this.lastPointX));
            // vpt[5] += ((curPointY - this.lastPointY));

            const rightEndPoint = canvas.getWidth() * (vpt[0] - 1);
            if(vpt[4] >= 0 || -rightEndPoint > vpt[4]) {
                vpt[4] = (vpt[4] >= 0) ? 0 : -rightEndPoint;
            }

            const bottomEndPoint = canvas.getHeight() * (vpt[0] - 1);
            if(vpt[5] >= 0 || -bottomEndPoint > vpt[5]) {
                vpt[5] = (vpt[5] >= 0) ? 0 : -bottomEndPoint;
            }

            // canvas.requestRenderAll();
            // this.lastPointX = e.clientX
            // this.lastPointY = e.clientY
        }
    }

    mouseUp(ie) {
        if (this.isDragging) {
            this.canvas.setViewportTransform(this.canvas.viewportTransform!!);
            this.isDragging = false;
            this.canvas.selection = true;
        }
    }
}

export default PanHandler
