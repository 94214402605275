import {Editor} from '@tiptap/vue-3'

export class ConcealManager {
    tiptapEditor: any
    revealingConcealInfoArray: Array<ConcealInfo>
    isScribbleMode: boolean

    constructor(editor) {
        this.tiptapEditor = editor
        this.revealingConcealInfoArray = new Array<ConcealInfo>()
        this.isScribbleMode = false
    }

    setScribbleMode(scribbleMode) {
        this.isScribbleMode = scribbleMode
        if (!scribbleMode) {
            this.revealingConcealInfoArray = []
        }
    }

    hasConcealText() {
        return this.revealingConcealInfoArray.length > 0
    }

    revealConcealTextTemporary(info) {
        const concealTextInfo = new ConcealInfo(info, null)
        this.revealingConcealInfoArray.push(concealTextInfo)
        concealTextInfo.revealOrSetConcealText(this.tiptapEditor,true)

        const displayTime = (concealTextInfo.text.length / 20 + 2) * 1000
        setTimeout(() => {
            if (this.isScribbleMode) {
                const target = this.findRevealingConcealText(concealTextInfo)
                if (target) {
                    target.revealOrSetConcealText(this.tiptapEditor, false)
                    this.removeRevelingConcealText(concealTextInfo)
                }
            }
        }, displayTime)
    }

    revealConcealRectTemporary(obj) {
        const concealRectInfo = new ConcealInfo(null, obj);
        this.revealingConcealInfoArray.push(concealRectInfo)
        concealRectInfo.revealOrSetConcealRect(true)
        setTimeout(() => {
            if (this.isScribbleMode) {
                const target = this.findRevealingConcealRect(concealRectInfo)
                if (target) {
                    concealRectInfo.revealOrSetConcealRect(false)
                    this.removeRevealingConcealRect(concealRectInfo)
                }
            }
        }, 2000)
    }

    removeRevelingConcealText(concealTextInfo) {
        const index = this.revealingConcealInfoArray.findIndex(info => info.text && info.from === concealTextInfo.from && info.to === concealTextInfo.to)
        if (index > -1) {
            this.revealingConcealInfoArray.splice(index, 1)
        }
    }

    findRevealingConcealText(concealTextInfo) {
        return this.revealingConcealInfoArray.find(info => info.text && info.from === concealTextInfo.from && info.to === concealTextInfo.to)
    }

    removeRevealingConcealRect(concealRectInfo) {
        const index = this.revealingConcealInfoArray.findIndex(info => info.rectObj && info.rectObj.id === concealRectInfo.rectObj.id)
        if (index > -1) {
            console.log('rm')
            this.revealingConcealInfoArray.splice(index, 1)
        }
    }

    findRevealingConcealRect(concealRectInfo) {
        return this.revealingConcealInfoArray.find(info => info.rectObj && info.rectObj.id === concealRectInfo.rectObj.id)
    }

    changeLastConcealTextBorderColor(color) {
        if (this.revealingConcealInfoArray.length <=0) {
            return;
        }

        const concealInfo = this.revealingConcealInfoArray[this.revealingConcealInfoArray.length-1]

        if (concealInfo.text) {
            const {from, to} = concealInfo
            const state = this.tiptapEditor.state;
            const tr = state.tr

            concealInfo.attrs.borderColor = color // 타이머에 의해서 conceal이 다시 생성될 때 color 를 유지하도록 저장
            const concealWithAttr = state.schema.marks.conceal.create({conceal: false, borderColor: color})
            tr.addMark(from, to, concealWithAttr)
            this.tiptapEditor.view.dispatch(tr)
        } else if (concealInfo.rectObj) {
            concealInfo.rectObj.set({
                stroke: color,
            })
            concealInfo.rectObj.canvas.requestRenderAll()
            // TODO. json 내용도 업데이트 해야 하는데..
            // FabricComponent 에서 updateStrokeColorInJson
        }
    }

    unsetLastConcealInfo() {
        if (this.revealingConcealInfoArray.length <= 0) {
            return;
        }

        const concealInfo = this.revealingConcealInfoArray[this.revealingConcealInfoArray.length-1]

        if (concealInfo.text) {
            this.removeRevelingConcealText(concealInfo)
            const state = this.tiptapEditor.state;
            const markType = state.schema.marks['conceal']
            const {from, to} = concealInfo
            const tr = state.tr
            tr.removeMark(from, to, markType)
            this.tiptapEditor.view.dispatch(tr)
            this.tiptapEditor.emit('onRemoveConcealText', concealInfo)
        } else if (concealInfo.rectObj) {
            this.removeRevealingConcealRect(concealInfo)
            const canvas = concealInfo.rectObj.canvas
            canvas.remove(concealInfo.rectObj)
            // TODO. json 내용도 업데이트 해야 하는데..
            // FabricComponent 에서 updateStrokeColorInJson
        }
    }
}

export class ConcealInfo {
    text: String
    rectObj: any
    from: number
    to: number
    attrs: {}
    rect: {}

    constructor(text, rectObj) {
        if (text) {
            this.rectObj = null
            this.text = text.text
            this.from = text.from
            this.to = text.to
            this.attrs = text.attrs
            this.rect = text.rect
        } else {
            this.text = null
            this.rectObj = rectObj
        }
    }

    revealOrSetConcealText(editor, reveal) {
        const state = editor.state;
        const tr = state.tr
        const revealedConceal = state.schema.marks.conceal.create({
            conceal: !reveal,
            borderColor: this.attrs.borderColor
        })
        tr.addMark(this.from, this.to, revealedConceal)
        editor.view.dispatch(tr)
    }

    revealOrSetConcealRect(reveal) {
        const color = reveal ? 'rgba(255,255,255,0)' : 'rgba(255,255,255,255)'
        this.rectObj.set({
            fill: color
        })

        if (this.rectObj.canvas) {
            this.rectObj.canvas.requestRenderAll()
        }
    }
}
