import noteApi from "../../api/noteApi"

const state = {
    noteBooks: []
    // noteBooks: [
    //     {
    //         id: 0,
    //         note: [
    //             {
    //                 id: 0,
    //                 title: '',
    //                 sections: [
    //                     {
    //                         id: 0,
    //                         content: ''
    //                     }
    //                 ]
    //             }
    //         ]
    //     },
    // ]
}

const getters = {
    getNote: (state) => (notebookId, noteId) => {
        const notebook = state.noteBooks.find(notebook => notebook.id === notebookId)
        if (notebook) {
            return notebook.notes.find(note => note.id === noteId)
        } else {
            return null
        }
    }
}

const actions = {
    clearAll({commit}) {
        commit('clearAll')
    },

    async loadNote({commit}, noteId) {
        const note = await noteApi.getNoteWithSections(noteId)
        if (note !== null) {
            commit('updateNotebook', note)
        }
        return note
    },
    addSection({commit}, data) {
        commit('addSection', data)
    },
    updateSection({commit}, data) {
        commit('updateSection', data)
    },
    async moveSection({commit}, data) {
        const result = await noteApi.moveSection(data)
        if (result) {
            commit('moveSection', data)
        }
        return result
    },
    async updateNoteTitle({commit}, data) {
        const note = await noteApi.updateNoteTitle(data.noteId, data.notebookId, data.title)
        if (note !== null) {
            commit('folders/setNotebookContentsIsDirty', note.notebookId, {root: true})
        }
        return note
    },
    async deleteNote({commit}, data) {
        const result = await noteApi.delete(data.noteId)
        if (result !== null) {
            commit('deleteNote', data)
            commit('folders/setNotebookContentsIsDirty', data.notebookId, {root: true})
        }
        return result
    }
}

const mutations = {
    clearAll(state) {
        state.noteBooks = []
    },

    updateNotebook(state, note) {
        const notebook = state.noteBooks.find(nb => nb.id === note.notebookId)
        if (notebook) {
            if (notebook.notes) {
                notebook.notes.push(note)
            } else {
                notebook.notes = [note]
            }
        } else {
            state.noteBooks.push(
                {
                    id: note.notebookId,
                    notes: [note]
                }
            )
        }
    },
    deleteNote(state, {notebookId, noteId}) {
        const notebook = state.noteBooks.find(notebook => notebook.id === notebookId)
        if (notebook) {
            const noteIndex = notebook.notes.findIndex(note => note.id === noteId)
            if (noteIndex >= 0) {
                notebook.notes.splice(noteIndex, 1)
            }
        }
    },
    addSection(state, {notebookId, noteId, index}) {
        const notebook = state.noteBooks.find(notebook => notebook.id === notebookId)
        if (notebook) {
            const note = notebook.notes.find(note => note.id === noteId)
            const section = {id: "", content: ""}
            if (note) {
                if (index === -1) {
                    note.sections.push(section)
                } else {
                    note.sections.splice(index, 0, section)
                }
            }
        }
    },
    updateSection(state, {notebookId, noteId, sectionId, sectionIndex, content}) {
        const notebook = state.noteBooks.find(notebook => notebook.id === notebookId)
        if (notebook) {
            const note = notebook.notes.find(note => note.id === noteId)
            if (note) {
                const updatedSection = {
                    id: sectionId,
                    content: content
                }

                let targetIndex = note.sections.findIndex(s => s.sectionId === sectionId)
                if (targetIndex < 0) {
                    targetIndex = sectionIndex
                }
                note.sections[targetIndex] = updatedSection
            }
        }
    },
    moveSection({commit}, {notebookId, noteId, sectionId, toIndex}) {
        const notebook = state.noteBooks.find(notebook => notebook.id === notebookId)
        if (notebook) {
            const note = notebook.notes.find(note => note.id === noteId)
            if (note) {
                const orgIndex = note.sections.findIndex(s => s.id === sectionId)
                if (orgIndex >= 0 && toIndex >= 0 && toIndex < note.sections.length) {
                    const orgSection = note.sections[orgIndex]
                    note.sections.splice(orgIndex, 1)
                    note.sections.splice(toIndex, 0, orgSection)
                }
            }
        }
    },
    deleteSection(state, {notebookId, noteId, sectionId, sectionIndex}) {
        const notebook = state.noteBooks.find(notebook => notebook.id === notebookId)
        if (notebook) {
            const note = notebook.notes.find(note => note.id === noteId)
            if (note) {
                let targetIndex = note.sections.findIndex(s => s.id === sectionId)
                if (targetIndex < 0) {
                    targetIndex = sectionIndex
                }

                note.sections.splice(targetIndex, 1)
            }
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
