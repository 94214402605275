<template>
  <div class="h-screen pb-36">
    <div v-show="data.inEditImage.imageUrl">
      <ImageEditor :key="data.randomNumberForImageEditor"
                   :image-source="data.inEditImage.imageUrl"
                   :editing="data.inEditImage.editing"
                   @saved="onImageEditorSaved"
                   @closed="onImageEditorClosed">
      </ImageEditor>
    </div>
    <div class="py-2">
      <EditorMenu
          ref="editorMenu"
          class="w-full flex justify-center pb-2 z-10"
          style="box-shadow: 0 4px 2px -2px #ababab88;"
          @modeChanged="onEditorModeChanged"
          @scribbleEditorModeChanged="onScribbleEditorModeChanged">
      </EditorMenu>
    </div>
    <div v-show="!data.inEditImage.imageUrl"
         id="NoteView"
         class="text-center h-full overflow-auto">
      <div class="text-left px-12">
        <div class="my-6 flex justify-between">
          <input
              type="text"
              class="w-96 font-bold text-2xl focus:outline-none"
              maxlength="20"
              ref="titleRef"
              @keyup.enter="onEnterTitle"
              @blur="onBlurTitle"
              :value="noteTitle"/>
          <div v-show="data.isWeb" class="flex gn-v-center">
            <v-btn rounded="xl"
                   size="x-small"
                   color="secondary"
                   @click="onAddSectionAfter(-1)">
              섹션 추가
            </v-btn>
            <div>
              <font-awesome-icon icon="ellipsis"
                                 class="cursor-pointer ml-2 text-gray-500 text-lg h-6"></font-awesome-icon>
              <v-menu activator="parent">
                <v-list>
                  <v-list-item
                      v-for="(menu, index) in data.noteMenu"
                      :key="index"
                      :value="index"
                  >
                    <v-list-item-title @click="onNoteMenuClicked(menu.cmd)">
                      {{ menu.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
        <SectionEditor v-for="(section, index) in data.sections"
                           :key=section.id
                           :notebookId="data.notebookId"
                           :noteId="data.noteId"
                           :sectionId=section.id
                           :sectionIndex=index
                           :contentValue="section.content"
                           ref="htmlEditors"
                           @onFocus="onEditorFocus"
                           @onBlur="onEditorBlur"
                           @textAttributeDetected="onTextAttributeDetected"
                           @onAddSectionClicked="onEditorAddSection"
                           @onSectionDeleted="onEditorSectionDeleted"
                           @onEditImageClicked="onEditorImageClicked(index, $event)"/>
      </div>
      <!--      <button v-if="data.note"-->
      <!--              class="mt-6 cursor-pointer"-->
      <!--              @click="onAddSection">-->
      <!--        <font-awesome-icon icon="plus" class="fa-2x"/>-->
      <!--      </button>-->

      <!--          <br>-->
      <!--          <br>-->
      <!--          <div v-for="section in data.sections">-->
      <!--            {{ section.content }}-->
      <!--          </div>-->
    </div>
  </div>
</template>

<script setup>
import SectionEditor from './SectionEditor.vue'
import EditorMenu from '../components/HtmlEditor/NoteMenu/EditorMenu.vue'
import ImageEditor from "../components/ImageEditor/ImageEditor.vue"
import {mapGetters, mapActions, useStore} from "vuex"
import {computed, onMounted, reactive, provide, ref, watch, onBeforeMount} from "vue";
import {useRoute, useRouter} from "vue-router";
import commonAlert from "@/components/CommonAlert";
import cookies from "vue-cookies";
import noteApi from "@/api/noteApi";

const store = useStore()
const route = useRoute()
const router = useRouter()

const data = reactive({
  noteId: null,
  notebookId: null,
  note: null,
  sections: [],
  inEditImage: {
    imageUrl: null,
    editorIndex: null,
    editing: false
  },
  randomNumberForImageEditor: 0,
  isWeb: true,
  noteMenu: [
    {
      title: '노트 삭제',
      cmd: 'DELETE',
    },
    {
      title: '노트 이동',
      cmd: 'MOVE',
    },
  ],
  isScribbleMode: false
})

const titleRef = ref()
const htmlEditors = ref()
const activeEditor = ref(null)
const editorMenu = ref(null)

provide('activeEditor', activeEditor)

onBeforeMount(() => {
  const ua = navigator.userAgent.toLowerCase()
  if (ua.includes("chrome") || ua.includes("safari")) {
    data.isWeb = true
  } else {
    data.isWeb = false
  }
})

onMounted(() => {
  data.notebookId = route.query.notebookId
  data.noteId = route.query.id
  loadNote()
})

watch(
    () => route.query.id,
    async newNotebookId => {
      data.notebookId = route.query.notebookId
      data.noteId = route.query.id
      await loadNote()
    }
)

const noteTitle = computed(() => {
  return data.note ? data.note.title : ""
})

function setCookieIfNeed() {
  // iOS 에서 cookie 에 설정한 access token 을 여기서 읽지 못한다
  // query param 으로 gatkn 에 access token 을 넘겨주고
  // 여기서 cookie 에 저장한다.
  if (route.query.gatkn) {
    cookies.set('gatkn', route.query.gatkn)
  }
}

async function loadNote() {
  setCookieIfNeed()

  // store 의 note 가 data.note 에 연결된다.
  // store 의 note를 바라보고 있으므로
  // store 의 note에 section 이 추가되면 data.note 도 추가된 note를 바라본다.
  let note = store.getters["notes/getNote"](data.notebookId, data.noteId)
  if (note === null || note === undefined) {
    // commonAlert.showInfoAlert(cookies.get('gatkn'))
    note = await store.dispatch('notes/loadNote', data.noteId)
  }

  // 노트를 생성해야 이 화면으로 들어올 수 있다.
  if (note === null || note === undefined) {
    commonAlert.showInfoAlert("잘못된 접근입니다.")
    await store.dispatch("users/clearUserAndData")
    await store.dispatch("notes/clearAll")
    await router.push({name: 'Home'})
    return
  }

  data.note = note

  addEmptySectionIfNeed();

  data.sections = data.note.sections

  await store.dispatch('folders/selectNotebook', data.notebookId)

  // const note = store.getters["note/getNote"](0,0)
  // data.sections = note.sections.map(section => section.content)
  // data.sections.push(section1.content)
}

function addEmptySectionIfNeed() {
  if (data.note.sections === null || data.note.sections.length <= 0) {
    data.note.sections = [{
      id: null,
      notebookId: data.notebookId,
      noteId: data.noteId,
      content: ''
    }]
    addSectionToStore(-1)
  }
}

function addSectionToStore(index) {
  store.dispatch('notes/addSection', {
    notebookId: data.notebookId,
    noteId: data.note,
    index: index
  })
}

function onEditorFocus(editor) {
  activeEditor.value = editor
}

function onEditorBlur(editor) {
  activeEditor.value = null
}

function onEditorContentUpdated({index, html}) {
  data.sections[index].content = html
}

// function onSectionContentChanged({id, content}) {
//   createOrUpdateSection({
//     id: id,
//     noteId: data.noteId,
//     content: content
//   })
// }

function onEditorKeydown(editor, view, event, sectionId) {
  /*
  // cmd + 엔터키 : 섹션 나누기
  if (event.metaKey && event.keyCode === 13) {
    const currentPosition = view.state.selection.$anchor?.pos;
    const content = editor.getText()
    if (content && currentPosition > 1) {
      const former = content.substring(0, currentPosition - 1)
      data.sections[contentIndex] = former
      const latter = content.substring(currentPosition - 1)
      // const splits = split(content, currentPosition-1)
      // console.log(splits[0])
      // console.log(splits[1])
      data.sections.splice(contentIndex + 1, 0, latter)
    }
    //  data.sections.push(splits[1])
  }
  // console.log(currentPosition)
  // console.log(v)
   */
}

function onEditorSectionDeleted(index) {
  data.sections.splice(index, 1)
}

function onEditorAddSection(index) {
  onAddSectionAfter(index)
}

function onAddSectionAfter(index) {
  const section = {
    id: null,
    content: ''
  }

  let newlyAddedIndex
  if (index >= 0) {
    data.sections.splice(index + 1, 0, section)
    newlyAddedIndex = index + 1
  } else {
    data.sections.push(section)
    newlyAddedIndex = data.sections[data.sections.length - 1]
  }

  addSectionToStore(newlyAddedIndex)

  setFocusToSectionEditorLazy(newlyAddedIndex)
}

function setFocusToSectionEditorLazy(index) {
  // base editor 가 화면에 추가되는 시간을 기다린 후 focus
  setTimeout(() => {
    const editor = htmlEditors.value.find(editor => editor.sectionIndex === index)
    if (editor) {
      editor.setFocus()
    }
  }, 50)
}

function onEditorModeChanged(scribbleMode) {
  if (htmlEditors && htmlEditors.value) {
    htmlEditors.value.forEach(editor => editor.setScribbleMode(scribbleMode))
  }
}

function onScribbleEditorModeChanged(scribbleEditorMode) {
  if (htmlEditors && htmlEditors.value) {
    htmlEditors.value.forEach(editor => editor.setScribbleEditorMode(scribbleEditorMode))
  }
}

function onEnterTitle() {
  updateTitleIfNeed()
}

function onBlurTitle() {
  updateTitleIfNeed()
}

function updateTitleIfNeed() {
  if (titleRef && titleRef.value) {
    const title = titleRef.value.value
    if (data.note && data.note.title && title.length > 0) {
      if (title !== data.note.title) {
        store.dispatch("notes/updateNoteTitle", {
          noteId: data.note.id,
          notebookId: data.note.notebookId,
          title: title
        }).then((result) => {
            data.note.title = result.title
        })
        // const result = noteApi.updateNoteTitle(
        //     data.note.id,
        //     data.note.notebookId,
        //     title
        // )
        // if (result) {
        //   data.note.title = title
        // }
      }
    }
  }
}

function onEditorImageClicked(index, $event) {
  const {imageUrl, editing} = $event
  data.randomNumberForImageEditor += 1
  data.inEditImage.editorIndex = index
  data.inEditImage.imageUrl = imageUrl
  data.inEditImage.editing = editing
}

function onImageEditorClosed() {
  closeImageEditor()
}

function onImageEditorSaved(blobUrl) {
  if (blobUrl) {
    if (data.inEditImage.editorIndex >= 0) {
      const editor = htmlEditors.value[data.inEditImage.editorIndex]
      editor.updateEditingImageSrc(blobUrl)
    }
  }
  closeImageEditor()
}

function closeImageEditor() {
  data.inEditImage.imageUrl = null
  data.inEditImage.editorIndex = null
  data.inEditImage.editing = false
}

function onNoteMenuClicked(cmd) {
  if (cmd === 'DELETE') {
    commonAlert.showConfirmAlert('노트 삭제',
        '노트를 삭제하시겠습니까?',
        () => deleteNote())
  } else if (cmd === 'MOVE') {
    // to be implemented
  }
}

function deleteNote() {
  store.dispatch('notes/deleteNote', {
    notebookId: data.notebookId,
    noteId: data.note.id
  }).then(result => {
    if (result) {
      router.replace({ name: 'Notebook', query: { id: data.notebookId }})
    }
  })
}

function onTextAttributeDetected({alignment, fontSize}) {
  editorMenu.value.setCurrentTextAttribute({alignment, fontSize})
}

</script>

<style lang="scss">
/* Basic editor styles */
//.ProseMirror {
//  > * + * {
//    margin-top: 0.75em;
//  }
//
//  code {
//    background-color: rgba(#616161, 0.1);
//    color: #616161;
//  }
//}

//.content {
//  padding: 1rem 0 0;
//
//  h3 {
//    margin: 1rem 0 0.5rem;
//  }
//
//  pre {
//    border-radius: 5px;
//    color: #333;
//  }
//
//  code {
//    display: block;
//    white-space: pre-wrap;
//    font-size: 0.8rem;
//    padding: 0.75rem 1rem;
//    background-color: #e9ecef;
//    color: #495057;
//  }
//}

</style>
