import axios from "axios";

export default {
    async get(id) {
        console.log('call api : folder get')
        try {
            const response = await axios.get('/api/v1/folders/folder/' + id)
            if (response.status === 200) {
                return response.data.result
            }
        } catch (e) {
            console.log(e)
        }

        return null
    },

    async getAll() {
        console.log('call api : folder list')
        try {
            const response = await axios.get('/api/v1/folders/list')
            if (response.status === 200) {
                return response.data.result
            }
        } catch (e) {
            console.log(e)
        }

        return []
    },

    async create(data) {
        try {
            const response = await axios.post('/api/v1/folders', data)
            if (response.status === 200) {
                return response.data.result
            }
        } catch (e) {
            console.log(e)
        }

        return null
    },

    async update(data) {
        try {
            const response = await axios.put('/api/v1/folders', data)
            if (response.status === 200) {
                return response.data.result
            }
        } catch (e) {
            console.log(e)
        }

        return null
    },

    async delete(id) {
        try {
            const response = await axios.delete('/api/v1/folders/' + id)
            if (response.status === 200) {
                return response.data.result
            }
        } catch (e) {
            console.log(e)
        }

        return null
    }
}
