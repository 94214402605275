import sectionApi from "@/api/sectionApi";

const actions = {
    async createOrUpdateSection({commit}, data) {
        const section = await sectionApi.createOrUpdateSection({
            id: data.sectionId,
            noteId: data.noteId,
            content: data.content
        })

        commit('notes/updateSection', {
            notebookId: data.notebookId,
            noteId: section.noteId,
            sectionId: section.id,
            sectionIndex: data.index,
            content: section.content
        }, {root:true})

        // 첫번째 섹션은 노트리스트 화면의 preview text 로 사용되므로
        // 변경되면 노트리스트 화면 조회시 다시 가져올 수 있게 세팅한다.
        if (data.sectionIndex === 0) {
            commit('folders/setNotebookContentsIsDirty', data.notebookId, {root: true})
        }

        return section
    },

    async deleteSection({commit}, data) {
        const result = await sectionApi.delete(data.sectionId)
        commit('notes/deleteSection', {
            notebookId: data.notebookId,
            noteId: data.noteId,
            sectionId: data.sectionId,
            sectionIndex: data.sectionIndex
        }, {root:true})

        // 첫번째 섹션은 노트리스트 화면의 preview text 로 사용되므로
        // 변경되면 노트리스트 화면 조회시 다시 가져올 수 있게 세팅한다.
        if (data.sectionIndex === 0) {
            commit('folders/setNotebookContentsIsDirty', data.notebookId, {root: true})
        }

        return result
    }
}

export default {
    namespaced: true,
    actions
}
