import axios from 'axios';
import cookies from "vue-cookies";
import userApi from "@/api/userApi.js";
import {router} from "../router/index";

axios.defaults.baseURL = process.env.AXIOS_BASE_URL
// axios.defaults.baseURL = "api.gongnote.co.kr"
// axios.defaults.baseURL = "http://172.30.1.33:9000"
// axios.defaults.baseURL = "api.gongnote.svc.cluster.local"
// axios.defaults.withCredentials = true
axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        console.log(error)
        if (error.response && error.response.status === 401) {
            if (error.response.data.message === "Expired Token") {
                const accessToken = await userApi.refresh()
                if (accessToken) {
                    error.config.headers.accessToken = accessToken;
                    return axios(error.config);
                }
                console.log("refresh failed!")
            }
            cookies.remove('gatkn');
            cookies.remove('grtkn');
            // 로그인 상태로 이동
            router.push({name: 'Home'});
        }

        return error;
    }
)

axios.interceptors.request.use(
    config => {
        config.headers["accessToken"] = cookies.get("gatkn");
        return config;
    }
)


export default axios
