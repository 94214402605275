import {fabric} from 'fabric';
import RectHandler from "@/components/ImageEditor/controls/draw/RectHandler";
import BaseHandler from "@/components/ImageEditor/controls/draw/BaseHandler";
import EllipseHandler from "@/components/ImageEditor/controls/draw/EllipseHandler";
import LineHandler from "@/components/ImageEditor/controls/draw/LineHandler";
import FreeDrawHandler from "@/components/ImageEditor/controls/draw/FreeDrawHandler";
import SelectHandler from "@/components/ImageEditor/controls/draw/SelectHandler";
import PanHandler from "@/components/ImageEditor/controls/draw/PanHandler";
import TextHandler from "@/components/ImageEditor/controls/draw/TextHandler";
import ConcealHandler from "@/components/ImageEditor/controls/draw/ConcealHandler";
import RevealHandler from "@/components/ImageEditor/controls/draw/RevealHandler";
import ZoomHandler from "@/components/ImageEditor/controls/draw/ZoomHandler";

// DrawProperty 는 여기에도 있고 ImageEditor 에도 정의되어 있는데
// 나중에 하나로 합치자
// property name 은 fabric 에서 다루는 이름과 동일하게 한다
type DrawProperty = {
    strokeWidth: number,
    stroke: string,
    fill: string
}

class DrawPlugin {
    public canvas: fabric.Canvas
    mouseDown: boolean
    drawHandlers: Map<String, BaseHandler>
    currentHandler?: BaseHandler | undefined
    zoomHandler?: ZoomHandler
    drawProperty: DrawProperty

    constructor(canvas: fabric.Canvas, {drawProperty, handlers}) {
        this.canvas = canvas
        this.init(drawProperty, handlers)
    }

    init(drawProperty, handlers) {
        const {canvas} = this;
        this.drawProperty = drawProperty
        this.drawHandlers = new Map()

        if (handlers.includes('ALL') || handlers.includes('SELECT')) {
            this.drawHandlers.set('SELECT', new SelectHandler(canvas))
        }

        if (handlers.includes('ALL') || handlers.includes('PAN')) {
            this.drawHandlers.set('PAN', new PanHandler(canvas))
        }

        if (handlers.includes('ALL') || handlers.includes('RECTANGLE')) {
            this.drawHandlers.set('RECTANGLE', new RectHandler(canvas, drawProperty))
        }

        if (handlers.includes('ALL') || handlers.includes('ELLIPSE')) {
            this.drawHandlers.set('ELLIPSE', new EllipseHandler(canvas, drawProperty))
        }

        if (handlers.includes('ALL') || handlers.includes('LINE')) {
            this.drawHandlers.set('LINE', new LineHandler(canvas, drawProperty))
        }

        if (handlers.includes('ALL') || handlers.includes('DRAW')) {
            this.drawHandlers.set('DRAW', new FreeDrawHandler(canvas, drawProperty))
        }

        if (handlers.includes('ALL') || handlers.includes('TEXT')) {
            this.drawHandlers.set('TEXT', new TextHandler(canvas, drawProperty))
        }

        if (handlers.includes('ALL') || handlers.includes('CONCEAL')) {
            this.drawHandlers.set('CONCEAL', new ConcealHandler(canvas))
        }

        if (handlers.includes('ALL') || handlers.includes('REVEAL')) {
            this.drawHandlers.set('REVEAL', new RevealHandler(canvas))
        }

        if (handlers.includes('ALL') || handlers.includes('ZOOM')) {
            this.zoomHandler = new ZoomHandler(canvas)
        }

        canvas.on('mouse:down', (ie) => {
            this.onMouseDown(ie)
        })
        canvas.on('mouse:move', (ie) => {
            this.onMouseMove(ie)
        })
        canvas.on('mouse:up', (ie) => {
            this.onMouseUp(ie)
        })

        canvas.on('mouse:wheel', (ie) => {
            this.onMouseWheel(ie)
        })
    }

    setCommand(cmd) {
        this.currentHandler = this.drawHandlers.get(cmd.type)
        this.currentHandler?.prepare()
        return this.currentHandler
    }

    getHandler(cmd) {
        return this.drawHandlers.get(cmd.type)
    }

    updateDrawProperty(partialProperty) {
        Object.assign(this.drawProperty, partialProperty)
        this.drawHandlers.forEach(dh => {
            dh.updateDrawProperty(partialProperty)
        })

        this.updateSelectedObjectsProperty(partialProperty)
    }

    updateSelectedObjectsProperty(partialProperty) {

        const {canvas} = this;
        const activeObjects = canvas.getActiveObjects();
        if (activeObjects) {
            activeObjects.forEach(o => {
                this.drawHandlers.forEach(dh => {
                    if (dh.canHandle(o)) {
                        dh.updateObjectProperty(o, partialProperty)
                        return false
                    }
                })
            })

            // fabric 에서 event 가 발생하지 않는다. 수동 fire
            canvas.fire('object:modified', {target: activeObjects})
            canvas.requestRenderAll()
        }
    }

    onMouseDown(ie) {
        const {canvas} = this;

        if (canvas.getActiveObject()) {
            return
        }

        this.mouseDown = true
        this.currentHandler?.mouseDown(ie)
    }

    onMouseMove(ie) {
        if (this.mouseDown) {
            this.currentHandler?.mouseMove(ie)
        }
    }

    onMouseUp(ie) {
        const {canvas} = this;
        if (this.mouseDown) {
            this.currentHandler?.mouseUp(ie)
            this.mouseDown = false
        }
    }

    onMouseWheel(ie) {
        if (this.zoomHandler) {
            this.zoomHandler.mouseWheel(ie)
        }
    }

    toggleRevealAll() {
        const handler = this.drawHandlers.get('REVEAL')
        if (handler) {
            const revealHandler = handler as RevealHandler
            return revealHandler.toggleRevealAll()
        }
    }
}

export default DrawPlugin
