import axios from "axios";

export default {
    async createOrUpdateSection({id, noteId, content}) {
        console.log('call api : update section')
        try {
            const response = await axios.post(
                "/api/v1/notes/sections",
                {
                    id: id,
                    noteId: noteId,
                    content: content
                }
            )

            if (response && response.status === 200 && response.data) {
                return response.data.result
            } else {
                return {error: response}
            }
        } catch (e) {
            console.log(e)
        }

        return null
    },

    async delete(id) {
        console.log('call api : delete section')
        try {
            const response = await axios.delete('/api/v1/notes/sections/' + id)
            if (response.status === 200) {
                return response.data.result
            }
        } catch (e) {
            console.log(e)
        }

        return null
    }
}
