<template>
  <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" role="img">
    <g>
      <path fill="none" d="M0 0H24V24H0z"/>
      <path d="M20 3c.552 0 1 .448 1 1v16c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h16zm-9 2H5v5.999h2V9l3 3-3 3v-2H5v6h6v-2h2v2h6v-6h-2v2l-3-3 3-3v1.999h2V5h-6v2h-2V5zm2 8v2h-2v-2h2zm0-4v2h-2V9h2z"
            style="stroke: transparent; fill: white;"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'MergeCellSvg',
}
</script>
