import EventEmitter from 'events';
import {SelectEvent, ObjectEvent} from './types';
import {fabric} from "fabric";

class CanvasEventEmitter extends EventEmitter {
    public canvas: fabric.Canvas

    init(canvas) {
        this.canvas = canvas
        if (this.canvas) {
            this.canvas.on('selection:created', () => this.selected());
            this.canvas.on('selection:updated', () => this.selected());
            this.canvas.on('selection:cleared', () => this.selected());
            this.canvas.on('object:added', (e) => this.added(e));
            this.canvas.on('object:custom_added', () => this.customAdded());
            this.canvas.on('object:removed', () => this.removed());
            this.canvas.on('object:modified', () => this.modified());
        }
    }

    private selected() {
        if (!this.canvas) {
            return
        }

        const selected = this.canvas
            .getActiveObjects()

        if (selected && selected.length === 1) {
            this.emit(SelectEvent.ONE, selected);
        } else if (selected && selected.length > 1) {
            this.emit(SelectEvent.MULTI, selected);
        } else {
            this.emit(SelectEvent.CANCEL);
        }
    }

    /*
    주의 : fabric canvas에 line, rect, circle 등은 canvas.add를 해야 그려진다.
    그래서 위 타입은 mouse move 시에 일정 길이 이상 그리면 canvas.add 를 호출해서 화면에 보이도록 하고
    => 이 때 object:add 이벤트가 발생 => 그래서 다 그리지 않은 상태에서 HistoryPlugin 이 화면을 저장하게 된다.
    이후 mouse move 를 계속 하면 그에 따라 객체의 길이가 달라진다
    하지만 freedraw 는 fabric canvas 에서 isDrawingMode 를 true 로 하면
    마우스를 따라서 그려지지만 object:added 이벤트는 mouse up 시에 발생한다
    현재 스펙 : 위 두 경우를 구분하기 위해서 HistoryPlugin 에서 FREEDRAW_ADDED, CUSTOM_ADDED 만 대응하고
    ADDED 는 대응하지 않는다.
    // 이 상황이 ADDED 로 통일되려면 line, rect, circle 도 freedraw 처럼
    화면을 따라서 그려져야 하고 mouse up 시에 add 이벤트가 발생해야 하는데.. 방법을 모르겠다.
     */
    private added(e) {
        if (e.target.path) {
            this.emit(ObjectEvent.FREEDRAW_ADDED)
        }
        this.emit(ObjectEvent.ADDED)
    }

    private removed() {
        this.emit(ObjectEvent.REMOVED)
    }

    private modified() {
        this.emit(ObjectEvent.MODIFIED)
    }

    private customAdded() {
        this.emit(ObjectEvent.CUSTOM_ADDED)
    }
}

export default CanvasEventEmitter
