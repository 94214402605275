<template>
  <v-navigation-drawer permanent>
    <div class="h-12 fixed right-0 gn-v-center pr-2 z-10">
      <v-btn id="btnCreateFolder" icon="mdi-folder-plus" size="x-small" class="right-0 mx-1 cursor-pointer bg-white"
             @click="onCreateFolderClicked"/>
      <v-btn id="btnCreateNotebook" icon="mdi-notebook-plus" size="x-small" class="mx-1 cursor-pointer bg-white"
             @click="onCreateNotebookClicked"
             :disabled="!anyItemSelected"/>
      <v-btn id="btnEdit" icon="mdi-pencil" size="x-small" class="mx-1 cursor-pointer bg-white"
             @click="onEditClicked"
             :disabled="!anyItemSelected"/>
      <v-btn id="btnDelete" icon="mdi-delete" size="x-small" class="mx-1 cursor-pointer bg-white"
             @click="onDeleteClicked"
             :disabled="!anyItemSelected"/>
    </div>
    <v-list class="pt-12" :opened="data.openFolderIds">
      <v-list-group
          v-for="folder in data.folders"
          :value="folder.id"
          color="primary"
          :expand-icon="getExpandCollapseIcon(true, folder)"
          :collapse-icon="getExpandCollapseIcon(false, folder)">
        <template v-slot:activator="{ props }">
          <v-list-item
              v-bind="props"
              prepend-icon="mdi-folder"
              :active="folder.id === data.selectedFolder?.id"
              :title="folder.title"
              @click="onFolderClicked(folder)"
          ></v-list-item>
        </template>

        <v-list-item
            v-if="folder.notebooks"
            v-for="(notebook, i) in folder.notebooks"
            :key="i"
            color="primary"
            prepend-icon="mdi-notebook"
            :title="notebook.title"
            :active="notebook.id === data.selectedNotebook?.id"
            @click="onNotebookClicked(notebook)"
        ></v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script setup>

import {reactive, computed, onMounted} from "vue";
import {useStore} from "vuex";
import commonAlert from "@/components/CommonAlert";
import folderApi from "../api/folderApi"
import notebookApi from "@/api/notebookApi";
import tippy from "tippy.js";

const emit = defineEmits(['notebookClicked'])

const props = defineProps({
  user: null
})

const data = reactive({
  open: [''],
  folders: [],
  selectedFolder: null,
  selectedNotebook: null,
  openFolderIds: []
})

const store = useStore()

store.subscribe((mutation, state) => {
  if (mutation.type === "users/setUser") {
    const user = store.getters["users/getUser"]
    if (user) {
      loadFolders()
    } else {
      clearDrawer()
    }
  } else if (mutation.type === "folders/setFolders"
      || mutation.type === "folders/addFolder"
      || mutation.type === "folders/updateFolder"
      || mutation.type === "folders/deleteFolder"
      || mutation.type === "folders/addNotebook"
      || mutation.type === "folders/removeNotebook") {
    data.folders = state.folders.folders
  } if (mutation.type === "folders/selectNotebook") {
      const selectedNotebook = store.getters['folders/getSelectedNotebook']()
      selectNotebook(selectedNotebook)
  }
})

const anyItemSelected = computed(() => {
  return data.selectedFolder || data.selectedNotebook
})

onMounted(() => {
  if (props.user) {
    loadFolders()
  }

  createTooltip()
})

function createTooltip() {
  tippy('#btnCreateFolder', {
    content: "폴더 생성",
  });

  tippy('#btnCreateNotebook', {
    content: "노트북 생성",
  });

  tippy('#btnEdit', {
    content: "폴더/노트북 수정",
  });

  tippy('#btnDelete', {
    content: "폴더/노트북 삭제",
  });
}

function clearDrawer() {
  data.folders = []
  data.open = ['']
  data.selectedFolder = null
  data.selectedNotebook = null
  data.openFolderIds = []
}

function selectNotebook(notebook) {
  if (notebook === null || notebook === undefined) {
    data.selectedFolder = null
    data.selectedNotebook = null
    return
  }

  data.openFolderIds.push(notebook.folderId)
  data.selectedFolder = null
  data.selectedNotebook = notebook
}

function getExpandCollapseIcon(expand, folder) {
  if (folder.notebooks && folder.notebooks.length > 0) {
    return expand ? "mdi-menu-down" : "mdi-menu-up"
  } else {
    return ""
  }
}

function onFolderClicked(folder) {
  data.selectedNotebook = null
  data.selectedFolder = folder
  data.openFolderId = folder.id
}

function onNotebookClicked(notebook) {
  data.selectedFolder = null
  data.selectedNotebook = notebook
  emit('notebookClicked', notebook)
}

function onCreateFolderClicked() {
  commonAlert.showInputConfirm(
      '폴더 추가',
      '',
      '폴더 이름',
      (folderTitle) => {
        createFolder(folderTitle)
      },
      null,
      '생성'
  )
}

function onCreateNotebookClicked() {
  const folderId = getCurrentFolderId()
  if (folderId) {
    commonAlert.showInputConfirm(
        '노트북 생성',
        '',
        '노트북 이름',
        (notebookTitle) => {
          createNotebook(folderId, notebookTitle)
        },
        null,
        '생성'
    )
  }
}

function getCurrentFolderId() {
  if (data.selectedFolder) {
    return data.selectedFolder.id
  } else if (data.selectedNotebook) {
    return data.selectedNotebook.folderId
  } else {
    return null
  }
}

function onEditClicked() {
  if (data.selectedFolder) {
    // emit('folderEditClicked', data.selectedFolder)
    commonAlert.showInputConfirm(
        '폴더 수정',
        data.selectedFolder.title,
        '폴더 이름',
        (folderTitle) => {
          updateFolder(data.selectedFolder.id, folderTitle)
        },
        null,
        '저장'
    )
  } else if (data.selectedNotebook) {
    // emit('notebookEditClicked', data.selectedNotebook)
    commonAlert.showInputConfirm(
        '노트북 수정',
        data.selectedNotebook.title,
        '노트북 이름',
        (notebookTitle) => {
          updateNotebook(data.selectedNotebook.id, data.selectedNotebook.folderId, notebookTitle)
        },
        null,
        '저장'
    )
  }
}

function onDeleteClicked() {
  if (data.selectedFolder) {
    // emit('folderDeleteClicked', data.selectedFolder)
    commonAlert.showCautiousConfirm(
        '폴더 삭제',
        data.selectedFolder.title,
        () => {
          deleteFolder(data.selectedFolder.id)
        },
        null,
        '삭제'
    )
  } else if (data.selectedNotebook) {
    // emit('notebookDeleteClicked', data.selectedNotebook)
    commonAlert.showCautiousConfirm(
        '노트북 삭제',
        data.selectedNotebook.title,
        () => {
          deleteNotebook(data.selectedNotebook.id)
        },
        null,
        '삭제'
    )
  }
}

async function loadFolders() {
  const result = await folderApi.getAll()
  await store.dispatch('folders/setFolders', result.folders)
}

async function createFolder(title) {
  const result = await folderApi.create({title: title})
  if (result) {
    await store.dispatch('folders/addFolder', result)
  } else {
    commonAlert.showErrorAlert("폴더를 생성하지 못했습니다.\n잠시 후 다시 시도해 주세요")
  }
}

async function createNotebook(folderId, title) {
  const result = await notebookApi.create({folderId: folderId, title: title})
  if (result) {
    await store.dispatch("notebooks/addNotebook", result)
  } else {
    commonAlert.showErrorAlert("노트북을 생성하지 못했습니다.\n잠시 후 다시 시도해 주세요")
  }
}

async function updateFolder(folderId, title) {
  const result = await folderApi.update({id: folderId, title: title})
  if (result) {
    await store.dispatch("folders/updateFolder", result)
  } else {
    commonAlert.showErrorAlert("폴더를 수정하지 못했습니다.\n잠시 후 다시 시도해 주세요")
  }
}

async function updateNotebook(notebookId, folderId, title) {
  const result = await notebookApi.update({id: notebookId, folderId: folderId, title: title})
  if (result) {
    await store.dispatch("notebooks/updateNotebook", result)
  } else {
    commonAlert.showErrorAlert("폴더를 수정하지 못했습니다.\n잠시 후 다시 시도해 주세요")
  }
}

async function deleteFolder(id) {
  const result = await folderApi.delete(id)
  if (result) {
    await store.dispatch('folders/deleteFolder', id)
  } else {
    commonAlert.showErrorAlert("폴더를 삭제하지 못했습니다.\n잠시 후 다시 시도해 주세요")
  }
}

async function deleteNotebook(id) {
  const result = await notebookApi.delete(id)
  if (result) {
    await store.dispatch('notebooks/deleteNotebook', id)
  } else {
    commonAlert.showErrorAlert("노트북을 삭제하지 못했습니다.\n잠시 후 다시 시도해 주세요")
  }
}

</script>
