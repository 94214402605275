import axios from "axios";
import cookies from 'vue-cookies';
import jwtDecode from "jwt-decode";
import {useStore} from "vuex";

const store = useStore()

export default {
    async login(token) {
        try {
            const response = await axios.post(`api/v1/users/login`, {
                token: token
            })
            if (response.status === 200 && response.data) {
                return response.data.result
            }
        } catch (e) {
            if (e.response && e.response.status === 400) {
                console.log(e.response.data) // data.code, data.message
            }
        }

        return null
    },

    async signup(token) {
        try {
            const response = await axios.post(`api/v1/users/signup`, {
                token: token
            })
            if (response.status === 200 && response.data) {
                return response.data.result
            }
        } catch (e) {
            if (e.response && e.response.status === 400) {
                console.log(e.response.data) // data.code, data.message
            }
        }

        return null
    },

    async loginAndGetDetail() {
        try {
            const response = await axios.get(`api/v1/users/login_and_detail`)
            if (response.status === 200 && response.data) {
                return response.data.result
            }
        } catch (e) {
            console.log(e);
        }

        return null
    },

    async refresh() {
        const refreshToken = cookies.get("grtkn");
        if (refreshToken) {
            const resp = await axios.post("/api/v1/users/refresh", {
                token: refreshToken
            });
            if (resp.status === 200 && resp.data.status >= 0) {
                // update tokens in cookies
                cookies.set('gatkn', resp.data.result.accessToken);
                cookies.set('grtkn', resp.data.result.refreshToken);

                axios.defaults.headers.common['accessToken'] = resp.data.result.accessToken;

                console.log("refreshed!")

                // update the accessToken for the original request
                return resp.data.result.accessToken;
            }
        }
        return null;
    }
}
