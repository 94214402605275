import {fabric} from 'fabric';
import RectHandler from "@/components/ImageEditor/controls/draw/RectHandler";
import {v4 as uuid} from "uuid"

class ConcealHandler extends RectHandler {

    constructor(canvas: fabric.Canvas) {
        super(canvas, null)
    }

    canHandle(drawObject): boolean {
        return (drawObject instanceof fabric.Rect) && (drawObject.name === 'ConcealRect')
    }

    createObject() {
        return new fabric.Rect({
            name: 'ConcealRect',
            id: uuid(),
            left: this.startPoint.x,
            top: this.startPoint.y,
            width: 1,
            height: 1,
            fill: 'rgba(255,255,255,0)',
            stroke: '#000',
            strokeWidth: 2,
            strokeDashArray: [4,2],
            strokeUniform: true,
        })
    }

    createAndSetDrawingObject() {
        const {canvas} = this;

        this.drawingObject = this.createObject()
        this.drawingObject.setControlsVisibility({
            mtr: false, ml: false, mt: false, mb: false, mr: false
        })

        canvas.add(this.drawingObject)
    }

    updateObjectProperty(drawObject, partialProperty) {
        // do nothing
    }
}

export default ConcealHandler
