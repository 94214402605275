<template>
  <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" role="img">
    <g>
      <path fill="none" d="M0 0H24V24H0z"/>
      <g>
        <path fill="none" d="M0 0H24V24H0z"/>
        <path d="M20 5c.552 0 1 .448 1 1v6c0 .552-.448 1-1 1 .628.835 1 1.874 1 3 0 2.761-2.239 5-5 5s-5-2.239-5-5c0-1.126.372-2.165 1-3H4c-.552 0-1-.448-1-1V6c0-.552.448-1 1-1h16zm-7 10v2h6v-2h-6zm6-8H5v4h14V7z"
              style="stroke: transparent; fill: white;"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DeleteRowSvg',
}
</script>
