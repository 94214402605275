<template>
  <v-layout>
    <v-app-bar v-if="data.isWeb">
      <v-toolbar-title class="cursor-pointer" @click="home">
        GongNote
      </v-toolbar-title>
      <template v-slot:append>
        <Login/>
      </template>
    </v-app-bar>
    <v-main class="w-full w-screen fixed" style="min-height: 300px;">
      <router-view class="m-2"></router-view>
    </v-main>

    <SideDrawer v-if="data.user && data.isWeb"
                :user="data.user"
                @notebookClicked="onNotebookClicked"/>
  </v-layout>
</template>

<script setup>

import {computed, onBeforeMount, onMounted, reactive, ref} from "vue";
import SideDrawer from "@/views/SideDrawer.vue";
import Login from "@/views/login/Login.vue"
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import commonAlert from "@/components/CommonAlert";

const router = useRouter()
const store = useStore()

const data = reactive({
  user: null,
  isWeb: false
})

store.subscribe((mutation, state) => {
  if (mutation.type === "users/setUser") {
    data.user = store.getters["users/getUser"]
  }
})

onBeforeMount(() => {
  // console.log(router.currentRoute)
  const ua = navigator.userAgent.toLowerCase()
  if (ua.includes("chrome") || ua.includes("safari")) {
    data.isWeb = true
  } else {
    data.isWeb = false
  }
})

function home() {
  linkTo({
    name: "Home"
  })
}

function onNotebookClicked(notebook) {
  linkTo({
    name: "Notebook",
    query: {
      id: notebook.id,
    }
  })
}

function linkTo({name, query}) {
  router.push({
        name: name,
        query: query
      })
}


</script>
