import notebookApi from "../../api/notebookApi"

const state = {
    notebooks: []
}

const getters = {
    getNotebook: (state) => (notebookId) => {
        if (state.notebooks && state.notebooks.length > 0) {
            return state.notebooks.find(notebook => notebook.id === notebookId)
        } else {
            return null
        }
    }
}

const actions = {
    async loadNoteWithPreviewList({commit}, notebookId) {
        const notebookWithPreview = await notebookApi.getNoteWithPreviewList(notebookId)
        if (notebookWithPreview) {
            commit('folders/updateNotebook', notebookWithPreview, {root: true})
        }
        return notebookWithPreview
    },
    addNotebook({commit}, data) {
        commit('folders/addNotebook', data, {root:true})
    },
    updateNotebook({commit}, data) {
        commit('folders/updateNotebook', data, {root:true})
    },
    deleteNotebook({commit}, id) {
        commit('folders/removeNotebook', id, {root:true})
    }
}

const mutations = {
    // addNotebook(state, notebookData) {
    //     state.notebooks.push({
    //         id: notebookData.id,
    //         folderId: notebookData.folderId,
    //         title: notebookData.title
    //     })
    // },
    //
    // removeNotebook(state, id) {
    //     const index = state.notebooks.findIndex(n => n.id === id)
    //     if (index >= 0) {
    //         console.log('e ' + index)
    //         state.notebooks.splice(index, 1)
    //     }
    // }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
