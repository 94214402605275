import {fabric} from 'fabric';
import BaseHandler from "@/components/ImageEditor/controls/draw/BaseHandler";

class RectHandler extends BaseHandler {
    constructor(canvas: fabric.Canvas, drawProperty) {
        super(canvas, drawProperty)
    }

    canHandle(drawObject): boolean {
        return (drawObject instanceof fabric.Rect) && (drawObject.name !== 'ConcealRect')
    }

    createObject() {
        return new fabric.Rect({
            left: this.startPoint.x,
            top: this.startPoint.y,
            width: 1,
            height: 1,
        })
    }

    updateObjectByMouse(point) {
        if (this.drawingObject) {
            const width = Math.abs(point.x - this.startPoint.x)
            const height = Math.abs(point.y - this.startPoint.y)
            this.drawingObject.set({
                originX: (point.x > this.startPoint.x) ? 'left' : 'right',
                originY : (point.y > this.startPoint.y) ? 'top' : 'bottom',
                width: width,
                height: height
            })

            this.canvas.requestRenderAll()
        }
    }

    isDrawingObjectValid(point) {
        const xDiff = Math.abs(point.x - this.startPoint.x)
        const yDiff = Math.abs(point.y - this.startPoint.y)

        return (xDiff > this.drawLengthThreshold && yDiff > this.drawLengthThreshold)
    }
}

export default RectHandler
