export const TextMenu = {
    BOLD: {
        cmd: "Bold",
        icon: null,
        title: "B",
    },
    ITALIC: {
        cmd: "Italic",
        icon: null,
        title: "I",
    },
    UNDERLINE: {
        cmd: "Underline",
        title: "U̲",
        value: "UNDERLINE",
    },
    FONT_SIZE: {
        cmd: "FontSize",
        value: "12pt",
        icon: null,
        title: "14",
    },
    ALIGN_L: {
        cmd: 'TextAlign',
        icon: null,
        title: "L",
        value: "left",
    },
    ALIGN_C: {
        cmd: 'TextAlign',
        icon: null,
        title: "C",
        value: "center",
    },
    ALIGN_R: {
        cmd: 'TextAlign',
        icon: null,
        title: "R",
        value: "right",
    },
    // 이미지 메뉴는 V2에 가자
    // IMAGE: {
    //     cmd: 'AddImage',
    //     icon: "image"
    // },
    TABLE: {
        cmd: 'CreateTable',
        title: "T"
    }
}

export const TableMenu = {
    INSERT_COLUMN: {
        cmd: 'InsertColumn',
    },
    INSERT_ROW: {
        cmd: 'InsertRow',
    },
    DELETE_COLUMN: {
        cmd: 'DeleteColumn',
    },
    DELETE_ROW: {
        cmd: 'DeleteRow',
    },
    MERGE_CELL: {
        cmd: 'MergeCells',
    },
    SPLIT_CELL: {
        cmd: 'SplitCell',
    },
    FILL_CELL: {
        cmd: 'FillCell',
    },
    DELETE_TABLE: {
        cmd: 'DeleteTable',
    },
}

export const NoteSpaceMenu = {
    TYPING: {
        cmd: 'Typing',
        icon: "keyboard"
    },
    SCRIBBLE: {
        cmd: 'Scribble',
        icon: "pencil"
    }
}
