import {fabric} from 'fabric';

class DeletePlugin {
    public canvas: fabric.Canvas

    public hotkeys: string[] = ['backspace'];

    constructor(canvas: fabric.Canvas) {
        this.canvas = canvas
    }

    hotkeyEvent(eventName: string, e: any) {
        if (e.type === 'keydown' && eventName === 'backspace') {
            this.deleteObject();
        }
    }

    deleteObject() {
        const { canvas } = this;
        const activeObjects = canvas.getActiveObjects();
        if (activeObjects) {
            activeObjects.forEach(o => canvas.remove(o))
            canvas.discardActiveObject();
        }
    }
}

export default DeletePlugin
