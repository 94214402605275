import {fabric} from 'fabric';

class CopyPlugin {
    public canvas: fabric.Canvas

    public hotkeys: string[] = ['ctrl+v', 'ctrl+c', 'command+c', 'command+v'];
    private cache: null | fabric.ActiveSelection | fabric.Object;

    constructor(canvas: fabric.Canvas) {
        this.canvas = canvas
        this.cache = null;
    }

    hotkeyEvent(eventName: string, e: any) {
        if ((eventName === 'ctrl+c' || eventName === 'command+c') && e.type === 'keydown') {
            this.cache = this.canvas.getActiveObject();
        }
        if ((eventName === 'ctrl+v' || eventName === 'command+v') && e.type === 'keydown') {
            if (this.cache) {
                this.paste(this.cache);
            }
        }
    }

    private paste(paramsActiveObject: fabric.ActiveSelection | fabric.Object) {
        const activeObject = paramsActiveObject;
        if (!activeObject) return;
        if (activeObject?.type === 'activeSelection') {
            this.pasteSelection(activeObject);
        } else {
            this.pasteObject(activeObject);
        }
    }

    private pasteObject(activeObject: fabric.Object) {
        const grid = 10;
        const canvas = this.canvas;
        activeObject?.clone((cloned: fabric.Object) => {
            if (cloned.left === undefined || cloned.top === undefined) return;
            canvas.discardActiveObject();
            // 设置位置信息
            cloned.set({
                left: cloned.left + grid,
                top: cloned.top + grid,
                evented: true
            });
            canvas.add(cloned);
            canvas.setActiveObject(cloned);
            canvas.requestRenderAll();
        }, ['name']);
    }

    private pasteSelection(activeObject: fabric.Object) {
        const grid = 10;
        const canvas = this.canvas;
        activeObject?.clone((cloned: fabric.Object) => {
            cloned.clone((clonedObj: fabric.ActiveSelection) => {
                canvas.discardActiveObject();
                if (clonedObj.left === undefined || clonedObj.top === undefined) return;
                clonedObj.canvas = canvas;
                // 设置位置信息
                clonedObj.set({
                    left: clonedObj.left + grid,
                    top: clonedObj.top + grid,
                    evented: true
                });
                clonedObj.forEachObject((obj: fabric.Object) => {
                    canvas.add(obj);
                });
                // 解决不可选择问题
                clonedObj.setCoords();
                canvas.setActiveObject(clonedObj);
                canvas.requestRenderAll();
            }, ['name']);
        }, ['name']);
    }
}

export default CopyPlugin
