import {fabric} from 'fabric';
import BaseHandler from "@/components/ImageEditor/controls/draw/BaseHandler";

class EllipseHandler extends BaseHandler {
    constructor(canvas: fabric.Canvas, drawProperty) {
        super(canvas, drawProperty)
    }

    canHandle(drawObject): boolean {
        return drawObject instanceof fabric.Ellipse
    }

    createObject() {
        return new fabric.Ellipse({
            left: this.startPoint.x,
            top: this.startPoint.y,
            rx: 1,
            ry: 1,
        })
    }

    updateObjectByMouse(point) {
        if (this.drawingObject) {
            const rx = Math.abs((point.x - this.startPoint.x) / 2.0)
            const ry = Math.abs((point.y - this.startPoint.y) / 2.0)
            this.drawingObject.set({
                originX: (point.x > this.startPoint.x) ? 'left' : 'right',
                originY : (point.y > this.startPoint.y) ? 'top' : 'bottom',
                rx: rx,
                ry: ry
            })
            this.canvas.requestRenderAll()
        }
    }

    isDrawingObjectValid(point) {
        const xDiff = Math.abs(point.x - this.startPoint.x)
        const yDiff = Math.abs(point.y - this.startPoint.y)

        return (xDiff > this.drawLengthThreshold && yDiff > this.drawLengthThreshold)
    }
}

export default EllipseHandler
