const state = {
    savedEditingImageMap: new Map()
}

const getters = {
    getSavedImage: (state) => (url) => {
        return state.savedEditingImageMap.get(url)
    }
}

const actions = {
    saveEditingImage({commit}, data) {
        commit('save', data)
    }
}

const mutations = {
    save(state, data) {
        state.savedEditingImageMap.set(data.imageUrl, data.imageJson)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
