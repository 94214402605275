
export const formatDate = dateString => {
    const datetime = new Date(dateString)
    const year = datetime.getFullYear();
    const month = String(datetime.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(datetime.getDate()).padStart(2, '0');

    return `${month}.${day}.${year}`;
}

export const formatDateTime = dateString => {
    const datetime = new Date(dateString)
    const year = datetime.getFullYear();
    const month = String(datetime.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(datetime.getDate()).padStart(2, '0');

    let ampm = "오전"
    let h = datetime.getHours()
    if (datetime.getHours() >= 13) {
        ampm = "오후"
        h -= 12
    }
    const hours = String(h);
    const minutes = String(datetime.getMinutes()).padStart(2, '0');

    return `${month}.${day} ${ampm} ${hours}:${minutes}`;
}
