import {fabric} from 'fabric';
import PanHandler from "@/components/ImageEditor/controls/draw/PanHandler";

class RevealHandler extends PanHandler {
    revealingObject: any
    revealingTimerId: any
    allRevealed: boolean
    constructor(canvas: fabric.Canvas) {
        super(canvas)
        this.init()
    }

    init() {
        this.allRevealed = false
    }

    prepare() {
        this.canvas.isDrawingMode = false

        // select 만 detect 할 수 있게
        const {canvas} = this
        canvas.forEachObject(function (o) {
            o.hasControls = false
            o.lockMovementX = true
            o.lockMovementY = true
            o.hasBorders = false
            o.selectable = false
            if (o.name === 'ConcealRect') {
                o.hoverCursor = 'pointer'
            } else {
                o.hoverCursor = 'default'
            }
        });
    }

    mouseMove(ie) {
        super.mouseMove(ie);
    }

    mouseDown(ie) {
        if (ie.target && ie.target.name === 'ConcealRect') {
            if (this.revealingObject) {
                this.clearCurrentRevelingObject()
            }

            this.revealingObject = ie.target
            this.revealObject(this.revealingObject)
            ie.e.preventDefault() // 여기서 막아야 FabricNode 가 EditMode 로 들어가지 않는다.
        }
    }

    mouseUp(ie) {
        super.mouseUp(ie);
    }

    clearCurrentRevelingObject() {
        if (this.revealingObject) {
            clearTimeout(this.revealingTimerId)
            this.concealObject(this.revealingObject)
            this.revealingObject = null
            this.canvas.discardActiveObject()
            this.canvas.requestRenderAll()
        }
    }

    private concealObject(obj) {
        obj.set({
            fill: 'rgba(255,255,255,255)'
        })
    }

    private revealObject(obj) {
        obj.set({
            fill: 'rgba(255,255,255,0)'
        })

        this.revealingTimerId = setTimeout(() => {
            this.clearCurrentRevelingObject()
        }, 2000)
    }

    deleteCurrentRevealingObject() {
        if (this.revealingObject) {
            const {canvas} = this
            canvas.remove(this.revealingObject)
        }
    }

    toggleRevealAll() {
        this.clearCurrentRevelingObject()

        const {canvas} = this
        const that = this
        const allObjects = canvas.getObjects()
        allObjects.forEach(function (o) {
            if (o.name === 'ConcealRect') {
                if (!that.allRevealed) {
                    that.revealObject(o)
                } else {
                    that.concealObject(o)
                }
            }
        })
        canvas.requestRenderAll()
        this.allRevealed = !this.allRevealed

        return this.allRevealed
    }

    // onSelectionUpdated(objs) {
    //     if (this.allRevealed) {
    //         this.toggleRevealAll()
    //     }
    //     if (objs && objs.length === 1) {
    //         const obj = objs[0]
    //         if (obj.name === 'ConcealRect') {
    //             console.log('1')
    //             this.revealTemporarily(obj)
    //         }
    //     }
    // }

    // private revealTemporarily(obj) {
    //     console.log('2')
    //     if (this.revealingObject) {
    //         clearTimeout(this.revealingTimerId)
    //         this.clearCurrentRevelingObject()
    //     }
    //
    //     this.revealingObject = obj!
    //
    //     this.revealObject(this.revealingObject)
    //
    //     const that = this
    //     this.revealingTimerId = setTimeout(() => {
    //         that.clearCurrentRevelingObject()
    //     }, 2000)
    // }
}

export default RevealHandler
