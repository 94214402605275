import userApi from "../../api/userApi"

const state = {
    user: {
        id: "",
        email: "",
        nickname: "",
        picture: ""
    }
}

const getters = {
    getUser: (state) => {
        return state.user
    }
}

const actions = {
    // deprecated
    async login({commit, rootState, state, store}) {
        const userWidthFolderData = await userApi.loginAndGetDetail()
        commit('setUser', userWidthFolderData.user)
        commit('folders/setFolders', userWidthFolderData.folders, {root:true})
    },

    setUser({commit}, user) {
        commit('setUser', user)
    },

    clearUserAndData({commit}) {
        commit('setUser', null)
        commit('folders/clearAll', null, {root:true})
    }
}

const mutations = {
    setUser(state, user) {
        state.user = user
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
