import { TableCell } from "@tiptap/extension-table-cell";
import {Editor as VueEditor, NodeView, VueNodeViewRenderer} from "@tiptap/vue-3";
import {Plugin} from "prosemirror-state";
import {TableNodeViewEx} from "@/components/HtmlEditor/extensions/table/TableNodeViewEx";
import {TableCellNodeViewEx} from "@/components/HtmlEditor/extensions/table/deprecated/TableCellNodeViewEx";
import TableCellMeuComponent from "@/components/HtmlEditor/extensions/tableCellEx/TableCellMenuTrigger.vue"
import TableCellComponent from "@/components/HtmlEditor/extensions/table/deprecated/TableCellComponent.vue"
import {Decoration} from "prosemirror-view";
import {mergeAttributes} from "@tiptap/core";

export const TableCellEx = TableCell.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            backgroundColor: {
                default: null,
                renderHTML: (attributes) => {
                    if (!attributes.backgroundColor) {
                        return {}
                    }

                    return {
                        style: `background-color: ${attributes.backgroundColor}`,
                    }
                },
                parseHTML: (element) => {
                    return element.style.backgroundColor.replace(/['"]+/g, '')
                },
            },
        }
    },

    // addProseMirrorPlugins() {
    //     const plugins = []
    //
    //     const decoPlugin = new Plugin({
    //         props: {
    //             decorations: (state) => {
    //                 if (state.selection) {
    //                     const node = state.selection.$anchor.node(-1)
    //                     if (node.type.name === 'tableCell') {
    //                         console.log('this ic cell')
    //                     }
    //                 }
    //                 // const decorations = [];
    //                 // const dom = document.createElement('div');
    //                 // dom.className = 'column-resize-handle';
    //                 // decorations.push(Decoration.widget(pos, dom));
    //
    //                 return null
    //             }
    //         }
    //     })
    //     plugins.push(decoPlugin)
    //     return plugins
    // },

    // addNodeView() {
    //     return (params) => {
    //         if (!(params.editor as VueEditor).contentComponent) {
    //             return {}
    //         }
    //
    //         // const {editor, node, getPos} = params
    //
    //         return new TableCellNodeViewEx(params, TableCellMeuComponent)
    //     }
    //     // return VueNodeViewRenderer(TableCellComponent)
    // },

    // addNodeView() {
    //     return VueNodeViewRenderer(TableCellComponent)
    // }

})

export default TableCellEx
