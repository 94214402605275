import Swal from 'sweetalert2'

// https://sweetalert2.github.io/#examples

const noAnimationClass = {
    backdrop: 'swal2-noanimation',
    popup: ''
}

export default {
    showWaitingAlert(title, msg = "", showIcon= false) {
        Swal.fire({
            title: title,
            html: msg,
            showClass: noAnimationClass,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })
    },
    showSucessAlert(title, msg = "", showIcon= false, timer = 2000) {
        Swal.fire({
            icon: showIcon ? 'success': '',
            title: title,
            html: msg,
            showConfirmButton: false,
            showClass: noAnimationClass,
            timer: timer,
            didOpen: () => {
                Swal.hideLoading()
            }
        })
    },
    showInfoAlert(title, msg = "", showIcon= false) {
        Swal.fire({
            icon: showIcon ? 'info': '',
            title: title,
            html: msg,
            showClass: noAnimationClass,
            showConfirmButton: false,
            timer: 2500,
            didOpen: () => {
                Swal.hideLoading()
            }
        })
    },
    showWarningAlert(title, msg = "", showIcon= false) {
        Swal.fire({
            icon: showIcon ? 'warning': '',
            title: title,
            html: msg,
            showClass: noAnimationClass,
            showConfirmButton: true,
            didOpen: () => {
                Swal.hideLoading()
            }
        })
    },
    showErrorAlert(title, msg = "", showIcon= false) {
        Swal.fire({
            icon: showIcon ? 'error': '',
            title: title,
            html: msg,
            showClass: noAnimationClass,
            didOpen: () => {
                Swal.hideLoading()
            }
        })
    },
    isVisible() {
        return Swal.isVisible()
    },
    close() {
        Swal.close();
    },
    showCautiousConfirm(title, content, confirmAction, denyAction, confirmButtonTitle = '삭제', denyButtonTitle = '취소') {
        this.showConfirmAlert(title, content, confirmAction, denyAction, confirmButtonTitle, '#f44336', denyButtonTitle)
    },
    showConfirmAlert(title, content, confirmAction, denyAction, confirmButtonTitle = '삭제', confirmButtonColor = '#3B82F6', denyButtonTitle = '취소') {
        Swal.fire({
            title: title,
            text: content,
            showDenyButton: true,
            reverseButtons: true,
            denyButtonText: denyButtonTitle,
            denyButtonColor: '#A1A1AA',
            confirmButtonText: confirmButtonTitle,
            confirmButtonColor: confirmButtonColor,
            showClass: noAnimationClass,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed && confirmAction) {
                confirmAction();
            } else if (result.isDenied && denyAction) {
                denyAction();
            }
        })
    },
    showInputConfirm(title, content, placeHolder, confirmAction, denyAction, confirmButtonTitle = '확인', denyButtonTitle = '취소') {
        // modal-dialog 위에서 띄우면 input 이 포커스를 받지 못한다
        // 호출하는 쪽 vue 에서 modal 에 data-bs-focus="false" 속성을 설정해주어야 한다.
        Swal.fire({
            title: title,
            input: 'text',
            inputValue: content,
            inputPlaceholder: placeHolder,
            focusConfirm: true,
            showDenyButton: true,
            reverseButtons: true,
            denyButtonText: denyButtonTitle,
            denyButtonColor: '#A1A1AA',
            confirmButtonText: confirmButtonTitle,
            confirmButtonColor: '#3B82F6',
            showClass: noAnimationClass,
        }).then((result) => {
            if (result.isConfirmed && confirmAction) {
                confirmAction(result.value);
            } else if (result.isDenied && denyAction) {
                denyAction();
            }
        })
    }
}
