import {fabric} from 'fabric';
import BaseHandler from "@/components/ImageEditor/controls/draw/BaseHandler";

class ZoomHandler extends BaseHandler {
    private maxZoom = 10
    private minZoom = 1

    constructor(canvas: fabric.Canvas) {
        super(canvas, null)
    }

    prepare() {
    }

    mouseDown(ie) {
    }

    mouseMove(ie) {
    }

    mouseUp(ie) {
    }

    mouseWheel(ie) {
        const { canvas } = this
        const delta = ie.e.deltaY;
        let zoom = canvas.getZoom();
        zoom *= 0.999 ** delta;
        if (zoom > this.maxZoom) zoom = this.maxZoom;
        if (zoom < this.minZoom) zoom = this.minZoom;
        if (zoom === canvas.getZoom()) {
            return
        }
        const center = canvas.getCenter();
        canvas.zoomToPoint({x: center.left, y: center.top}, zoom);
        ie.e.preventDefault();
        ie.e.stopPropagation();

        let vpt = canvas.viewportTransform!!;

        const rightEndPoint = canvas.getWidth() * (vpt[0] - 1);
        if(vpt[4] >= 0 || -rightEndPoint > vpt[4]) {
            vpt[4] = (vpt[4] >= 0) ? 0 : -rightEndPoint;
        }

        const bottomEndPoint = canvas.getHeight() * (vpt[0] - 1);
        if(vpt[5] >= 0 || -bottomEndPoint > vpt[5]) {
            vpt[5] = (vpt[5] >= 0) ? 0 : -bottomEndPoint;
        }
    }
}

export default ZoomHandler
