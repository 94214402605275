export const DrawCommand = [
    {type: 'SELECT', title: 'Select', icon: '' },
    {type: 'PAN', title: 'Pan', icon: '' },
    {type: 'LINE', title: 'Line', icon: '', },
    {type: 'RECTANGLE', title: 'Rect', icon: '', },
    {type: 'ELLIPSE', title: 'Circle', icon: '', },
    {type: 'DRAW', title: 'Draw', icon: '', },
    {type: 'TEXT', title: 'Text', icon: '', },
    {type: 'CONCEAL', title: 'Conceal', icon: '', },
]

export const ViewCommand = [
    {type: 'REVEAL', title: 'Reveal', icon: '', },
]
