import {fabric} from 'fabric';
import BaseHandler from "@/components/ImageEditor/controls/draw/BaseHandler";

class TextHandler extends BaseHandler {
    constructor(canvas: fabric.Canvas, drawProperty) {
        let drawPropertyForText = {
            ...drawProperty
        }
        if(drawPropertyForText.strokeWidth) {
            drawPropertyForText.strokeWidth = drawProperty.strokeWidth / 5
        }
        super(canvas, drawPropertyForText)

        this.addGlobalTextEventHandler()
    }

    canHandle(drawObject): boolean {
        return drawObject instanceof fabric.IText
    }

    addGlobalTextEventHandler() {
        const {canvas} = this;
        canvas.on("text:editing:exited", function (e) {
            if (e.target && e.target.type == 'i-text') {
                const textObject = e.target as fabric.IText
                if (typeof textObject.text == 'string' && textObject.text.length === 0) {
                    canvas.remove(textObject)
                }
            }
        })
    }

    mouseDown(ie) {
        const {canvas} = this;
        if (this.drawingObject) {
            this.drawingObject = null
            canvas.discardActiveObject()
            return
        }
        super.mouseDown(ie)
        super.createAndSetDrawingObject()
    }

    createObject() {
        const text = new fabric.IText('', {
            left: this.startPoint.x,
            top: this.startPoint.y,
            // fontFamily: '',
            fontSize: 24,
            // fontWeight: 'bold', // normal, 400, 600, 800
            // fill: '#ffffff',
            // stroke: '#000000'
        });

        return text
    }

    setAdditionalProperty() {
        this.drawingObject.set({
            cursorColor: this.drawProperty.fill,
            cursorDuration: 500
        })
    }

    updateObjectProperty(drawObject, partialProperty) {
        // stroke 변경시 fill 도 동일 색상으로
        // fill 변경시 stroke 도 동일 색상으로
        let property = partialProperty
        if (partialProperty.stroke) {
            property = {
                ...property,
                fill: partialProperty.stroke
            }
        }

        if (partialProperty.fill) {
            property = {
                ...property,
                stroke: partialProperty.fill
            }
        }

        // font 의 경우 숫자 조정
        if (partialProperty.strokeWidth) {
            property = {
                ...property,
                strokeWidth: partialProperty.strokeWidth / 5
            }
        }

        drawObject.set({
            ...property
        })

        drawObject.dirty = true
    }

    mouseMove(ie) {}

    mouseUp(ie) {
        const {canvas} = this;

        if (this.drawingObject) {
            canvas.setActiveObject(this.drawingObject)
            canvas.fire('object:custom_added',{ target:  this.drawingObject})
            const text = this.drawingObject as fabric.IText
            this.drawingObject.selectAll()
            this.drawingObject.enterEditing()
            this.drawingObject = null
        }
    }

    isDrawingObjectValid(point): boolean {
        return true
    }
}

export default TextHandler
