<template>
  <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" role="img">
    <g>
      <path fill="none" d="M0 0H24V24H0z"/>
      <path d="M12 13c2.761 0 5 2.239 5 5s-2.239 5-5 5-5-2.239-5-5 2.239-5 5-5zm1 2h-2v1.999L9 17v2l2-.001V21h2v-2.001L15 19v-2l-2-.001V15zm7-12c.552 0 1 .448 1 1v6c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h16zM5 5v4h14V5H5z"
            style="stroke: transparent; fill: white;"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'InsertRowSvg',
}
</script>
