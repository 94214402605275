import { fabric } from 'fabric';
import EventEmitter from 'events';
import hotkeys from 'hotkeys-js';

declare interface IPlugin {
    hotkeyEvent: (name: string, e: Event) => void;
    [propName: string]: any;
}

class CanvasComposer extends EventEmitter {
    canvas: fabric.Canvas;

    constructor(canvas) {
        super()
        this.canvas = canvas
    }

    addPlugin(plugin: CanvasPlugin, option: any = null) {
        const _plugin = new plugin(this.canvas, option)
        this.bindHotkeys(_plugin)
        return _plugin
    }

    private bindHotkeys(plugin: IPlugin) {
        plugin?.hotkeys?.forEach((keyName: string) => {
            hotkeys(keyName, { keyup: false, keydown: true }, (e) => plugin.hotkeyEvent(keyName, e));
        });
    }
}

export default CanvasComposer
