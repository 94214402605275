<template>
  <GoogleLogin v-if="!data.user" :callback="googleLoginCallback"/>
  <v-avatar v-else class="cursor-pointer" color="#aaaaaa" size="small" @click="logout">
    <v-img :src="data.user.picture"></v-img>
  </v-avatar>
</template>

<script setup>

import {computed, onMounted, reactive, ref} from "vue";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import { decodeCredential, googleLogout, googleOneTap } from 'vue3-google-login'
import cookies from 'vue-cookies';
import userApi from "@/api/userApi";
import commonAlert from "@/components/CommonAlert";
import {jwtDecode} from 'jwt-decode'

const data = reactive({
  gat: null,
  user: null
})

const store = useStore()
const router = useRouter()

store.subscribe((mutation, state) => {
  if (mutation.type === "users/setUser") {
    data.user = store.getters["users/getUser"]
  }
})

onMounted(() => {
  data.gat = cookies.get('gatkn')
  if (data.gat) {
    storeUser(jwtDecode(data.gat))
    // 저장된 유저가 여전히 유효한지 확인한다.
    userApi.refresh()
        .then((accessToken) => {
          if (!accessToken) {
            logout()
          }
        })

  } else {
    logout()
  }
  // googleOneTap({ autoLogin: true })
  //     .then((response) => {
  //       // This promise is resolved when user selects an account from the One Tap prompt
  //       console.log("Handle the response", response)
  //     })
  //     .catch((error) => {
  //       console.log("Handle the error", error)
  //     })
})

async function googleLoginCallback(response) {
  const result = await userApi.login(response.credential)
  if (result) {
    const user = result.user
    if (user.id) {
      storeLoginResponse(result)
    } else {
      // need sign up
      console.log(user)
      commonAlert.showConfirmAlert(
          '',
          `${user.email}로 가입하시겠습니까?`,
          () => { signUp(response.credential) },
          () => {},
          "가입",
      )
    }
  }

  // console.log(response.credential)
  // const userData = decodeCredential(response.credential)
  // console.log(userData)
  // if (userData.email_verified) {
  //   data.loginUser.name = userData.name
  //   data.loginUser.email = userData.email
  //   data.loginUser.image = userData.picture
  // }
}

function logout() {
  googleLogout()
  clearLogin()
  router.push({name: 'Home'})
}

async function signUp(token) {
  const result = await userApi.signup(token)
  if (result) {
    console.log(result)
    storeLoginResponse(result)
    commonAlert.showSucessAlert(
        "공노트에 가입되었습니다.\n도움말 노트를 참고하세요.\n지금 노트를 작성하고 공부를 시작해보세요.",
        false,
        10000
    )
  } else {
    clearLogin()
    commonAlert.showErrorAlert("가입에 실패하였습니다.\n구글 계정 확인 후 다시 시도해 주세요.")
  }
}

function storeLoginResponse(res) {
  const user = res.user
  storeUser(user)

  cookies.set('gatkn', res.accessToken);
  cookies.set('grtkn', res.refreshToken);
}

function clearLogin() {
  store.dispatch("users/setUser", null)
  cookies.remove('gatkn');
  cookies.remove('gatkn');
  data.user = null
  data.gat = null
}

function storeUser(user) {
  store.dispatch("users/setUser", {
    email: user.email,
    nickname: user.nickname,
    picture: user.picture
  })
}

</script>
