import {fabric} from 'fabric';
import BaseHandler from "@/components/ImageEditor/controls/draw/BaseHandler";

class FreeDrawHandler extends BaseHandler {
    constructor(canvas: fabric.Canvas, drawProperty) {
        super(canvas, drawProperty)
    }

    canHandle(drawObject): boolean {
        return !!drawObject.path
    }

    prepare() {
        this.canvas.isDrawingMode = true
        this.canvas.freeDrawingBrush.color = this.drawProperty.stroke
        this.canvas.freeDrawingBrush.width = this.drawProperty.strokeWidth
    }

    updateDrawProperty(partialProperty) {
        if (partialProperty.strokeWidth) {
            this.canvas.freeDrawingBrush.width = partialProperty.strokeWidth
        }
        if (partialProperty.stroke) {
            this.canvas.freeDrawingBrush.color = partialProperty.stroke
        }
    }

    mouseDown(ie) {}

    mouseMove(ie) {}

    mouseUp(ie) {}
}

export default FreeDrawHandler
