import {findParentNodeClosestToPos} from "@tiptap/core";

export function getNodeAttrsBetween(state, from, to) {
    let nodeAttr = null
    state.doc.nodesBetween(from, to, (node, pos) => {
        nodeAttr = node.attrs
    })

    return nodeAttr
}

export function selectionIsInTable(state) {
    const ret =
        findParentNodeClosestToPos(
            state.selection.$anchor,
            (node) => node.type.name === "table"
        )

    if (ret) {
        return !!ret.node
    } else {
        return false
    }
}
