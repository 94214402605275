<template>
  <div class="inline-block rounded-sm bg-white full-shadow">
<!--    <button @click="toggleBold"-->
<!--            class="editor-bubble-button"-->
<!--            :class="activeStyle('bold')">-->
<!--      B-->
<!--    </button>-->

<!--    <button @click="toggleItalic"-->
<!--            class="editor-bubble-button"-->
<!--            :class="activeStyle('italic')">-->
<!--      I-->
<!--    </button>-->

<!--    <button @click="toggleUnderline"-->
<!--            class="editor-bubble-button"-->
<!--            :class="activeStyle('underline')">-->
<!--      U̲-->
<!--    </button>-->

<!--    <button @click="setFontSize('14pt')"-->
<!--            class="editor-bubble-button"-->
<!--            :class="activeStyle('textStyle', { fontSize: '14pt' })">-->
<!--      12-->
<!--    </button>-->

<!--    <button @click="setFontSize('1pt')"-->
<!--            class="editor-bubble-button"-->
<!--            :class="activeStyle('textStyle', { fontSize: '14pt' })">-->
<!--      24-->
<!--    </button>-->

<!--    <button @click="setFontSize('16pt')"-->
<!--            class="editor-bubble-button"-->
<!--            :class="activeStyle('textStyle', { fontSize: '16pt' })">-->
<!--      36-->
<!--    </button>-->

<!--    <button @click="setFontSize('20pt')"-->
<!--            class="editor-bubble-button"-->
<!--            :class="activeStyle('textStyle', { fontSize: '20pt' })">-->
<!--      48-->
<!--    </button>-->

<!--    <button @click="decreaseFontSize"-->
<!--            class="editor-bubble-button">-->
<!--      ▼-->
<!--    </button>-->

<!--    <button @click="increaseFontSize()"-->
<!--            class="editor-bubble-button">-->
<!--      ▲-->
<!--    </button>-->

    <button v-if="activeStyle('conceal')" @click="unsetConceal"
            class="p-2 text-md">
      <font-awesome-icon icon="eye"/>
    </button>

    <button v-else @click="setConceal"
            class="p-2 text-md">
      <font-awesome-icon icon="eye-slash"/>
    </button>

    |

    <button @click="toggleHighlight('#fb923c' )"
            class="editor-bubble-button text-orange-400"
            :class="activeStyle('highlight', { color: '#fb923c' })">
      <font-awesome-icon icon="pen-clip" style="width: 0.8rem; height: 0.8rem"/>
    </button>

    <button @click="toggleHighlight('#a3e635' )"
            class="editor-bubble-button text-lime-400"
            :class="activeStyle('highlight', { color: '#a3e635' })">
      <font-awesome-icon icon="pen-clip"/>
    </button>

    <button @click="toggleHighlight('#22d3ee' )"
            class="editor-bubble-button text-cyan-400"
            :class="activeStyle('highlight', { color: '#22d3ee' })">
      <font-awesome-icon icon="pen-clip"/>
    </button>

    <button @click="toggleHighlight('#c084fc' )"
            class="editor-bubble-button text-purple-400"
            :class="activeStyle('highlight', { color: '#c084fc' })">
      <font-awesome-icon icon="pen-clip"/>
    </button>

  </div>
</template>

<script setup>

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const props = defineProps({
  editor: null,
  isInTable: false
})

function activeStyle(v, o) {
  return props.editor.isActive(v, o) ? 'editor-bubble-button-active' : ''
}

function setFontSize(fontSize) {
  props.editor.chain().focus().setFontSize(fontSize).run()
}

function toggleBold() {
  props.editor.chain().focus().toggleBold().run()
}

function toggleItalic() {
  props.editor.chain().focus().toggleItalic().run()
}

function toggleUnderline() {
  props.editor.chain().focus().toggleUnderline().run()
}

function toggleHighlight(color) {
  props.editor.chain().focus().toggleHighlight({color: color}).run()
}

function setTextAlign(value) {
  props.editor.chain().focus().setTextAlign(value).run()
}

function unsetConceal() {
  props.editor.chain().focus().unsetConceal().run()
}

function setConceal() {
  props.editor.chain().focus().setConceal({reveal: true, revealColor: 'red'}).run()
}

function increaseFontSize() {
  const mark = props.editor.chain().focus().mark
  // const state = this.htmlEditor.view.state
  // console.log(JSON.stringify(state.selection))
  // const currentFontSize = this.htmlEditor.chain().focus().setFontSize()
  // console.log(currentFontSize)
  // this.htmlEditor.chain().focus().setFontSize('48pt').run()
}

function decreaseFontSize() {
  props.editor.chain().focus().setFontSize('48pt').run()
}

</script>
