
class ImageLogic {
    async loadBlobUrlFromImageFile(file) {
        const that = this
        return new Promise(resolve => {
            let reader = new FileReader();
            reader.onload = e => {
                const url = that.dataURItoBlobURL(e?.target?.result)
                resolve(url)
            }
            reader.readAsDataURL(file)
        })
    }

    dataURItoBlobURL(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        let byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        let ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        let ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        let blob = new Blob([ab], {type: mimeString});
        return URL.createObjectURL(blob)
    }
}

export default ImageLogic
