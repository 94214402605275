import {fabric} from 'fabric';

class BaseHandler{
    public canvas: fabric.Canvas
    drawingObject: any
    isMouseDown: boolean
    startPoint: any
    commonProperty = {
        strokeUniform: true,
        cornerSize: 10,
        // hasBorders: true
    }
    drawProperty: any
    drawLengthThreshold = 5

    constructor(canvas: fabric.Canvas, drawProperty) {
        this.canvas = canvas
        this.drawProperty = drawProperty
    }

    prepare() {
        this.isMouseDown = false
        this.canvas.isDrawingMode = false
        this.canvas.selectionBorderColor = 'rgba(1,1,120,0.15)'
        this.canvas.selectionColor = 'rgba(0,0,0,0)'
    }

    createAndSetDrawingObject() {
        const {canvas} = this;

        this.drawingObject = this.createObject()
        this.drawingObject.set({
            ...this.commonProperty,
            strokeWidth: this.drawProperty.strokeWidth,
            stroke: this.drawProperty.stroke,
            fill: this.drawProperty.fill,
            perPixelTargetFind: true  // cause warning : fabric.js:1572 Canvas2D: Multiple readback operations using getImageData are faster
        })
        this.setAdditionalProperty()
        this.drawingObject.setControlsVisibility({
            mtr: false, ml: false, mt: false, mb: false, mr: false
        })

        canvas.add(this.drawingObject)
    }

    setAdditionalProperty() {}

    updateDrawProperty(partialProperty) {
        if (this.drawProperty) {
            Object.assign(this.drawProperty, partialProperty)
        }
    }

    canHandle(drawObject) {
        return false
    }

    updateObjectProperty(drawObject, partialProperty) {
        drawObject.set({
            ...partialProperty
        })
        drawObject.dirty = true
    }

    mouseDown(ie) {
        this.isMouseDown = true
        this.startPoint = this.canvas.getPointer(ie.e)
    }

    mouseMove(ie) {
        if (!this.isMouseDown) return
        const point = this.canvas.getPointer(ie.e)
        if (!this.drawingObject) {
            if (this.isDrawingObjectValid(point)) {
                this.createAndSetDrawingObject()
            }
        } else {
            this.updateObjectByMouse(point)
        }
    }

    mouseUp(ie) {
        if (!this.isMouseDown) return

        const {canvas} = this;
        const point = canvas.getPointer(ie.e)

        if (this.drawingObject) {
            if (this.isDrawingObjectValid(point)) {
                canvas.setActiveObject(this.drawingObject)
                canvas.fire('object:custom_added',{ target:  this.drawingObject})
            } else {
                canvas.remove(this.drawingObject)
            }
        }

        this.startPoint = null
        this.drawingObject = null
    }

    mouseWheel(ie) {
    }

    createObject() {}

    updateObjectByMouse(point) {}

    isDrawingObjectValid(point) {
        return false
    }
}


export default BaseHandler
