import {fabric} from 'fabric';
import {throttle} from 'lodash-es';
import ImageLogic from "@/components/logic/ImageLogic";
import {findParentNodeClosestToPos} from "@tiptap/core";

class WorkspacePlugin {
    public canvas: fabric.Canvas
    containerEl: HTMLElement
    resizeObserver: any
    // originalImageBoundingRect: any
    initialCanvasSize: any
    imageLogic = new ImageLogic()

    constructor(canvas: fabric.Canvas, option) {
        this.canvas = canvas

        this.init(option)
    }

    init(option) {
        if (!option) {
            return
        }

        const containerEl = document.getElementById(option.containerId) as HTMLElement
        if (!containerEl) {
            return
        }

        this.containerEl = containerEl
        this.initResizeObserver()
    }

    private initResizeObserver() {
        this.resizeObserver = new ResizeObserver(
            throttle(() => {
                this.onContainerResize()
            }, 50)
        )
        this.resizeObserver.observe(this.containerEl);
    }

    private onContainerResize() {
        const viewPortWidth = this.containerEl.offsetWidth
        const viewPortHeight = this.containerEl.offsetHeight

        this.canvas.setWidth(viewPortWidth);
        this.canvas.setHeight(viewPortHeight);
    }

    addBackgroundImageWithCanvasFitToImage({url, widthFit}, callback) {
        const {canvas} = this

        const that = this
        fabric.Image.fromURL(url, (_img) => {
            // _img.set({left: 0, top: 0, width: _img.width, height: _img.height})
            const scale = this.getImageScaleToFitCanvas(_img.width, _img.height, widthFit)
            const scaledImage = _img.scale(scale) as fabric.Image
            canvas.setBackgroundImage(scaledImage, function () {
                canvas.setWidth(scaledImage.getScaledWidth())
                canvas.setHeight(scaledImage.getScaledHeight())
                canvas.centerObject(scaledImage)

                that.setInitialCanvasSize(canvas.width, canvas.height)
                // that.originalImageBoundingRect = scaledImage.getBoundingRect()
                canvas.requestRenderAll()
                if (callback) {
                    callback()
                }
            })
        }, {crossOrigin: 'anonymous'})
    }

    private setInitialCanvasSize(width, height) {
        this.initialCanvasSize = {
            width: width,
            height: height
        }
    }

    private getImageScaleToFitCanvas(imageWidth, imageHeight, widthFit) {
        const {canvas} = this

        // 이미지가 캔버스보다 크면 이미지를 줄여야한다. 이 때 스케일을 구한다
        // 이미지가 캔버스보다 작으면 이미지를 그대로 표시한다. 이 때 스케일은 1 이다
        let scale = 1
        const canvasWidth = canvas.getWidth()
        const canvasHeight = canvas.getHeight()
        if (canvasHeight < imageHeight || canvasWidth < imageWidth) {
            const canvasRatio = canvasHeight / canvasWidth
            const imageRatio = imageHeight / imageWidth
            if (widthFit || (imageRatio <= canvasRatio)) {
                // width first
                scale = canvasWidth / imageWidth
            } else {
                // height first
                scale = canvasHeight / imageHeight
            }
        }

        return scale
    }

    save(overwriteCanvasSize) {

        const imageBase64Data = this.saveToImageData()
        // blobUrl 이 필요한가? uuid 로 임의의 url 을 만들어서 유지하면 될 듯?
        const blobUrl = this.imageLogic.dataURItoBlobURL(imageBase64Data)

        const jsonString = this.saveToJson(overwriteCanvasSize)

        return {blobUrl, jsonString}
    }

    saveToImageData() {
        const {canvas} = this
        const transform = canvas.viewportTransform!.slice();
        canvas.viewportTransform = [1, 0, 0, 1, 0, 0];

        const dataUrl = canvas.toDataURL({
            format: 'png',
            quality: 1,
            // left: this.originalImageBoundingRect.left,
            // top: this.originalImageBoundingRect.top,
            // width: this.originalImageBoundingRect.width,
            // height: this.originalImageBoundingRect.height
        })

        canvas.viewportTransform = transform;

        return dataUrl
    }

    private saveToJson(overwriteCanvasSize) {
        const {canvas} = this

        const json = canvas.toDatalessJSON(['name', 'id'])
        // HTML Editor의 FabricComponent 에서 저장(ConcealRect 색상 변경후)시에는
        // 다른 객체들의 크기가 캔버스 사이즈에 맞춰서 변경,저장되지 않으므로
        // 이 때는 캔버스 사이즈도 수정하지 않는다.
        const jsonWithCanvasSize = {
            ...json,
            canvas: {
                width: overwriteCanvasSize ? canvas.width : this.initialCanvasSize.width,
                height: overwriteCanvasSize ? canvas.height : this.initialCanvasSize.height
            }
        }

        const out = JSON.stringify(jsonWithCanvasSize, null, "\t")

        console.log(out)

        /* download json file
        const blob = new Blob([out], {type: "text/plain"})

        const blobURL = URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.href = blobURL
        link.download = "fabric1"
        link.click()
        URL.revokeObjectURL(blobURL)
         */

        return out

        /* download json file
        const blob = new Blob([out], {type: "text/plain"})

        const blobURL = URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.href = blobURL
        link.download = "fabric_example"
        link.click()
        URL.revokeObjectURL(blobURL)
         */
    }

    loadFromJson(jsonString, widthFit, selectable, callback) {
        const {canvas} = this

        const jsonData = JSON.parse(jsonString)
        const jsonCanvasSize = {
            width: jsonData.canvas.width,
            height: jsonData.canvas.height
        }
        this.setInitialCanvasSize(jsonCanvasSize.width, jsonCanvasSize.height)

        let scaleByCanvas = 1

        let widthFirst = widthFit
        if (!widthFit) {
            // 가로를 맞추는 상황이 아니면 (ImageEditor 에서 로드할 때)
            // jsonCanvas 와 canvas element 의 가로 세로 비율을 비교해서
            // 가로 또는 세로 중 기준을 잡는다
            const canvasRatio = canvas.height / canvas.width
            const jsonCanvasRatio = jsonCanvasSize.height / jsonCanvasSize.width
            widthFirst = canvasRatio >= jsonCanvasRatio
        }

        if (widthFirst) {
            // 가로를 기준으로 하고 세로는 가변
            const canvasHeight = canvas.width * (jsonCanvasSize.height / jsonCanvasSize.width)
            canvas.setHeight(canvasHeight)
            scaleByCanvas = (canvas.width !== jsonCanvasSize.width) ?  (canvas.width / jsonCanvasSize.width) : 1
        } else {
            // 세로를 기준으로 하고 가로는 가변
            const canvasWidth = canvas.height * (jsonCanvasSize.width / jsonCanvasSize.height)
            canvas.setWidth(canvasWidth)
            scaleByCanvas = (canvas.height !== jsonCanvasSize.height) ?  (canvas.height / jsonCanvasSize.height) : 1
        }

        canvas.loadFromJSON(jsonString,
            () => {
                // const backgroundImage = canvas.backgroundImage as fabric.Image
                canvas.setZoom(scaleByCanvas)

                // zoom 된 canvas 크기로 설정해야 한다.
                // thisPlugin.originalImageBoundingRect =
                canvas.renderAll()

                if (callback) {
                    callback()
                }
            },
            function (jsonObject, fabricObject) {
                if (!selectable) {
                    fabricObject.selectable = false
                }
            })
    }

    changeCanvasSize(width, height) {
        const {canvas} = this

        const scaleByCanvas = (width !== this.initialCanvasSize.width) ? (width / this.initialCanvasSize.width) : 1

        canvas.setWidth(width)
        canvas.setHeight(height)
        canvas.setZoom(scaleByCanvas)

        canvas.requestRenderAll()

        // this.originalImageBoundingRect = backgroundImage.getBoundingRect()
    }

    dispose() {
        if (this.resizeObserver) {
            this.resizeObserver.unobserve(this.containerEl)
        }
    }

    getTableElement(editor) {
        const nearestTableParent =
            findParentNodeClosestToPos(
                editor.state.selection.$anchor,
                (node) => node.type.name === "table"
            )


        if (nearestTableParent) {
            const wrapperDomNode = editor.view.nodeDOM(nearestTableParent.pos)

            // The DOM node of a Tiptap table node is a div wrapper, which contains a `table` child.
            // The div wrapper is a block element that fills the entire row, but the table may not be
            // full width, so we want to get our bounding rectangle based on the `table` (to align it
            // with the table itself), not the div. See
            // https://github.com/ueberdosis/tiptap/blob/40a9404c94c7fef7900610c195536384781ae101/packages/extension-table/src/TableView.ts#L69-L71
            // return wrapperDomNode
            // return wrapperDomNode?.querySelector("table") as HTMLElement
            return wrapperDomNode?.querySelector("table");
            // if (tableDomNode) {
            //     console.log(tableDomNode)
            //     return tableDomNode.getBoundingClientRect();
            // }
        }
    }
}

export default WorkspacePlugin
