<template>
  <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" role="img">
    <g>
      <path fill="none" d="M0 0H24V24H0z"/>
      <path d="M20 3c.552 0 1 .448 1 1v16c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h16zm-9 2H5v14h6v-4h2v4h6V5h-6v4h-2V5zm4 4l3 3-3 3v-2H9v2l-3-3 3-3v2h6V9z"
            style="stroke: transparent; fill: white;"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SplitCellSvg',
}
</script>
