<template>
  <svg :fill="strokeColor" width="90%" height="90%" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       viewBox="0 0 512 512"  xml:space="preserve">
    <g>
      <path class="st0" d="M410.34,153.043c-1.278-1.293-2.712-2.477-4.288-3.527l-1.615-0.337c-0.846-0.368-1.52-0.972-1.96-1.702
		c-2.524-1.191-5.166-1.959-7.854-2.343c-2.321-0.322-3.951-2.477-3.614-4.814c0.313-2.336,2.484-3.966,4.805-3.645
		c2.658,0.392,5.322,1.067,7.878,2.07c0.415-1.866,0.65-3.771,0.65-5.691c-0.008-4.202-0.995-8.443-3.096-12.433
		c-2.399-4.562-5.918-8.121-10.034-10.55c-3.606-2.132-7.682-3.402-11.868-3.7c-0.305,3.817-1.214,7.619-2.798,11.303
		c-0.941,2.163-3.441,3.159-5.612,2.226c-2.164-0.925-3.167-3.433-2.234-5.597c1.52-3.55,2.234-7.203,2.25-10.825l-0.055-1.544
		c-0.29-5.017-1.952-9.861-4.782-13.992c-2.83-4.124-6.796-7.502-11.734-9.642c-3.543-1.513-7.212-2.226-10.826-2.226
		c-6.922,0-13.624,2.666-18.687,7.368c0.948,3.222,1.489,6.616,1.489,10.144c0,2.352-1.928,4.264-4.28,4.264
		s-4.272-1.912-4.272-4.264c0-3.457-0.635-6.734-1.795-9.783h0.008c-1.968-5.134-5.448-9.555-9.908-12.675
		c-4.445-3.119-9.83-4.946-15.693-4.946c-5.087,0-9.83,1.388-13.906,3.786c-3.261,1.928-6.075,4.531-8.285,7.588
		c4.319,5.918,6.874,13.232,6.874,21.118c0,2.351-1.92,4.256-4.28,4.256c-2.352,0-4.272-1.905-4.272-4.256
		c0-6.929-2.548-13.232-6.788-18.053c-5.032-5.746-12.377-9.343-20.608-9.343c-5.714,0-10.974,1.732-15.372,4.711
		c-4.382,2.979-7.87,7.196-9.932,12.15c-1.332,3.237-2.093,6.804-2.093,10.535c0,2.351-1.905,4.256-4.264,4.256
		c-2.36,0-4.264-1.905-4.264-4.256c0-3.465,0.502-6.804,1.411-9.987c-3.214-1.301-6.694-2.022-10.268-2.022
		c-2.03,0-4.068,0.235-6.138,0.698c-6.083,1.403-11.17,4.68-14.894,9.116c-2.681,3.199-4.594,7-5.589,11.084
		c8.819,0.156,17.629,3.566,24.434,10.229c1.678,1.654,1.709,4.351,0.063,6.028c-1.654,1.693-4.351,1.709-6.036,0.07
		c-5.346-5.212-12.236-7.823-19.166-7.831c-1.238,0-2.477,0.086-3.716,0.243h-0.015h-0.008c-5.808,0.807-11.405,3.456-15.85,7.988
		c-5.221,5.33-7.808,12.213-7.815,19.158c0,3.959,0.862,7.948,2.586,11.617c1.34,2.892,3.222,5.589,5.652,7.956v0.007
		c2.744,2.697,5.911,4.688,9.29,5.989c2.186,0.855,3.292,3.324,2.43,5.518c-0.854,2.211-3.316,3.309-5.518,2.446
		c-4.413-1.709-8.583-4.326-12.19-7.862c-1.575-1.552-2.979-3.206-4.224-4.961c-3.222,4.617-4.97,10.142-4.97,15.771
		c0,4.359,1.043,8.756,3.245,12.887c2.477,4.625,6.06,8.231,10.277,10.692c3.331,1.952,7.055,3.183,10.896,3.614l-0.008-0.259
		c0-2.258,0.212-4.468,0.596-6.616c0.439-2.32,2.673-3.834,4.978-3.418c2.32,0.431,3.849,2.657,3.433,4.977
		c-0.306,1.638-0.47,3.339-0.47,5.056l0.07,1.984l0.227,1.999v0.023l0.008,0.039c1.96,13.389,13.53,23.398,27.075,23.398
		l1.968-0.078h0.008c6.004-0.431,11.397-2.752,15.677-6.365c3.002-2.532,5.463-5.706,7.149-9.282
		c0.995-2.14,3.558-3.048,5.683-2.038c2.132,1.004,3.042,3.543,2.038,5.676c-1.826,3.849-4.319,7.321-7.313,10.276
		c1.677,5.158,4.828,9.587,8.944,12.879c4.704,3.771,10.637,5.997,17.049,5.997l1.976-0.078h0.008
		c4.06-0.29,7.815-1.458,11.147-3.277c-0.518-2.453-0.815-4.961-0.815-7.501c0-5.237,1.168-10.575,3.582-15.592
		c1.027-2.124,3.574-3.01,5.691-1.983c2.124,1.011,3.026,3.567,1.999,5.691c-1.858,3.849-2.728,7.894-2.728,11.884
		c0,2.79,0.431,5.573,1.262,8.214h0.008c2.187,6.969,7.126,13.06,14.235,16.477c3.841,1.858,7.886,2.728,11.868,2.728
		c6.326,0,12.511-2.211,17.418-6.216l0.016-0.008c2.979-2.454,5.472-5.558,7.266-9.266l0.008-0.016
		c1.67-3.464,2.548-7.093,2.696-10.715c0.11-2.352,2.093-4.186,4.46-4.076c2.352,0.102,4.17,2.092,4.068,4.444
		c-0.204,4.75-1.356,9.547-3.543,14.07v-0.008c-1.756,3.661-4.068,6.883-6.78,9.634c5.032,7.227,13.404,11.782,22.458,11.774
		c1.677,0,3.386-0.156,5.103-0.486c6.592-1.238,12.15-4.727,16.101-9.524c3.943-4.806,6.24-10.912,6.24-17.356l-0.008-0.579v-0.032
		c-0.031-1.45-0.18-2.97-0.462-4.5c-0.682-3.629-2.07-6.922-3.974-9.83c-2.759-2.43-5.824-5.604-8.716-9.132
		c-3.081-3.762-5.918-7.878-7.823-12.024c-1.254-2.775-2.148-5.581-2.164-8.536c0-1.309,0.18-2.665,0.674-3.966
		c0.478-1.325,1.262-2.579,2.312-3.614c1.662-1.654,4.358-1.654,6.028,0c1.662,1.677,1.662,4.389,0,6.044l-0.322,0.51l-0.164,1.027
		c-0.016,1.074,0.431,2.893,1.395,4.985c0.956,2.086,2.375,4.429,4.045,6.741c3.308,4.633,7.596,9.18,10.825,11.954l0.439,0.377
		l0.322,0.478c2.642,3.935,4.578,8.466,5.51,13.412v0.008l0.306,2.116l1.278,0.055c5.409-0.008,10.856-1.607,15.646-4.97
		c3.81-2.681,6.71-6.122,8.67-9.987c1.967-3.872,2.994-8.152,2.994-12.463c0-4.037-0.91-8.09-2.728-11.86
		c-1.686,0.541-3.395,0.972-5.127,1.27c-2.32,0.415-4.523-1.161-4.938-3.48c-0.393-2.328,1.176-4.523,3.496-4.931
		c7.556-1.301,14.478-5.706,18.758-12.777c2.712-4.444,3.99-9.336,3.99-14.172c0-7.126-2.752-14.094-7.878-19.26L410.34,153.043z
		 M214.788,120.481c4.076-4.821,9.485-8.247,15.301-10.489c5.84-2.242,12.095-3.316,18.1-3.316
		c5.84,0.008,11.452,0.996,16.218,3.207c2.132,0.971,3.065,3.519,2.077,5.659c-0.98,2.133-3.512,3.073-5.667,2.086
		c-3.292-1.544-7.8-2.43-12.628-2.43c-4.97,0-10.268,0.916-15.027,2.767c-4.758,1.826-8.936,4.554-11.876,8.019
		c-1.505,1.803-4.209,2.038-6.012,0.502C213.479,124.965,213.252,122.276,214.788,120.481z M311.925,221.396
		c-4.962,0-9.462-0.564-13.569-1.512c0.11,1.175,0.188,2.484,0.188,3.872c0,2.783-0.306,5.964-1.176,9.304
		c-0.878,3.331-2.359,6.835-4.695,10.198c-1.34,1.944-3.998,2.422-5.934,1.081c-1.944-1.341-2.414-3.99-1.09-5.942
		c1.724-2.485,2.798-5.025,3.457-7.525c0.659-2.5,0.902-4.931,0.902-7.117c0.008-3.018-0.455-5.503-0.784-6.922
		c-2.085-0.941-4.006-1.999-5.8-3.183c-7.149-4.625-11.93-10.731-14.933-16.54c-2.014-3.896-3.238-7.658-3.896-10.927
		c-0.376-1.866-0.564-3.558-0.564-5.096c0-2.367,1.913-4.28,4.272-4.28c2.359,0,4.272,1.913,4.272,4.28
		c-0.008,1.238,0.259,3.316,0.933,5.676c0.674,2.367,1.748,5.056,3.3,7.76c3.112,5.44,8.058,10.888,15.693,14.424
		c5.103,2.367,11.452,3.903,19.424,3.903c2.359,0,4.272,1.913,4.272,4.272C316.197,219.484,314.285,221.396,311.925,221.396z
		 M341.979,190.229c-0.878,2.18-3.363,3.246-5.558,2.375c-2.187-0.878-3.237-3.347-2.375-5.534v-0.008l0.024-0.063l0.126-0.376
		l0.439-1.536c0.322-1.348,0.651-3.253,0.651-5.346l-0.008-0.298c-5.863,1.991-11.303,2.884-16.312,2.884
		c-7.572,0.016-14.125-2.061-19.456-5.212c-5.338-3.15-9.492-7.321-12.597-11.562c-3.527-4.805-8.152-9.116-13.451-12.165
		c-5.314-3.058-11.256-4.876-17.566-4.892c-5.526,0.016-11.405,1.395-17.543,4.766c-5.824,3.198-9.571,7.454-11.993,12.126
		c-2.406,4.68-3.425,9.79-3.418,14.447c-0.016,4.311,0.894,8.214,2.077,10.676c1.011,2.132,0.094,4.672-2.03,5.683
		c-2.124,1.012-4.672,0.094-5.683-2.03c-1.858-3.936-2.892-8.89-2.908-14.329c0.008-5.409,1.082-11.32,3.724-16.971
		c-5.298,1.2-10.221,1.733-14.705,1.733c-2.422,0-4.704-0.157-6.875-0.439l-0.792,1.599l-0.008,0.015l-0.063,0.064l-0.227,0.29
		l-0.91,1.238c-0.776,1.114-1.85,2.736-3.018,4.805c-2.328,4.1-4.993,9.885-6.287,16.382c-0.462,2.313-2.704,3.826-5.017,3.355
		c-2.312-0.462-3.818-2.704-3.339-5.032c1.536-7.706,4.571-14.259,7.212-18.923c1.379-2.414,2.642-4.326,3.566-5.628
		c-3.48-1.042-6.49-2.32-8.951-3.622c-5.62-2.987-8.545-5.981-8.795-6.224c-1.631-1.701-1.584-4.398,0.109-6.036
		c1.701-1.63,4.39-1.583,6.028,0.094l0.048,0.047l0.25,0.243l1.223,1.026c1.112,0.878,2.83,2.086,5.126,3.308
		c4.609,2.43,11.492,4.892,20.718,4.908c6.482,0,14.125-1.238,23.006-4.656c2.25-2.093,4.844-4.005,7.823-5.636
		c7.306-4.005,14.666-5.808,21.65-5.808c7.98,0,15.403,2.321,21.831,6.02c6.428,3.716,11.876,8.803,16.069,14.525
		c2.548,3.456,5.895,6.804,10.042,9.241c4.17,2.446,9.1,4.038,15.128,4.045c4.131,0,8.787-0.768,14.055-2.618
		c-1.285-2.062-3.198-4.053-6.349-5.856c-2.054-1.168-2.783-3.77-1.623-5.816c1.168-2.062,3.763-2.768,5.816-1.623
		c5.048,2.846,8.427,6.616,10.386,10.559c1.983,3.927,2.571,7.909,2.571,11.296C343.797,185.652,342.096,189.924,341.979,190.229z
		 M340.38,137.938c-9.971,0-18.695-2.689-25.75-6.757c-0.995,1.38-2.328,3.112-4.045,4.993c-3.864,4.241-9.54,9.219-17.135,11.946
		c-2.234,0.784-4.664-0.36-5.456-2.586c-0.792-2.227,0.376-4.664,2.579-5.448c5.605-1.999,10.292-5.926,13.538-9.477
		c1.505-1.623,2.673-3.159,3.52-4.335c-4.21-3.558-7.533-7.611-9.814-11.781c-2.163-3.998-3.418-8.121-3.433-12.127
		c0-2.351,1.913-4.264,4.272-4.264c2.352,0,4.264,1.913,4.264,4.264c-0.016,2.156,0.737,5.033,2.398,8.051
		c1.638,3.025,4.131,6.176,7.392,8.999c6.522,5.652,15.936,9.978,27.671,9.978c2.359,0,4.264,1.921,4.264,4.28
		C344.644,136.025,342.739,137.938,340.38,137.938z M374.023,198.037c-1.497,1.803-4.194,2.069-6.012,0.556
		c-1.818-1.497-2.069-4.193-0.58-6.004c4.656-5.652,6.373-11.343,6.388-16.79c0.016-4.962-1.505-9.752-3.888-13.788
		c-2.352-4.03-5.604-7.259-8.732-9.054c-2.101-1.207-4.076-1.748-5.644-1.748c-2.36,0-4.264-1.904-4.264-4.264
		c0-2.359,1.905-4.272,4.264-4.272c2.594,0,5.134,0.635,7.549,1.701c2.406,1.051,4.68,2.548,6.796,4.375
		c4.225,3.652,7.815,8.622,10.073,14.447c1.489,3.887,2.398,8.152,2.398,12.604C382.379,183.12,379.879,190.958,374.023,198.037z"
            />
      <path class="st0" d="M172.459,411.86l0.291,0.259c-0.008-0.008-0.008-0.008-0.016-0.016L172.459,411.86z"
            />
      <path class="st0" d="M428.699,66.574C396.943,29.442,349.183,4.155,287.986,0.462C283.008,0.156,278.054,0,273.147,0
		c-51.469,0-98.815,16.712-133.524,47.393c-34.733,30.602-56.36,75.408-56.282,129.072c0,1.215,0.008,2.43,0.031,3.653l-0.054,6.474
		l-44.179,78.018c-2.375,4.209-3.575,8.905-3.575,13.601c0,3.918,0.832,7.854,2.516,11.522c3.567,7.784,10.582,13.381,18.891,15.309
		l-0.016,0.07l20.318,5.597l8.027,77.423l0.063-0.008c0.565,11.28,5.597,21.87,14.142,29.301
		c7.596,6.616,17.292,10.206,27.263,10.206c1.01,0,2.006-0.094,3.01-0.164l23.022,1.826l1.153-0.125l0.368-0.016
		c0.885,0,1.709,0.314,2.344,0.886l0.038,0.04c0.73,0.658,1.161,1.622,1.161,2.61V512h214.96v-12.04c0,0,0-32.616,0-45.628
		c-0.024-5.237,1.019-15.591,3.073-25.578c1.019-5.008,2.28-10.002,3.708-14.32c1.411-4.311,3.042-7.98,4.405-10.12
		c14.298-23.046,34.514-41.952,53.028-68.69c18.492-26.714,34.255-61.369,38.574-113.951c0.549-6.592,0.824-13.185,0.824-19.745
		C476.436,152.079,460.508,103.674,428.699,66.574z M451.611,219.664c-4.178,48.875-17.849,78.12-34.405,102.303
		c-16.531,24.159-36.974,43.152-53.616,69.577c-2.9,4.672-5.025,9.846-6.851,15.348c-2.712,8.239-4.648,17.222-5.973,25.586
		c-1.317,8.387-2.014,15.991-2.022,21.854c0,8.356,0,20.992,0,33.588H181.944v-55.231c0-7.847-3.339-15.317-9.188-20.56
		c-5.103-4.586-11.687-7.063-18.436-7.063c-0.494,0-0.972,0.054-1.458,0.086l-22.998-1.834l-1.129,0.125l-1.967,0.11
		c-4.186,0-8.262-1.513-11.453-4.288c-3.684-3.206-5.864-7.815-6.005-12.714l-0.007-0.454l-9.767-94.221l-36.607-10.096
		l-0.439-0.086c-1.113-0.22-2.045-0.957-2.524-1.992l-0.322-1.489l0.455-1.74l47.251-83.443l0.102-13.036v-0.173
		c-0.023-1.128-0.031-2.242-0.031-3.354c0.079-47.251,18.444-84.658,48.153-111.035c29.74-26.315,71.27-41.35,117.573-41.35
		c4.413,0,8.866,0.141,13.35,0.416l0.744-12.017l-0.737,12.017c55.443,3.488,96.252,25.609,123.914,57.755
		c27.616,32.17,41.938,74.898,41.938,119.674C452.356,207.812,452.113,213.73,451.611,219.664z"
            />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'BrainSvg',
  props: {
    strokeColor: {
      type: String,
      default: '#ffffff'
    }
  }
}
</script>
