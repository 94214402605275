import {fabric} from 'fabric';
import {inject} from 'vue'
import CanvasEventEmitter from "@/components/ImageEditor/controls/event/CanvasEventEmitter";

class HistoryPlugin {
    public canvas: fabric.Canvas
    public hotkeys: string[] = ['command+z', 'command+shift+z'];
    eventEmitter: CanvasEventEmitter

    historyUndo: string[]
    historyRedo: string[]
    current: string

    historyProcessing: boolean

    constructor(canvas: fabric.Canvas) {
        this.canvas = canvas

        this.init()
    }

    setEventEmitter(em) {
        this.eventEmitter = em

        this.eventEmitter.on('freedraw_added', () => this.saveHistory())
        this.eventEmitter.on('custom_added', () => this.saveHistory())
        this.eventEmitter.on('removed', () => this.saveHistory())
        this.eventEmitter.on('modified', () => this.saveHistory())
    }

    hotkeyEvent(eventName: string, e: any) {
        if ((eventName === 'ctrl+z' || eventName === 'command+z') && e.type === 'keydown') {
            this.undo()
        } else if ((eventName === 'ctrl+shift+z' || eventName === 'command+shift+z') && e.type === 'keydown') {
            this.redo()
        }
    }

    init() {
        this.historyProcessing = true

        this.historyUndo = [];
        this.historyRedo = [];

        this.historyProcessing = false
    }

    saveAsInitialCanvas() {
        this.current = this.getCurrent()
    }

    private saveHistory() {
        if (this.historyProcessing) return

        const json = this.current
        this.historyUndo.push(json)
        this.current = this.getCurrent()
    }

    private getCurrent() {
        const {canvas} = this
        return JSON.stringify(canvas.toDatalessJSON())
    }

    undo() {
        this.historyProcessing = true

        const history = this.historyUndo.pop()
        if (history) {
            // Push the current state to the redo history
            this.historyRedo.push(this.getCurrent());
            this.current = history;
            this.loadHistory(history);
        } else {
            this.historyProcessing = false;
        }
    }

    redo() {
        this.historyProcessing = true;
        const history = this.historyRedo.pop();
        if (history) {
            // Every redo action is actually a new action to the undo history
            this.historyUndo.push(this.getCurrent());
            this.current = history;
            this.loadHistory(history);
        } else {
            this.historyProcessing = false;
        }
    }

    canUndo() {
        return this.historyUndo.length > 0
    }

    canRedo() {
        return this.historyRedo.length > 0
    }

    private loadHistory(content) {
        const {canvas} = this
        const that = this
        canvas.loadFromJSON(content, () => {
            canvas.renderAll()
            that.historyProcessing = false
        })
    }
}

export default HistoryPlugin
