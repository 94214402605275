<template>
  <div class="inline-block rounded-sm bg-white full-shadow">
    <button @click="onMenuClicked(true)"
            class="editor-bubble-button">
      <font-awesome-icon icon="pen-to-square" class="text-md p-1"/>
    </button>

    <button @click="onMenuClicked(false)"
            class="editor-bubble-button">
      <font-awesome-icon icon="eye" class="text-md p-1"/>
    </button>

  </div>
</template>

<script setup>

import { getNodeAttrsBetween } from "../editorUtil"

const emit = defineEmits(['onImageMenuClicked'])

const props = defineProps({
  editorState: null
})

function onMenuClicked(editing) {
  const state = props.editorState
  if (!state || state.selection.empty) {
    return
  }

  const attrs = getNodeAttrsBetween(state, state.selection.from, state.selection.to)
  emit('onImageMenuClicked', {imageUrl: attrs.src, editing: editing})
}

</script>
