import {fabric} from 'fabric';
import BaseHandler from "@/components/ImageEditor/controls/draw/BaseHandler";

class LineHandler extends BaseHandler {
    constructor(canvas: fabric.Canvas, drawProperty) {
        super(canvas, drawProperty)
    }

    canHandle(drawObject): boolean {
        return drawObject instanceof fabric.Line
    }

    createObject() {
        const line = new fabric.Line([
            this.startPoint.x,
            this.startPoint.y,
            this.startPoint.x,
            this.startPoint.y
        ])
        return line
    }

    updateObjectByMouse(point) {
        if (this.drawingObject) {
            this.drawingObject.set({
                x2 : point.x,
                y2: point.y
            })
            this.canvas.requestRenderAll()
        }
    }

    isDrawingObjectValid(point) {
        const xDiff = Math.abs(point.x - this.startPoint.x)
        const yDiff = Math.abs(point.y - this.startPoint.y)

        return (xDiff > this.drawLengthThreshold && yDiff > this.drawLengthThreshold)
    }
}

export default LineHandler
