<template>
  <div class="h-full caret-transparent" @click="closePopover">
    <div class="bg-white ml-auto flex justify-center items-center" style="height: 64px">
      <span class="ml-4">
        Image
      </span>
      <div v-if="editing" class="flex ml-auto space-x-2 h-10">
        <button v-for="cmd in DrawCommand"
                class="common-button-nobg text-gray-500"
                :class="isCurrentCommand(cmd) ? 'bg-sky-100 text-black' : ''"
                @click="onChangeCommandClicked(cmd)">
          {{ cmd.title }}
        </button>

          <div class="inline-block z-10">
            <button class="w-12 outline-none border px-2 m-auto py-1 bg-white rounded-sm items-center"
                    @click.stop="onStrokeWidthButtonClicked">
            <span>
              <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <line x1="0" y1="10" x2="20" y2="10" stroke="#000000"
                      :stroke-width="data.selectedLineWidthOption.show"/>
              </svg>
            </span>
            </button>
            <ul v-if="data.strokeWidthSelectVisible"
                class="right bg-white border rounded-sm transform absolute transition duration-150 ease-in-out origin-top min-w-32">
              <li v-for="lw in lineWidthOption"
                  class="w-12 cursor-pointer rounded-sm px-3 py-1 hover:bg-gray-100"
                  @click="onStrokeWidthSelected(lw)">
              <span>
                <svg-line :stroke-width="lw.show"></svg-line>
              </span>
              </li>
            </ul>
          </div>

          <div class="inline-block z-10">
            <button
                class="w-12 h-10 outline-none border px-2 m-auto py-1 bg-white rounded-sm items-center"
                @click.stop="onStrokeButtonClicked">
              <div class="w-8 h-2 border border-gray-200" :style="{background: data.drawProperty.stroke}">
              </div>
            </button>
            <ul v-if="data.strokeSelectVisible"
                class="bg-white right border rounded-sm transform absolute transition duration-150 ease-in-out origin-top">
              <li v-for="color in strokeOption"
                  class="w-10 h-4 m-1 cursor-pointer rounded-sm px-3 py-1 hover:bg-gray-100 border border-gray-300"
                  :style="{background: color}"
                  @click="onStrokeSelected(color)">
              </li>
            </ul>
          </div>

          <div class="inline-block z-10">
            <button class="w-12 h-10 outline-none border px-3 m-auto py-2 rounded-sm items-center"
                    @click.stop="onFillButtonClicked">
              <div v-if="data.drawProperty.fill === '#00000000'"
                   class="transparent-pattern w-full h-full border border-gray-100">
              </div>
              <div v-else class="w-full h-full border border-gray-100" :style="{background: data.drawProperty.fill}">
              </div>
            </button>
            <ul v-if="data.fillSelectVisible"
                class="bg-white right border rounded-sm transform absolute transition duration-150 ease-in-out origin-top">
              <li v-for="color in strokeOption"
                  class="w-10 h-4 m-1 cursor-pointer rounded-sm border border-gray-300"
                  :style="{background: color}"
                  @click="onFillSelected(color)">
              </li>
              <li class="transparent-pattern w-10 h-4 m-1 cursor-pointer rounded-sm border border-gray-300"
                  @click="onFillSelected('#00000000')">
              </li>
            </ul>
          </div>

      </div>
      <div v-else class="flex ml-auto space-x-2 h-10">
        <button v-for="cmd in ViewCommand"
                class="common-button-nobg text-gray-500"
                :class="isCurrentCommand(cmd) ? 'bg-sky-100 text-black' : ''"
                @click="onChangeCommandClicked(cmd)">
          {{ cmd.title }}
        </button>
        <button class="common-button-nobg text-gray-500"
        @click="onToggleRevealAll">
          Reveal All
        </button>
      </div>
      <div class="ml-auto">
        <button class="common-button-normal mr-4" @click="onCloseClicked">
          닫기
        </button>
        <button v-if="editing" class="common-button-action mr-4" :disabled="!state.isDirty" @click="onSaveClicked">
          저장
        </button>
      </div>
    </div>
    <div style="display: flex; height: calc(100vh - 64px); padding: 24px">
      <div id="canvas-workspace" class="relative w-full bg-gray-200 flex justify-center items-center overflow-hidden">
        <div class="inside-shadow"></div>
        <canvas id="canvas"></canvas>
      </div>
      <!--      <div class="border-[1px] border-gray-200"-->
      <!--           style="width: 530px; height: 100%; padding: 10px; overflow-y: auto; background: white">-->
      <!--        Properties-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script setup name="ImageSpace">

import {fabric} from 'fabric';
import {reactive, onMounted, provide, onUnmounted, toRefs, computed} from "vue";
import {useStore} from "vuex";
import CanvasComposer from "@/components/ImageEditor/controls/core";
import WorkspacePlugin from "./controls/plugin/WorkspacePlugin";
import DrawPlugin from "./controls/plugin/DrawPlugin";
import CopyPlugin from "@/components/ImageEditor/controls/plugin/CopyPlugin";
import DeletePlugin from "@/components/ImageEditor/controls/plugin/DeletePlugin";
import HistoryPlugin from "@/components/ImageEditor/controls/plugin/HistoryPlugin";
import {lazyExec} from "@/utils/util";
// import ImageLogic from "@/components/logic/ImageLogic";
import {DrawCommand, ViewCommand} from "@/components/ImageEditor/controls/draw/DrawCommand";
import svgLine from "@/assets/svg/svgLine.vue";
import CanvasEventEmitter from "@/components/ImageEditor/controls/event/CanvasEventEmitter"

const store = useStore();

let canvas = null
let canvasComposer = null
let eventEmitter = null
let workspacePlugin = null
let drawPlugin = null
let historyPlugin = null
// let imageLogic = null

const props = defineProps({
  imageSource: {
    Type: String,
    default: ""
  },
  editing: {
    Type: Boolean,
    default: false
  }
})

const {imageSource, editing} = toRefs(props);
const state = reactive({
  isDirty: false,
  command: DrawCommand[1]
})


const lineWidthOption = [
  {show: '1', value: 1},
  {show: '1.5', value: 2},
  {show: '2', value: 4},
  {show: '4', value: 8},
  {show: '8', value: 12},
  {show: '12', value: 16}
]
const strokeOption = ['#ff0000', '#ffa500', '#ffff00', '#00ff00', '#0000ff', '#ff00ff', '#ffffff', '#000000']

const data = reactive({
  strokeWidthSelectVisible: false,
  strokeSelectVisible: false,
  fillSelectVisible: false,
  selectedLineWidthOption: lineWidthOption[0],
  drawProperty: {
    strokeWidth: 2,
    stroke: '#000000',
    fill: '#000000'
  },
  originalImageBoundingRect: null
})

const emits = defineEmits(['closed', 'saved'])

onMounted(() => {

  if (imageSource.value && imageSource.value.length > 0) {

    fabric.Object.NUM_FRACTION_DIGITS = 8
    canvas = new fabric.Canvas('canvas', {
      isDrawingMode: false,
      enableRetinaScaling: true,
      stopContextMenu: true, // right click 시에 브라우저 기본 메뉴 표시 방지
      uniformScaling: false, // 오브젝트 사이즈 변경시 비율 유지 안함
      targetFindTolerance: 10,
      selection: editing.value,
      // stateful: true, // should fire object:modified when object property changed. but not working
      // freeDrawingCursor: ''
      // hoverCursor: ''
      // moveCursor: ''
      // fireRightClick: true,
      // stopContextMenu: true,
      // controlsAboveOverlay: true,
    });

    state.command = editing.value ? DrawCommand[0] : ViewCommand[0]
    initPlugins()
    lazyExec(loadData, 50)
  }
})

function onObjectSelected() {
  const activeObject = canvas.getActiveObject();
  if (!activeObject) return

  // ConcealRect 의 속성은 반영하지 않는다.
  if (activeObject.name === 'ConcealRect') {
    return
  }

  const strokeWidth = activeObject.get('strokeWidth')
  const stroke = activeObject.get('stroke')
  const fill = activeObject.get('fill')
  data.drawProperty.strokeWidth = strokeWidth
  data.drawProperty.stroke = stroke
  data.drawProperty.fill = fill

  // update line option ui
  const lwOption = lineWidthOption.find(opt => opt.value === strokeWidth)
  if (lwOption) {
    data.selectedLineWidthOption = lwOption
  }
}

onUnmounted(() => {
  if (canvas) {
    workspacePlugin.dispose()
    canvas.clear()
    canvas.dispose()
    canvasComposer = null
    eventEmitter = null
    workspacePlugin = null
    drawPlugin = null
    historyPlugin = null
    canvas = null
  }
})

function onCloseClicked() {
  emits('closed')
}

function onSaveClicked() {
  const {blobUrl, jsonString} = workspacePlugin.save(true)
  if (blobUrl) {
    store.dispatch('images/saveEditingImage', {
      imageUrl: blobUrl,
      imageJson: jsonString
    })
    emits('saved', blobUrl)
  }
}

function loadData() {
  const savedEditingImageJson = store.getters['images/getSavedImage'](imageSource.value)
  if (savedEditingImageJson) {
    workspacePlugin.loadFromJson(savedEditingImageJson, false, true, afterLoaded)
  } else {
    workspacePlugin.addBackgroundImageWithCanvasFitToImage({
      url: imageSource.value,
      widthFit: false
    }, afterLoaded)
  }
}

function afterLoaded() {
  if (historyPlugin) {
    historyPlugin.saveAsInitialCanvas()
  }

  drawPlugin.setCommand(state.command)
}

function initPlugins() {
  eventEmitter = new CanvasEventEmitter()
  eventEmitter.init(canvas)

  canvasComposer = new CanvasComposer(canvas)
  canvasComposer.addPlugin(CopyPlugin)
  canvasComposer.addPlugin(DeletePlugin)
  historyPlugin = canvasComposer.addPlugin(HistoryPlugin)
  historyPlugin.setEventEmitter(eventEmitter)

  workspacePlugin = canvasComposer.addPlugin(WorkspacePlugin, {containerId: 'canvas-workspace'})
  drawPlugin = canvasComposer.addPlugin(
      DrawPlugin, { drawProperty:data.drawProperty, handlers: ['ALL'] }
  )

  canvas.on('object:added', onObjectAdded)
  canvas.on('object:removed', onObjectRemoved)

  eventEmitter.on('selectOne', onObjectSelected);
}

function onObjectAdded() {
  onObjectCountChanged()
}

function onObjectRemoved() {
  onObjectCountChanged()
}

function onObjectCountChanged() {
  const allObjects = canvas.getObjects()
  if (allObjects && allObjects.length > 0) {
    state.isDirty = true
  } else {
    state.isDirty = false
  }
}

function onChangeCommandClicked(cmd) {
  state.command = cmd
  drawPlugin.setCommand(cmd)
}

function isCurrentCommand(cmd) {
  return state.command.type === cmd.type
}

function onStrokeWidthButtonClicked() {
  data.strokeWidthSelectVisible = !data.strokeWidthSelectVisible
}

function onStrokeWidthSelected(lineOption) {
  data.strokeWidthSelectVisible = false
  data.selectedLineWidthOption = lineOption
  data.drawProperty.strokeWidth = lineOption.value
  drawPlugin.updateDrawProperty({strokeWidth: data.drawProperty.strokeWidth})
}

function onStrokeButtonClicked() {
  data.strokeSelectVisible = !data.strokeSelectVisible
}

function onStrokeSelected(color) {
  data.strokeSelectVisible = false
  data.drawProperty.stroke = color
  drawPlugin.updateDrawProperty({stroke: data.drawProperty.stroke})
}

function onFillButtonClicked() {
  data.fillSelectVisible = !data.fillSelectVisible
}

function onFillSelected(color) {
  data.fillSelectVisible = false
  data.drawProperty.fill = color
  drawPlugin.updateDrawProperty({fill: data.drawProperty.fill})
}

function closePopover(e) {
  if (e) {
    data.strokeWidthSelectVisible = false
    data.strokeSelectVisible = false
    data.fillSelectVisible = false
  }
}

function onToggleRevealAll() {
  drawPlugin.toggleRevealAll()
}

</script>

<style lang="less" scoped>

.canvas-box {
  position: relative;
}

#workspace {
  overflow: hidden;
}

#canvas {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  background: transparent;
}

.inside-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 9px 2px #0000001f;
  z-index: 2;
  pointer-events: none;
}

.button {
  caret-color: transparent;
}

.transparent-pattern {
  background-color: #000000;
  opacity: 0.6;
  background-image: repeating-linear-gradient(45deg, #555555 25%, transparent 25%, transparent 75%, #555555 75%, #555555), repeating-linear-gradient(45deg, #555555 25%, #cccccc 25%, #cccccc 75%, #555555 75%, #555555);
  background-position: 0 0, 5px 5px;
  background-size: 10px 10px;
}

</style>
