<template>
  <div class="inline-block rounded-sm bg-white full-shadow">
    <button @click="unsetConceal">
      <font-awesome-icon icon="fa-eye" class="text-md pl-2 p-1 text-black"/>
    </button>

    <button @click="setConcealColor('#000000')"
            class="editor-bubble-button">
      <font-awesome-icon icon="fa-regular fa-square-full" class="text-md p-1 text-black" />
    </button>

    <button @click="setConcealColor('#ff0000')"
            class="editor-bubble-button">
      <font-awesome-icon icon="fa-regular fa-square-full" class="text-md p-1 text-red-700"/>
    </button>

  </div>
</template>

<script setup>

const emit = defineEmits(['onChangeConcealColor', 'onUnsetConceal'])

function setConcealColor(color) {
  emit("onChangeConcealColor", color)
}

function unsetConceal() {
  emit("onUnsetConceal")
}

</script>
