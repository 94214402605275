export const lazyExec = (func, ms) => {
    setTimeout(() => { func() }, ms)
}

export function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export const range = (start, end) => {
    const rangeArray = new Array()
    rangeArray.push(start)
    rangeArray.push(end)
    return rangeArray
}

// import isMobile from "ismobilejs";
//
// export const isMobileOs = () => {
//     return isMobile(window.navigator).any
// }

export const getMouseEventName = (eventName) => {
    return eventName
    // if (!isMobileOs()) {
    //     return eventName
    // }
    //
    // switch (eventName) {
    //     case 'mousemove':
    //         return 'touchmove'
    //     case 'mouseup':
    //         return 'touchend'
    //     case 'mouseleave':
    //         return 'touchend'
    // }
}
