import {fabric} from 'fabric';
import {throttle} from 'lodash-es';
import FreeDrawHandler from "@/components/ImageEditor/controls/draw/FreeDrawHandler";

class ScribbleOnHtmlEditorHandler extends FreeDrawHandler {

    tiptapEditorEl: any
    tiptapEditor: any
    pencilCursor: any
    pointerCursor = 'pointer'
    resizeObserver: any
    // HtmlEditor <conceal> cache
    concealTextArray: Array<any>
    // <data-type=fabric> 인경우 FabricComponent에 의해서 fabric canvas로 그려진다. 그 위 ConcealRect cache
    // ScribbleOnHtmlEditorHandler 는 HtmlEditor마다 하나씩 만들어지는데
    // HtmlEditor 에 여러 FabricComponent가 있을 수 있다.
    // 마우스 동작시 먼저 어느 FabricComponent 인지 확인해야 한다.
    concealRectMap: Map<any, any> // <canvas element, concealRects>

    constructor(canvas: fabric.Canvas, drawProperty) {
        super(canvas, drawProperty)

        // 다른 DrawHandler는 DrawPlugin 에서 subscribe 하는데
        // ScribbleOnHtmlEditorHandler 는 DrawPlugin 없이 단독으로 사용되고 있어서
        // 여기서 scribe 하고 있다. 개선 필요

        this.canvas.on('mouse:down:before', (ie) => this.mouseDownBefore(ie))

        this.canvas.on('mouse:down', (ie) => this.mouseDown(ie))

        this.canvas.on('mouse:up:before', (ie) => this.mouseUpBefore(ie))
        this.canvas.on('mouse:up', (ie) => this.mouseUp(ie))

        this.canvas.on('mouse:move:before', throttle((ie) => {
            this.mouseMoveBefore(ie)
        }, 50))

        const pencil =
            `data:image/svg+xml;base64,${window.btoa('<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">' +
                '<path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/>' +
                '</svg>')}`

        this.pencilCursor = `url(${pencil}) 1 15, crosshair`

        this.concealRectMap = new Map() // <CanvasRect, ConcealRectArray>
    }

    setTiptapEditor(tiptapEditorEl, tiptapEditor) {
        this.tiptapEditorEl = tiptapEditorEl
        this.tiptapEditor = tiptapEditor
        // ScribbleOnHtmlEditorHandler 는 HtmlEditor 전체를 대상으로 Scribble
        // HtmlEditor 안에 fabric canvas가 node 로 포함될 수 있다.
        // 그 node의 fabric canvas 에 concealRect가 포함된 json 파일이 로드된 경우 호출됨
        // HtmlEditor에 fabric node 가 여러개 있으면 여러번 호출되겠지
        this.tiptapEditor.on('onFabricNodeCanvasLoaded', (data) => this.cacheConcealRect(data))
        this.tiptapEditor.on('onFabricNodeCanvasScaleFinished', (data) => this.cacheConcealRect(data))
        this.tiptapEditor.on('onRemoveConcealText', (concealInfo) => this.removeConcealText(concealInfo))
    }

    prepare() {
        this.canvas.freeDrawingCursor = this.pencilCursor
        super.prepare();
        this.cacheConcealText()
    }

    dispose() {
        // cache clear
        this.clearConcealText()
    }

    private getConcealRectsFromCanvas(canvas) {
        return canvas.getObjects().filter(obj => obj.name === 'ConcealRect')
    }

    mouseDownBefore(ie) {
        const {canvas} = this

        const concealInfo = this.getConcealPositionOfCanvasPoint(canvas.getPointer(ie.e))
        if (concealInfo) {
            canvas.isDrawingMode = false

            const {isNode, isRect} = concealInfo
            if (isNode) {
                const {text, rect} = concealInfo

                const $pos = this.tiptapEditor.view.state.doc.resolve(text.pos)
                const from = text.pos - $pos.textOffset
                const to = from + text.size

                this.tiptapEditor.emit('onConcealMarkClick', {
                    from,
                    to,
                    text: text.text,
                    attrs: text.attrs,
                    rect: rect
                })
            } else if (isRect) {
                // FabricNode Clicked
                const {concealRect} = concealInfo
                this.tiptapEditor.emit('onConcealRectClick', {concealRect})
            }
            // const {text, pos} = this.getConcealNodeAtDocPos(concealPos)
        } else {
            canvas.isDrawingMode = true
        }
    }

    mouseDown(ie) {
        const {canvas} = this
        canvas.isDrawingMode = true
    }

    mouseUpBefore(ie) {
        const {canvas} = this
        if (canvas.isDrawingMode) {
            const freeDrawObjects = canvas.getObjects()
            if (freeDrawObjects && freeDrawObjects.length > 0) {
                const addObject = freeDrawObjects[freeDrawObjects.length -1]
                setTimeout(() => {
                    canvas.remove(addObject)
                }, 1200)
            }
        } else {
            canvas.isDrawingMode = true
        }
    }

    mouseUp(ie) {
        const {canvas} = this
        if (canvas.isDrawingMode) {
            const freeDrawObjects = canvas.getObjects()
            if (freeDrawObjects && freeDrawObjects.length > 0) {
                const addObject = freeDrawObjects[freeDrawObjects.length - 1]
                setTimeout(() => {
                    canvas.remove(addObject)
                }, 1200)
            }
        } else {
            canvas.isDrawingMode = true
        }
    }

    mouseMoveBefore(ie) {
        const {canvas} = this
        if (canvas.isDrawingMode) {
            const point = canvas.getPointer(ie.e)
            const found = this.getConcealPositionOfCanvasPoint(point)
            if (found) {
                canvas.freeDrawingCursor = this.pointerCursor
            } else {
                canvas.freeDrawingCursor = this.pencilCursor
            }
        }
    }

    getConcealPositionOfCanvasPoint(pointInScribbleCanvas) {
        // pointInScribbleCanvas : Scribble 대상이 되는 Canvas 에서의 좌표
        // Scribble Canvas 는 Html Editor 크기와 동일하다

        // pointInScribbleCanvas 를 브라우저 화면에서의 위치로 변환
        // Scribble Canvas 와 HtmlEditor 가 위치,크기가 동일하므로
        // HtmlEditor 가 브라우저 컨텐츠 화면에서 차지하는 위치를 더해준다
        const editorRect = this.tiptapEditorEl.getBoundingClientRect();
        const screenX = editorRect.left + pointInScribbleCanvas.x;
        const screenY = editorRect.top + pointInScribbleCanvas.y

        const screenPos = {x: screenX, y: screenY}
        const concealText = this.getCachedConcealText(screenPos)
        if (concealText) {
            return {
                isNode: true,
                text: concealText,
                rect: concealText.rect,
                dom: concealText.dom
            }
        }

        const concealRect = this.getCachedConcealRect(screenPos)
        if (concealRect) {
            // const posAt = this.tiptapEditor.view.posAtCoords({left: screenX, top: screenY});
            // const {pos} = posAt
            return {isRect: true, concealRect}
        }

        return null
    }

    getCachedConcealText(screenPos) {
        // html editor <conceal> 의 경우는 scroll 을 고려해야한다

        const noteView = document.getElementById('NoteView')

        const scrollAddedPoint = {
            x: screenPos.x + noteView.scrollLeft,
            y: screenPos.y + noteView.scrollTop
        }

        return this.concealTextArray.find(node => {
            return this.isPointInRect(scrollAddedPoint, node.rect)
        })
    }

    getCachedConcealRect(screenPos) {
        // canvas 를 먼저 찾고
        // canvas 안에서 concealRect 를 찾는다
        for (const [canvasEl, concealRects] of this.concealRectMap) {
            const canvasClientRect = canvasEl.getBoundingClientRect()
            if (this.isPointInRect(screenPos, canvasClientRect)) {
                const pointInCanvas = {
                    x: screenPos.x - canvasClientRect.left,
                    y: screenPos.y - canvasClientRect.top
                }

                return concealRects.find(concealRect => {
                    return this.isPointInRect(pointInCanvas, concealRect.rect)
                })
            }
        }

        return null
    }

    private isPointInRect(point, rect) {
        return (
            rect.left <= point.x && point.x <= (rect.right)
            && rect.top <= point.y && point.y <= (rect.bottom)
        )
    }

    clearConcealText() {
        this.concealTextArray = []
    }

    getAllConcealDom(parentDom, doms) {
        for (let childDom of parentDom.childNodes) {
            if (childDom.nodeName === 'CONCEAL') {
                doms.push(childDom)
            } else {
                this.getAllConcealDom(childDom, doms)
            }
        }
    }

    cacheConcealText() {
        this.concealTextArray = []
        const editorView = this.tiptapEditor.view
        if (editorView === null || editorView === undefined) {
            return
        }

        const state = editorView.state

        const parentDom = editorView.domAtPos(0).node
        const allConcealDoms = []
        this.getAllConcealDom(parentDom, allConcealDoms)

        const noteView = document.getElementById('NoteView')

        const editorRect = this.tiptapEditorEl.getBoundingClientRect()

        let concealDomIndex = 0
        state.doc.descendants((node, pos, parent, index) => {
            const concealMark = node.marks.find(mark => mark.type.name === 'conceal')
            if (concealMark) {
                const concealDom = allConcealDoms[concealDomIndex++]
                const clientRect = concealDom.getBoundingClientRect()
                const curRect = {
                    left : clientRect.left + noteView.scrollLeft,
                    top : clientRect.top + noteView.scrollTop,
                    right: clientRect.right + noteView.scrollLeft,
                    bottom: clientRect.bottom + noteView.scrollTop,
                    width: clientRect.right - clientRect.left,
                    height: clientRect.bottom - clientRect.top,
                    xDistFromEditorTop: clientRect.left - editorRect.left,
                    yDistFromEditorTop: clientRect.top - editorRect.top
                }
                const concealNode = {
                    pos: pos,
                    size: node.nodeSize,
                    text: node.text,
                    attrs: concealMark.attrs,
                    rect: curRect
                }

                this.concealTextArray.push(concealNode)

                // console.log(this.concealTextArray)
                // concealMark 는 특정 <conceal> 가리키지만
                // concealDomNode 는 <p> 에 해당하는 한줄이 나오고 그 안에 여러 conceal 이 있을 수 있다
                // 여러 conceal 중에 concealMark 에 해당하는 값만 저장한다.
                // for (let childDom of concealDomNode.childNodes) {
                //     if ((childDom.nodeName === 'CONCEAL') && (childDom.innerText === node.text)) {
                //         // <p> 안에 같은 text 의 conceal 이 여럿 있을 수 있다
                //         // 앞에서부터 비교되므로 한번 add 한 rect 는 무시한다.
                //         const curRect = childDom.getBoundingClientRect()
                //         const existing = this.concealTextArray.find(ct => {
                //             return (ct.rect.left === curRect.left && ct.rect.top == curRect.top)
                //         })
                //
                //         if (!existing) {
                //             const concealNode = {
                //                 pos: pos,
                //                 size: node.nodeSize,
                //                 text: node.text,
                //                 attrs: concealMark.attrs,
                //                 rect: curRect
                //             }
                //
                //             this.concealTextArray.push(concealNode)
                //         }
                //     }
                // }
            }
            // */
        })

        // console.log(this.concealTextArray)
    }

    removeConcealText(concealInfo) {
        const index = this.concealTextArray.findIndex(c => c.pos === concealInfo.from)
        if (index > -1) {
            this.concealTextArray.splice(index, 1)
        }
    }

    cacheConcealRect(data) {
        // 여기서 Canvas는 ScribbleOnHtmlEditorHandler 의 canvas 가 아니다
        // HtmlEditor 에 삽입된 FabricNode 에 생성되어 있는 Canvas 이다.
        const {canvasEl, canvas} = data

        const canvasElRect = canvasEl.getBoundingClientRect()
        const concealRectsOnCanvas = this.getConcealRectsFromCanvas(canvas)
        if (concealRectsOnCanvas && concealRectsOnCanvas.length > 0) {
            const scale = canvas.getZoom() // fabric zoom 을 이용해서 scale. scale 되어 있으면 객체좌표들도 보정해준다
            if (scale === 1) {
                this.concealRectMap.set(canvasEl, concealRectsOnCanvas.map(cr => {
                    return {
                        obj: cr,
                        rect: cr.rect,
                        canvasElRect: canvasElRect
                    }
                }))
            } else {
                const scaledRects = concealRectsOnCanvas.map(cr => {
                    return {
                        obj: cr,
                        rect: {
                            left: cr.left * scale,
                            top: cr.top * scale,
                            width: cr.width * scale,
                            height: cr.height * scale
                        },
                        canvasElRect: canvasElRect
                    }
                })
                this.concealRectMap.set(canvasEl, scaledRects)
            }
        }
    }
}

export default ScribbleOnHtmlEditorHandler

