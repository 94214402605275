<template>
  <div data-drag-handle
       class="drag-trigger">
  </div>
  <div
      ref="resizeRef"
      class="resize-trigger resize-default-icon"
      @mousedown="handlerResize"
      @touchstart="handlerResize"
  >

  </div>
</template>

<script setup>
import {inject, ref, computed, watch, onMounted, reactive} from 'vue';
import {getMouseEventName} from "@/utils/util";

const tiptapProps = inject('tiptapProps')
const props = defineProps({
  fabricRef: {type: Object, required: true},
});

const emit = defineEmits(['resizeFinished'])

const resizeRef = ref(null);
const originX = ref(0);
const originW = ref(0);
let lastMouseMoveTick = 0

const handlerResize = (e) => {
  removeEventListener();

  const rect = e.target.getBoundingClientRect();
  originX.value = rect.x;
  originW.value = props.fabricRef.clientWidth;
  document.body.addEventListener(getMouseEventName('mousemove'), onMouseMoveThrottle);
  document.body.addEventListener(getMouseEventName('mouseup'), onMouseUp, {once: true});
  document.body.addEventListener(getMouseEventName('mouseleave'), onMouseLeave, {once: true});
};

function onMouseMoveThrottle(e) {
  const curTick = Date.now()
  if (curTick - lastMouseMoveTick >= 50) {
    onMouseMove(e)
    lastMouseMoveTick = curTick
  }
}

function onMouseMove(e) {
  const maxW = resizeRef.value.parentElement.parentElement.clientWidth;
  const newW = originW.value - (originX.value - e.pageX);
  const width = newW > maxW ? maxW : newW
  tiptapProps.updateAttributes({width: `${width}px`});
}

function onMouseUp(e) {
  emit('resizeFinished')
  removeEventListener()
}

function onMouseLeave(e) {
  emit('resizeFinished')
  removeEventListener()
}

function removeEventListener() {
  document.body.removeEventListener(getMouseEventName('mousemove'), onMouseMoveThrottle);
  document.body.removeEventListener(getMouseEventName('mouseup'), removeEventListener);
  document.body.removeEventListener(getMouseEventName('mouseleave'), removeEventListener);
}

// watch(() => tiptapProps.selected, removeEventListener)
</script>

<style lang="scss">

.resize-trigger {
  display: none;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: nwse-resize;
}

.resize-default-icon {
  height: 12px;
  width: 12px;
  margin: 0 -4px -4px 0;
  background: #41464b;
  border: 2px solid #fff;
  box-sizing: content-box;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 30%);
}

.drag-trigger {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #00000000;
  border: solid 3px #2563eb;
  cursor: move;
}

.ProseMirror-selectednode {
  z-index: 1;

  .resize-trigger {
    display: block;
  }

  .drag-trigger {
    display: block;
  }
}
</style>
