import {Table} from '@tiptap/extension-table'
import {TableNodeViewEx} from './TableNodeViewEx'
import {columnResizing, tableEditing} from 'prosemirror-tables'
import {columnResizingEx} from "@/components/HtmlEditor/extensions/table/columnResizingEx";
import {VueNodeViewRenderer} from "@tiptap/vue-3";
import {tableMenuPlugin} from './tableMenuPlugin'
import TableMenuComponent from './TableMenuComponent.vue'
import {mergeAttributes} from "@tiptap/core";
import { Editor as VueEditor } from "@tiptap/vue-3";

export const TableEx = Table.extend({
    draggable: true,

    addProseMirrorPlugins() {
        const isResizable = this.options.resizable && this.editor.isEditable

        return [
            ...(isResizable
                ? [
                    // columnResizingEx 안 써도 동작 동일함
                    columnResizingEx({
                        handleWidth: this.options.handleWidth,
                        cellMinWidth: this.options.cellMinWidth,
                        // TODO: PR for @types/plugin-tables
                        // @ts-ignore (incorrect type)
                        lastColumnResizable: this.options.lastColumnResizable,
                    }),
                ]
                : []),
            tableEditing({
                allowTableNodeSelection: this.options.allowTableNodeSelection,
            }),
            tableMenuPlugin(this.editor)
        ]
    },

    // <table> 하나에 대해서 addNodeView가 두번씩 호출된다.
    addNodeView() {
        return (params) => {
            if (!(params.editor as VueEditor).contentComponent) {
                return {}
            }

            // if (this.tableNodeView && params.getPos() === this.tableNodePos) {
            //     console.log('reuse')
            //     return this.tableNodeView
            // }

            // const {editor, node, getPos} = params
            // console.log('table node created')

            return new TableNodeViewEx(params, this.options.cellMinWidth, TableMenuComponent)
        }
    },
})
