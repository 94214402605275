import axios from "axios";

export default {
    async getNoteList(notebookId) {
        console.log('call api : notelist')
        try {
            const response = await axios.get(`/api/v1/notebooks/notebook/notelist/${notebookId}`)
            if (response.status === 200 && response.data) {
                return response.data.result
            }
        } catch (e) {
            console.log(e);
        }

        return null
    },

    async getNoteWithPreviewList(notebookId) {
        console.log('call api : notelist')
        try {
            const response = await axios.get(`/api/v1/notebooks/notebook/notelist_w_preview/${notebookId}`)
            if (response.status === 200 && response.data) {
                return response.data.result
            }
        } catch (e) {
            console.log(e);
        }

        return null
    },

    async create(data) {
        try {
            const response = await axios.post('/api/v1/notebooks', data)
            if (response.status === 200) {
                return response.data.result
            }
        } catch (e) {
            console.log(e)
        }

        return null
    },

    async update(data) {
        try {
            const response = await axios.put('/api/v1/notebooks', data)
            if (response.status === 200) {
                return response.data.result
            }
        } catch (e) {
            console.log(e)
        }

        return null
    },

    async delete(id) {
        try {
            const response = await axios.delete('/api/v1/notebooks/' + id)
            if (response.status === 200) {
                return response.data.result
            }
        } catch (e) {
            console.log(e)
        }

        return null
    }
}
