import { mergeAttributes, Node, VueNodeViewRenderer } from '@tiptap/vue-3'

import FabricComponent from "./FabricComponent.vue";

export interface FabricNodeOptions {
    HTMLAttributes: Record<string, any>
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        fabricnode: {
            setFabricNode: (options: { image: string, width?: string }) => ReturnType,
        }
    }
}

export default Node.create<FabricNodeOptions>({
    name: 'gncanvas',

    draggable: true,

    group: 'block',

    atom: true,

    addOptions() {
        return {
            HTMLAttributes: {},
        }
    },

    addAttributes() {
        return {
            src: {
                default: '',
            },
            width: {
                default: 'auto'
            },
            height: {
                default: 'auto'
            }
        }
    },

    parseHTML() {
        return [
            {
                tag: 'gncanvas',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        // return ['div', mergeAttributes(HTMLAttributes, { 'data-type': 'gncanvas' })]
        return ['gncanvas', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
    },

    addCommands() {
        return {
            setFabricNode: options => ({ commands }) => {
                return commands.insertContent({
                    type: this.name,
                    attrs: options,
                })
            },
        }
    },

    addNodeView() {
        return VueNodeViewRenderer(FabricComponent)
    }
})
