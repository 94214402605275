<template>
  <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" role="img">
    <g>
      <path fill="none" d="M0 0H24V24H0z"/>
      <path d="M12 3c.552 0 1 .448 1 1v8c.835-.628 1.874-1 3-1 2.761 0 5 2.239 5 5s-2.239 5-5 5c-1.032 0-1.99-.313-2.787-.848L13 20c0 .552-.448 1-1 1H6c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h6zm-1 2H7v14h4V5zm8 10h-6v2h6v-2z"
            style="stroke: transparent; fill: white;"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DeleteColumnSvg',
}
</script>
