
import { EditorState, Plugin, PluginKey, Transaction } from 'prosemirror-state';
import { TableMap, cellAround, CellAttrs, pointsAtCell } from 'prosemirror-tables';
import { domCellAround } from "./util";
import { Editor } from "@tiptap/core"
import {
    Decoration,
    DecorationSet,
    EditorView,
    NodeView,
} from 'prosemirror-view';
import {columnResizingPluginKey} from "@/components/HtmlEditor/extensions/table/columnResizingEx";
import table from "@/components/HtmlEditor/extensions/table/index";

export const tableMenuPluginKey = new PluginKey(
    'tableMenu'
)

export class TableState {
    constructor(public activeCell: number) {}

    apply(tr: Transaction): TableState {
        const state = this
        const action = tr.getMeta(tableMenuPluginKey)
        if (action && action.setCell != null) {
            return new TableState(action.setCell)
        }
        if (state.activeCell > -1 && tr.docChanged) {
            let handle = tr.mapping.map(state.activeCell, -1)
            if (!pointsAtCell(tr.doc.resolve(handle))) {
                handle = -1;
            }
            return new TableState(handle)
        }
        return state
    }
}

export function tableMenuPlugin(editor: Editor): Plugin {
    const plugin = new Plugin<TableState>({
        key: tableMenuPluginKey,
        state: {
          init(_, state) {
              return new TableState(-1)
          },
          apply(tr, prev) {
              return prev.apply(tr)
          }
        },
        props: {
            attributes: (state): Record<string, string> => {
                const pluginState = tableMenuPluginKey.getState(state);
                return pluginState && pluginState.activeCell > -1
                    ? { class: 'cell-selected' }
                    : {};
            },

            handleDOMEvents: {
                mousedown: (view, event) => {
                    handleMouseDown(editor, view, event)
                },
                mouseleave: (view) => {
                    handleMouseLeave(editor, view);
                },
                // dragstart: (view, event) => {
                //     conosole.log('drgs')
                //     console.log(event)
                //     console.log('drgs---')
                // }
            },

            // table Node 의 경우 prosemirror 에서 drop 을 처리하면
            // 원래 테이블이 그자리에 남아 있어서 drag copy 동작이 된다
            // 여깃 true 로 리턴하면 prosemirror 의 drop 동작을 막는다
            // 실제 동작은 TableNodeViewEx 에서 dragend event lister 에서 수행
            handleDrop(view, event, slice, moved) {
                if (slice && slice.content && slice.content.content[0] && slice.content.content[0].type.name === 'table') {
                    return true
                }
                return false
            }
            //     console.log(view)
            //     console.log(event)
            //     console.log(slice)
            //     console.log(moved)
            //     // const node = moved.content.content[0]
            //     if (moved) {
            //         const tr = editor.state.tr
            //         const {from, to} = editor.state.selection
            //         const end = from + slice.content.size
            //         console.log(from + ' ' + end)
            //         tr.delete(from, end)
            //         editor.view.dispatch(tr)
            //     }
            //     return true
            // }
        },
    })

    return plugin
}

function handleMouseDown(
    editor: Editor,
    view: EditorView,
    event: MouseEvent
) {
    const pluginState = tableMenuPluginKey.getState(view.state);

    if (!pluginState) {
        return
    }

    let cell = -1;
    const target = domCellAround(event.target as HTMLElement);
    if (target) {
        cell = cellAt(view, event)
        updateTable(editor, view, cell)
    } else if (event.target) {
        const tableMenuButtonClicked = event.target.classList.contains('table-drag-handle') || event.target.classList.contains('table-menu-button')
        if(tableMenuButtonClicked === false) {
            updateTable(editor, view, -1)
        }
    }
}

function cellAt(view: EditorView, event: MouseEvent) {
    const found = view.posAtCoords({
        left: event.clientX,
        top: event.clientY,
    });
    if (!found) return -1;
    const { pos } = found;
    const $cell = cellAround(view.state.doc.resolve(pos));
    if (!$cell) return -1;
    // const map = TableMap.get($cell.node(-1)), start = $cell.start(-1);
    return $cell.pos
    // const index = map.map.indexOf($cell.pos - start);
    // return index
}

function handleMouseLeave(editor: Editor, view: EditorView): void {
    const pluginState = tableMenuPluginKey.getState(view.state);
    if (pluginState && pluginState.activeCell > -1) {
        // editor bubble menu 가 뜨면서 mouse leave 이벤트가 발생한다. 메뉴 사라지지 않도록 주석처리
        // updateTable(editor, view, -1);
    }
}

function updateTable(editor: Editor, view: EditorView, cell: number): void {
    const pluginState = tableMenuPluginKey.getState(view.state);
    if (cell != pluginState.activeCell) {
        view.dispatch(
            view.state.tr.setMeta(tableMenuPluginKey, { setCell: cell }),
        )

        displayTableMenu(editor, view)
    }
}

function displayTableMenu(editor: Editor, view: EditorView) {
    const pluginState = tableMenuPluginKey.getState(view.state);

    const cell = pluginState.activeCell

    let tableWrapperDom: HTMLElement | null
    let tableWrapperDomId: string = ""
    let tableDom: DomNode | null

    if (cell > -1) {
        const $cell = view.state.doc.resolve(cell);
        tableDom = view.domAtPos($cell.start(-1)).node;
        while (tableDom && tableDom.nodeName != 'TABLE') {
            tableDom = tableDom.parentNode;
        }

        tableWrapperDom = tableDom?.parentNode

        if (tableWrapperDom) {
            tableWrapperDomId = tableWrapperDom.id
            const cellDom = view.domAtPos($cell.start(3)).node
            editor.emit('tableSelected', {editor: editor, wrapperDomId: tableWrapperDomId, $cellPos: $cell, cellDom: cellDom})
        }
    } else {
        // outside table
        editor.emit('noTableSelected', {editor: editor})
    }

    // editor.emit('tableSelectionUpdated', {twdid: tableWrapperDomId, cell: cell})
}
