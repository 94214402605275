<template>
  <svg :fill="strokeColor" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink"
       width="90%" height="90%" viewBox="0 0 910.5 910.5" xml:space="preserve"
  >
<g>
	<path
      d="M216.813,228.5h73.5c13.8,0,25-11.2,25-25s-11.2-25-25-25h-73.5c-13.8,0-25,11.2-25,25S203.013,228.5,216.813,228.5z"/>
  <path d="M420.313,98.5V25c0-13.8-11.2-25-25-25s-25,11.2-25,25v73.5c0,13.8,11.2,25,25,25S420.313,112.3,420.313,98.5z"/>
  <path d="M573.813,228.5c13.8,0,25-11.2,25-25s-11.2-25-25-25h-73.5c-13.8,0-25,11.2-25,25s11.2,25,25,25H573.813z"/>
  <path d="M705.213,755.4c12.1-20.301,18.5-43.5,18.5-67.2V528.9c0-19.5-12.5-36.801-31.101-42.801
		c-55.5-18.1-167.5-54.199-184.6-57.6c-19.7-3.9-38.101-0.2-47.9,8.4h-0.1c0,0,0,0,0-0.101c-0.5-4.5-25.9-250.5-25.9-250.7
		c-2.399-21.3-20.399-31.3-38.2-30.7c-19.1,0.7-37,16.2-36.899,36.1l-0.2,404.3c0,4.8-3.5,8.8-8.3,9.5c-0.101,0-0.101,0-0.2,0
		c-0.5,0.101-1,0.101-1.5,0.101c-13.6-0.4-44.3-0.801-74.2-44.101c-4-5.7-7.6-10.899-10.899-15.7c-28.5-40.899-83.9-18.3-76.2,28.2
		c7.2,43.3,54.6,144.2,148,238.8h335.8L705.213,755.4z"/>
  <path d="M335.713,890.5c0,11,9,20,20,20h295.6c11,0,20-9,20-20v-45.8h-335.6V890.5z"/>
</g>
  </svg>
</template>

<script>
export default {
  name: 'Finger2Svg',
  props: {
    strokeColor: {
      type: String,
      default: '#ffffff'
    }
  }
}
</script>
