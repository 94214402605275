import {createRouter, createWebHistory} from "vue-router";

import Home from "../views/Home.vue"
import Note from "../views/Note.vue";
import Notebook from "../views/Notebook.vue"
import ImageEditorSample from "../components/ImageEditor/ImageEditorSample.vue";
import FabricNodeSample from "@/components/HtmlEditor/FabricNode/FabricNodeSample.vue";

export const router = createRouter({
    history: createWebHistory(),
    routes: [
        {path: '/', name: 'Home', meta:{title: 'Home'}, component: Home},
        {path: '/notebook', name: 'Notebook', meta:{title: 'Notebook'}, component: Notebook},
        {path: '/note', name: 'Note', meta:{title: 'Note'}, component: Note},
        {path: '/image', name: 'imageSample', meta:{title: 'imageSample'}, component: ImageEditorSample},
        {path: '/paper', name: 'paperSample', meta:{title: 'paperSample'}, component: FabricNodeSample},
    ]
})
