<template>
  <div class="p-4">
    <div v-if="data.notebook">
      <span class="h-24">{{ data.notebook?.title }}</span>
      <v-btn v-if="data.notebook.id" rounded="xl" size="x-small" color="secondary" class="ml-2"
             @click="onCreateNote">
        노트 추가
      </v-btn>
      <span v-else>
        최근 노트
      </span>
    </div>
    <div ref="containerDivRef">
      <v-container
          v-if="hasNote"
          fluid
          id="grid-container"
      >
        <v-row
            v-for="row in getRowCount()"
            class="mb-8"
            align="start"
            no-gutters
        >
          <v-col
              v-for="col in data.NOTE_COUNT_IN_ROW"
              :key="col"
          >
            <div style="position: sticky">
              <!--          v-spacer : row 를 다 채우지 못하는 경우 빈칸으로 채워야 앞의 카드가 가로로 늘어나지 않는다-->
              <v-spacer v-if="isEmptyPosition(row,col)"></v-spacer>
              <v-card v-else
                      variant="elevated"
                      color="surface-stone-50"
                      max-width="16rem"
                      min-width="16rem"
                      min-height="10rem"
                      max-height="10rem"
                      @click="onNoteClicked(row, col)"
              >
                <v-card-item>
                  <v-card-title>
                    {{ getNoteTitle(row, col) }}
                  </v-card-title>
                  <v-card-text style="display: inline-block; height: 5rem; padding: 0; overflow-y: clip;">
                    {{ getNotePreviewText(row, col) }}
                  </v-card-text>
                  <div class="flex justify-end mt-1">
                    <v-card-subtitle v-if="getReadCount(row, col) > 0" class="inline-block">
                      {{ getReadCount(row, col) }} 회독 /
                    </v-card-subtitle>
                    <v-card-subtitle class="ml-2 inline-block">
                      {{ getNoteDateTime(row, col) }}
                    </v-card-subtitle>
                  </div>
                </v-card-item>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div v-if="data.notebook && !hasNote" class="mt-8">
        노트를 추가해 주세요
      </div>
    </div>
  </div>
</template>

<script setup>

import {useRoute, useRouter} from "vue-router";
import {computed, onMounted, onUnmounted, reactive, ref, watch} from "vue";
import notebookApi from "@/api/notebookApi";
import noteApi from "@/api/noteApi";
import {convertRemToPixels} from "@/utils/util";
import commonAlert from "@/components/CommonAlert";
import {useStore} from "vuex";
import {formatDateTime} from "@/utils/dateutil";

const route = useRoute()
const router = useRouter()
const store = useStore()

const containerDivRef = ref(null)

const data = reactive({
  cardWidth: null,
  requestNotebookId: "",
  notebook: null,
  NOTE_COUNT_IN_ROW: 5
})

onMounted(() => {
  data.cardWidth = convertRemToPixels(16 + 1) // 1은 대략 margin
  recalcColumnCount()
  window.addEventListener("resize", recalcColumnCount)

  data.requestNotebookId = route.query.id ? route.query.id : ""
  loadNoteList()
})

onUnmounted(() => {

})

watch(
    () => route.query.id,
    async newNotebookId => {
      data.requestNotebookId = route.query.id ? route.query.id : ""
      await loadNoteList()
    }
)

async function loadNoteList() {
  recalcColumnCount()

  let notebookWithPreviewList = store.getters["folders/getNotebook"](data.requestNotebookId)
  if (notebookWithPreviewList === null || notebookWithPreviewList === undefined
      || notebookWithPreviewList.noteList == null // 폴더목록 api 에서 가져온 경우 노트 내용이 없으므로 다시 가져온다
      || (notebookWithPreviewList.isDirty && notebookWithPreviewList.isDirty === true) ) {
    if (data.requestNotebookId) {
      notebookWithPreviewList = await store.dispatch('notebooks/loadNoteWithPreviewList', data.requestNotebookId)
    } else {
      notebookWithPreviewList = await store.dispatch('notebooks/loadUserNoteWithPreviewList')
    }
    await store.dispatch('folders/selectNotebook', data.requestNotebookId)
  }

  if (data.notebook && data.requestNotebookId !== notebookWithPreviewList.id) {
    return
  }

  if (notebookWithPreviewList) {
    data.notebook = notebookWithPreviewList
  } else {
    await router.replace({name: 'Home'})
  }
}

const hasNote = computed(() => {
  return data.notebook && data.notebook.noteList && data.notebook.noteList.length > 0
})

function getRowCount() {
  return Math.floor(data.notebook.noteList.length / data.NOTE_COUNT_IN_ROW)
      + (data.notebook.noteList.length % data.NOTE_COUNT_IN_ROW === 0 ? 0 : 1)
}

function getNoteOf(row, col) {
  if (!data.notebook.noteList || data.notebook.noteList.length < 0) {
    return null
  }

  const index = (row - 1) * data.NOTE_COUNT_IN_ROW + col - 1
  if (index < 0 || index >= data.notebook.noteList.length) {
    return null
  }
  return data.notebook.noteList[index]
}

function isEmptyPosition(row, col) {
  return getNoteOf(row, col) === null
}

function getColCountInRow(row) {
  const elementCount = data.notebook.noteList.length - (data.NOTE_COUNT_IN_ROW * (row - 1))
  return elementCount > data.NOTE_COUNT_IN_ROW ? data.NOTE_COUNT_IN_ROW : elementCount
}

function recalcColumnCount() {
  if (containerDivRef.value) {
    data.NOTE_COUNT_IN_ROW = Math.floor(containerDivRef.value.clientWidth / data.cardWidth)
  }
}

function onCreateNote() {
  commonAlert.showInputConfirm(
      '노트 추가',
      '',
      '노트 이름',
      (noteTitle) => {
        createNote(noteTitle)
      },
      null,
      '생성'
  )
}

async function createNote(noteTitle) {
  const result = await noteApi.createNote(data.notebook.id, noteTitle)
  recalcColumnCount()
  if (result) {
    const note = {
      id: result.id,
      notebookId: result.notebookId,
      title: result.title,
    }
    data.notebook.noteList.push(note)
  }
}

function getNoteTitle(row, col) {
  const note = getNoteOf(row, col)
  return note?.title
}

function getNoteDateTime(row, col) {
  const note = getNoteOf(row, col)
  return note.readAt ? formatDateTime(note.readAt) : "-"
}

function getReadCount(row, col) {
  const note = getNoteOf(row, col)
  return note.readCount
}

function getNotePreviewText(row, col) {
  const note = getNoteOf(row, col)
  return note?.previewText
}

function onNoteClicked(row, col) {
  const note = getNoteOf(row, col)
  if (note) {
    router.push({
      name: 'Note',
      query: {
        notebookId: data.notebook.id,
        id: note.id
      }
    })
  }
}

</script>
