<template>
  <div v-if="tiptapEditor">
    <bubble-menu
        id="bubbleMenuForText"
        class="text-sm text-gray-800 text-lg"
        plugin-key="bubbleMenuForText"
        :tippy-options="{ duration: 100 }"
        :should-show="shouldShowBubbleMenuForText"
        :editor="tiptapEditor">
      <TextMenu :editor="tiptapEditor"/>
    </bubble-menu>

    <bubble-menu
        id="bubbleMenuForImage"
        plugin-key="bubbleMenuForImage"
        class="text-sm text-gray-800"
        :tippy-options="{ duration: 100 }"
        :should-show="shouldShowBubbleMenuForImage"
        :editor="tiptapEditor">
      <ImageMenu :editorState="tiptapEditor.state"
                 @onImageMenuClicked="onEditImageClicked"/>
    </bubble-menu>

  </div>
  <div class="relative mb-6 mt-2">
    <div v-if="data.fold" class="w-full h-4 bg-stone-200" style="{caret-color: transparent}"></div>
    <div v-else style="position: relative">
      <div style="position: relative; z-index: 1;">
        <editor-content :class="data.hideCaret ? 'ProseMirror-caret' : ''"
                        class="tiptap"
                        :editor="tiptapEditor"
                        @click="onEditorClicked"
                        ref="editorRef"/>
      </div>
      <div v-show="data.isScribbleMode" :id="data.overlayCanvasId" class="w-full h-full pointer-events-auto"
           style="position: absolute; z-index: 100; top: 0; left: 0;">
        <canvas ref="canvasRef" class="w-full h-full bg-transparent">
        </canvas>
      </div>
      <ConcealMenu ref="concealMenuRef"
                   v-show="data.isScribbleMode && data.isScribbleEditorMode && shouldShowConcealMenu"
                   style="position: absolute; z-index: 101;"
                   :style="{left: data.lastClickedConcealRect.left, top: data.lastClickedConcealRect.top}"
                   @onChangeConcealColor="changeConcealBorderColor"
                   @onUnsetConceal="unsetConceal"/>
    </div>
    <font-awesome-icon v-if="data.fold"
                       icon="arrow-down"
                       class="absolute right-0 top-0 cursor-pointer text-gray-500 text-md"
                       @mousedown.prevent="onFoldClicked"/>
    <div class="absolute right-0 top-0 z-10 mr-2 flex justify-center items-center">
      <div v-if="data.showContextMenu" class="flex justify-center items-center">
        <font-awesome-icon icon="trash-can"
                           class="cursor-pointer text-gray-500 text-lg mr-4"
                           @mousedown.prevent="onDeleteClicked"/>
        <font-awesome-icon icon="arrow-up"
                           class="cursor-pointer text-gray-500 text-md mr-4 flex justify-center items-center"
                           @mousedown.prevent="onFoldClicked"/>
        <font-awesome-icon icon="circle-plus"
                           class="cursor-pointer text-gray-500 text-md mr-4 flex justify-center items-center"
                           @mousedown.prevent="onAddSection"/>
      </div>
      <font-awesome-icon v-if="data.hasFocus"
                         icon="ellipsis"
                         class="cursor-pointer text-gray-500 text-lg h-6"
                         @blur="onBlurMenuButton"
                         @mousedown.prevent="onContextMenuClicked"/>
    </div>
  </div>
  <!--  <div>-->
  <!--    {{ getHtml() }}-->
  <!--    {{ getText() }}-->
  <!--  </div>-->
</template>

<script setup>
import StarterKit from '@tiptap/starter-kit'
import {Editor, EditorContent, BubbleMenu} from '@tiptap/vue-3'
import TextStyle from '@tiptap/extension-text-style'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import FontFamily from '@tiptap/extension-font-family'
import FontSize from 'tiptap-extension-font-size'
import Conceal from '../components/HtmlEditor/extensions/conceal/conceal'
import Highlight from '@tiptap/extension-highlight'
import {Color} from '@tiptap/extension-color'
import Link from '@tiptap/extension-link'
import Table from '@tiptap/extension-table'
import TableEx from "@/components/HtmlEditor/extensions/table"
import {v4 as uuid} from "uuid"
import {convertRemToPixels, lazyExec, range} from "@/utils/util";
// import TableDemoEx from "@/components/HtmlEditor/extensions/table/TableDemoEx";
import TableCell from '@tiptap/extension-table-cell'
import TableCellEx from '@/components/HtmlEditor/extensions/tableCellEx/tableCellEx'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'

import TableRowEx from "@/components/HtmlEditor/extensions/tableRowEx/tableRowEx";
import ImageWithTools from '../components/HtmlEditor/extensions/ImageResize'
import ImageLogic from '../components/logic/ImageLogic.ts'
import {fabric} from 'fabric'
import WorkspacePlugin from "@/components/ImageEditor/controls/plugin/WorkspacePlugin";
import FabricNode from "@/components/HtmlEditor/FabricNode/FabricNode";
import {CellSelection} from 'prosemirror-tables'

import ImageMenu from "../components/HtmlEditor/BubbleMenu/ImageMenu.vue"
import TextMenu from "../components/HtmlEditor/BubbleMenu/TextMenu.vue"
import ConcealMenu from "../components/HtmlEditor/BubbleMenu/ConcealMenu.vue"
import ScribbleOnEditorHandler from "@/components/ImageEditor/controls/draw/ScribbleOnHtmlEditorHandler";
import {onMounted, onUnmounted, reactive, watch, ref, computed, onBeforeMount} from "vue";
import {ConcealManager} from "@/components/logic/ConcealManager";

import sectionApi from "@/api/sectionApi";
import commonAlert from "@/components/CommonAlert";
import {useStore} from "vuex";

const emit = defineEmits([
  'contentChanged',
  'onKeydown',
  'onFocus',
  'onBlur',
  'onAddSectionClicked',
  'onSectionDeleted',
  'onEditImageClicked',
  'textAttributeDetected'
])

const editorAppearance = 'px-4 py-4 bg-neutral-50 border border-gray-200 rounded focus:outline focus:outline-1 focus:outline-sky-200'
const defaultFontSize = '14pt'
const defaultTextAlign = 'left'

const store = useStore()

const props = defineProps({
  contentValue: {
    type: String,
    default: '',
  },
  notebookId: {
    type: String,
    default: ''
  },
  noteId: {
    type: String,
    default: '',
  },
  sectionId: {
    type: String,
    default: '',
  },
  sectionIndex: {
    type: Number,
    default: -1
  }
})

const canvasRef = ref(null)
const editorRef = ref(null)
const concealMenuRef = ref(null)

const tiptapEditor = ref(null)

const data = reactive({
  // contentValue: null,
  sectionId: props.sectionId, // props에서 가져왔을 때 null 이었다가 저장되면 값이 바뀔 수 있다.
  imageLogic: null,
  fold: false,
  showContextMenu: false,
  orgContent: "",
  foldedText: "",
  hasFocus: false,
  turnOffBubbleMenu: false,
  hideCaret: false,
  editorModeCount: 1,
  isScribbleMode: false,
  isScribbleEditorMode: false, // scribble mode에서만 유효 true - 클릭시 컨텍스트메뉴도 표시, false - reveal 동작만 수행
  canvas: null,
  canvasWorkspacePlugin: null,
  scribbleOnHtmlEditorHandler: null,
  overlayCanvasId: 0,
  revealingConcealInfoArray: new Array(),
  lastClickedConcealRect: {left: 0, top: 0},
  concealMenuSize: {
    width: convertRemToPixels(6.2),
    height: convertRemToPixels(2)
  },
  concealManager: null,
  latestContentValue: '',
  checkSaveIntervalId: 0,
  isDirty: false
})

defineExpose({
  setScribbleMode,
  setScribbleEditorMode,
  updateEditingImageSrc,
})


// watch(() => data.contentValue, (newVal, oldVal) => {
//   const isSame = tiptapEditor.value.getHTML() === newVal
//
//   // JSON
//   // const isSame = JSON.stringify(this.tiptapEditor.getJSON()) === JSON.stringify(value)
//
//   if (isSame) {
//     return
//   }
//
//   console.log('watch')
//   tiptapEditor.value.commands.setContent(newVal, false)
// })

const shouldShowConcealMenu = computed(() => {
  return data.concealManager.hasConcealText()
});

onMounted(() => {
  data.overlayCanvasId = uuid()
  // data.contentValue = props.contentValue

  window.addEventListener('deviceorientation', reCacheConcealText);

  tiptapEditor.value = new Editor({
    extensions: [
      StarterKit,
      Text,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
        defaultAlignment: defaultTextAlign
      }),
      TextStyle,
      FontFamily,
      FontSize,
      Color,
      Underline,
      TableEx.configure({
        resizable: true,
        lastColumnResizable: true,
        cellMinWidth: 50,
        // selectable: true,
        // allowTableNodeSelection: false
      }),
      TableRow,
      TableHeader,
      TableCellEx,
      Highlight.configure(
          {
            multicolor: true,
            // HTMLAttributes: {'cursor': 'pointer'}
          }
      ),
      Link.configure({openOnClick: true,}),
      ImageWithTools.configure({
        inline: true,
        onExtraCreated: (eleExtra, imgRef) => {
          // eleExtra.innerHTML = 'something else'
        },
        onError: (eleExtra) => {
          if (eleExtra) {
            eleExtra.innerHTML = '<errorIcon />'
          }
        }
      }),
      // Document,
      // Paragraph,
      // Text,
      // CustomBold
      Conceal,
      FabricNode.configure({
        // able to add some option, pass to FabricNode
      }),
    ],
    editorProps: {
      attributes: {
        class: editorAppearance,
        style: `font-size: ${defaultFontSize};`,
        // class: `${editorAppearance} ${this.tClass}`
      },
      handleKeyDown: (view, event) => {
        let handled = false
        // 바깥으로 key down 이벤트를 전달할 케이스가 계속 추가될 예정
        // if (event.metaKey && event.keyCode === 13) {
        //   emit('onKeydown', this, view, event, data.sectionId)
        //   handled = true // true 로 리턴하면 editor 기본 동작을 막는다
        // }
        onEditorKeydown(event)
        return handled
      }
    },
    content: props.contentValue,
  })

  data.latestContentValue = props.contentValue

  data.concealManager = new ConcealManager(tiptapEditor.value)

  tiptapEditor.value.chain().focus().setFontSize(`${defaultFontSize}`).run();

  tiptapEditor.value.on('update', ({editor}) => {
    if (editor.isEmpty) {
      restoreDefaultFontSize(editor);
    }
  })

  tiptapEditor.value.on('focus', ({editor, event}) => {
    data.hasFocus = true
    emit('onFocus', editor)
  })

  tiptapEditor.value.on('blur', ({editor, event}) => {
    data.hasFocus = false
    data.showContextMenu = false
    emit('onBlur')
  })

  tiptapEditor.value.on('selectionUpdate', ({editor}) => {
    onSelectionUpdate()
  })

  tiptapEditor.value.on('onConcealMarkClick', (concealInfo) => {
    revealConcealTextTemporary(concealInfo)
  })

  // tiptapEditor.value.on('onFabricComponentConcealRectClick', ({concealMenuPos}) => {
  //   concealRectClickedOnFabricComponent(concealMenuPos)
  // })

  tiptapEditor.value.on('onConcealRectClick', ({concealRect}) => {
    revealConcealRectTemporary(concealRect)
  })

  tiptapEditor.value.setOptions()

  // canvas element 를 query해서 크기를 맞추고 있으므로 딜레이를 조금 준다
  lazyExec(initScribbleCanvas, 100)

  startCheckSave()
})

onUnmounted(() => {

  window.removeEventListener('deviceorientation', reCacheConcealText);

  if (data.canvasWorkspacePlugin) {
    data.canvasWorkspacePlugin.dispose()
  }

  if (data.canvas) {
    data.canvas.clear()
    data.canvas.dispose()
  }

  tiptapEditor.value.destroy()

  stopCheckSave()
})

function startCheckSave() {
  data.checkSaveIntervalId = setInterval(checkNeedSave, 5000)
}

function stopCheckSave() {
  clearInterval(data.checkSaveIntervalId)
}

function checkNeedSave() {
  const content = tiptapEditor.value.getHTML()
  const isDirty = content !== data.latestContentValue
  if (isDirty) {
    createOrUpdate()
  }

  data.latestContentValue = content
}

function createOrUpdate() {
  store.dispatch('sections/createOrUpdateSection', {
    notebookId: props.notebookId,
    sectionId: data.sectionId,
    index: props.sectionIndex,
    noteId: props.noteId,
    content: getHtml()
  }).then((result) => {
    data.sectionId = result.id
  })
}

function initScribbleCanvas() {
  // const drawCursor =
  //     `data:image/svg+xml;base64,${window.btoa('<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">' +
  //         '<path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/>' +
  //         '</svg>')}`

  fabric.Object.NUM_FRACTION_DIGITS = 8

  data.canvas = new fabric.Canvas(canvasRef.value, {
    isDrawingMode: true,
    width: 800,
    height: 600,
    selection: false
  })

  data.canvasWorkspacePlugin = new WorkspacePlugin(data.canvas, {containerId: data.overlayCanvasId})
  const drawProperty = {
    strokeWidth: 8,
    stroke: '#c084fcaa',
  }

  data.scribbleOnHtmlEditorHandler = new ScribbleOnEditorHandler(data.canvas, drawProperty)
  data.scribbleOnHtmlEditorHandler.setTiptapEditor(editorRef.value.$el, tiptapEditor.value)
}

function shouldShowBubbleMenuForText(status) {
  return shouldShowBubbleMenu(['text', 'paragraph'], status.state)
}

function shouldShowBubbleMenuForImage(status) {
  return shouldShowBubbleMenu(['image', 'gncanvas'], status.state)
}

function shouldShowBubbleMenu(types, state) {
  if (!data.hasFocus || data.turnOffBubbleMenu || state.selection.empty) {
    return false
  }

  const nodeType = getNodeTypeBetween(state, state.selection.from, state.selection.to)
  return types.includes(nodeType)
}

function getNodeTypeBetween(state, from, to) {
  let nodeType = ''
  state.doc.nodesBetween(from, to, (node, pos) => {
    nodeType = node.type.name
  })

  return nodeType
}

function revealConcealTextTemporary(concealInfo) {
  data.concealManager.revealConcealTextTemporary(concealInfo)
  // Note.vue에 여러 HtmlEditor (섹션당 하나씩)가 있고
  // concealMenu 는 HemlEditor 오른쪽 상단을 0,0 으로해서 좌표를 가진다
  data.lastClickedConcealRect = {
    left: concealInfo.rect.xDistFromEditorTop - (data.concealMenuSize.width / 2 - concealInfo.rect.width / 2),
    top: concealInfo.rect.yDistFromEditorTop - (data.concealMenuSize.height * 1.2)
  }
}

function revealConcealRectTemporary(concealRect) {
  data.concealManager.revealConcealRectTemporary(concealRect.obj)

  const concealRectObj = concealRect.obj
  const canvas = concealRectObj.canvas
  const canvasElRect = concealRect.canvasElRect
  const scale = canvas.getZoom()
  data.lastClickedConcealRect = {
    left: canvasElRect.left - data.editorRect.left + (concealRectObj.left + concealRectObj.width / 2) * scale - data.concealMenuSize.width / 2,
    top: canvasElRect.top - data.editorRect.top + concealRectObj.top * scale - data.concealMenuSize.height - 4
  }
}

function changeConcealBorderColor(color) {
  data.concealManager.changeLastConcealTextBorderColor(color)
}

function revealOrConcealAll(scribbleMode) {
  const state = tiptapEditor.value.state;
  const tr = state.tr
  state.doc.descendants((node, pos) => {
    const concealMarks = node.marks.filter(mark => mark.type.name === 'conceal')
    concealMarks.forEach(concealMark => {
      const $pos = state.doc.resolve(pos)
      const from = pos - $pos.textOffset
      const to = from + node.nodeSize

      const concealWithAttr = state.schema.marks.conceal.create({
            ...concealMark.attrs,
            conceal: scribbleMode
          }
      )
      tr.addMark(from, to, concealWithAttr)
    })
  });

  tiptapEditor.value.view.dispatch(tr)
}

function unsetConceal() {
  data.concealManager.unsetLastConcealInfo();
}

function setEditorMode(enable) {
  // enable - true : 편집가능, 버블메뉴 표시, 캐럿 표시
  // enable - false : 편집 불가능, 버블메뉴 표시안됨, 캐럿 없음

  // 여러 conceal 을 계속 클릭하면 마지막 conceal 이 reveal 될 때 editable 을 true 로 되돌린다.
  data.editorModeCount = enable ? data.editorModeCount + 1 : data.editorModeCount - 1

  if (data.editorModeCount > 0) {
    tiptapEditor.value.setOptions({editable: true});
    // data.turnOffBubbleMenu = false
    data.hideCaret = false
  } else {
    tiptapEditor.value.setOptions({editable: false});
    // data.turnOffBubbleMenu = true
    data.hideCaret = true
  }
}

function clearSelection() {
  tiptapEditor.value.commands.setTextSelection({from: 0, to: 0})
}

function clearTextSelection() {
  const {state, dispatch} = data.editor
  const {from, to} = state.selection
  const tr = state.tr.setSelection(null);
  // dispatch(tr);
}

function setFocus() {
  if (data.editor && tiptapEditor.value.commands) {
    tiptapEditor.value.commands.focus()
  }
}

function handleEditorClick(event) {
  // const selection = window.getSelection();
  // const clickedWord = selection.toString().trim();
  //
  // if (clickedWord !== '') {
  //   this.$emit('wordClicked', selection.anchorOffset)
  // }
  const state = tiptapEditor.value.view.state
  const {from, to} = state.selection
  if (from && to && to - from > 0) {
    emit('wordClicked', from, to, this)
  }
}

function getText() {
  if (tiptapEditor.value) {
    return tiptapEditor.value.getText()
  }
}

function getHtml() {
  if (tiptapEditor.value) {
    return tiptapEditor.value.getHTML()
  }
}

function hideText() {
  tiptapEditor.value.chain().focus().toggleHighlight({color: '#8ce99a'}).run()
  // this.tiptapEditor.chain().focus().setColor('#8ce99a').run()
  tiptapEditor.value.commands.blur()
  // this.tiptapEditor.commands.setLink({ href: 'https://example.com' })
}

function onContextMenuClicked() {
  data.showContextMenu = !data.showContextMenu
}

function onFoldClicked() {
  data.fold = !data.fold
}

function onEditorClicked() {
  detectAndSendTextAttributeEvent()
  data.showContextMenu = false
}

function onBlurMenuButton() {
  data.showContextMenu = false
}

function onDeleteClicked() {
  commonAlert.showConfirmAlert(
      "삭제",
      "섹션을 삭제하시겠습니까?",
      () => { deleteSection() }
  )
}

function onAddSection() {
  emit('onAddSectionClicked', props.sectionIndex)
}

async function deleteSection() {
  if (data.sectionId) {
    const result = await store.dispatch('sections/deleteSection', {
      notebookId: props.notebookId,
      noteId: props.noteId,
      sectionId: data.sectionId,
      sectionIndex: props.sectionIndex
    })
    if (result) {
      emit('onSectionDeleted', props.sectionIndex)
    }
  }
}

function onEditImageClicked(props) {
  emit('onEditImageClicked', {...props})
}

function updateEditingImageSrc(imageUrl) {
  const {state} = tiptapEditor.value
  if (!state || state.selection.empty) {
    return
  }

  state.doc.nodesBetween(state.selection.from, state.selection.to, (node, pos) => {
    if ('image' === node.type.name || 'gncanvas' === node.type.name) {
      const result = tiptapEditor.value.commands.updateAttributes(node.type.name,
          {src: imageUrl}
      )

      if (result) {
        forceRender()
      }
    }
  })

  return
  // */

  /* 다른 방법
  const {state} = this.tiptapEditor
  if (!state || state.selection.empty) {
    return
  }

  const tr = state.tr;

  const newAttr = { src: imageUrl }
  state.doc.nodesBetween(state.selection.from, state.selection.to, (node, pos) => {
    if ('image' === node.type.name) {
      // tr.setNodeMarkup(pos, undefined, {
      //   ...node.attrs,
      //   ...newAttr,
      // })
      const newAttrs = { ...node.attrs, src: imageUrl };
      const newNode = state.schema.nodes.image.create(newAttrs, node.content, node.marks);
      tr.replaceWith(pos, pos + node.nodeSize, newNode);
      this.tiptapEditor.view.dispatch(tr)
    }
  })

  this.forceRender()
   //*/
}

function forceRender() {
  // tiptapEditor 가 mounted 될 때 NoteSpace 의 소스를 editor 에 전달하므로
  // 결국 NoteSpace 가 가지고 있는 소스 content (html) 을 업데이트해야 한다.
  const orgHtml = getHtml()
  const tempHtml = '<p>' + getHtml()
  tiptapEditor.value.commands.setContent(tempHtml, false)
  tiptapEditor.value.commands.setContent(orgHtml, false)
  // this.$forceUpdate()
}

function setScribbleMode(scribbleMode) {
  if (data.isScribbleMode === scribbleMode) {
    return
  }

  if (scribbleMode) {
    checkNeedSave() // scribble mode 로 인해서 editor update => save 동작 호출 방지
    stopCheckSave()
  } else {
    startCheckSave()
  }

  revealOrConcealAll(scribbleMode)

  // changeScribbleMode(value) 를 호출해주면
  // 위 스펙이 FabricNode - FabricComponent에도 적용되도록 하는데
  // 단, toggleAllConcealTextVisible에서 forceRender 를 호출하면 FabricNode - FabricComponent도 새로 생성되기 때문에
  // scribbleMode가 디폴트 값이 되버린다. 따라서 지연을 두어 효과가 완료된 이후에 해야한다.
  if (scribbleMode) {
    data.scribbleOnHtmlEditorHandler.prepare()
    // scribbleMode 에서만 <ConcealMenu> 를 띄우는데 이때 위치를 잡기 위해서 editorRect 가 필요하다
    data.editorRect = editorRef.value.$el.getBoundingClientRect();
  } else {
    data.scribbleOnHtmlEditorHandler.dispose()
  }

  tiptapEditor.value.emit('changeScribbleMode', scribbleMode)
  data.concealManager.setScribbleMode(scribbleMode)
  data.isScribbleMode = scribbleMode
}

function setScribbleEditorMode(scribbleEditorMode) {
  data.isScribbleEditorMode = scribbleEditorMode
}

function reCacheConcealText() {
  if (data.isScribbleMode) {
    setTimeout(() => {
      data.scribbleOnHtmlEditorHandler.cacheConcealText()
    }, 1000)
  }
}

function restoreDefaultFontSize(editor) {
  const currentFontSize = editor.getAttributes('textStyle').fontSize;
  const newFontSize = currentFontSize || defaultFontSize;
  editor.chain().focus().setFontSize(newFontSize).run();
}

function onSelectionUpdate() {
  detectAndSendTextAttributeEvent()
}

function detectAndSendTextAttributeEvent() {
  // text alignment
  const { state } = tiptapEditor.value;
  const { from } = state.selection;

  const resolvedPos = state.doc.resolve(from);
  const parentNode = resolvedPos.node(resolvedPos.depth);
  const alignment = parentNode.attrs.textAlign || defaultTextAlign

  // font size
  const fontSize = tiptapEditor.value.getAttributes('textStyle').fontSize || defaultFontSize

  emit('textAttributeDetected', {alignment, fontSize})
}

function onEditorKeydown(event) {
  if (event.key === 'Enter') {
    // 엔터키를 누르면 conceal attribute 는 따라가지 않도록 한다.
    tiptapEditor.value.chain().focus().unsetConceal().run()
  }
}
// updateNodeAttrs(nodeType, attributes) {
// this.htmlEditor.commands.updateAttributes 의 코드이다
//   const state = this.htmlEditor.view.state;
//   const tr = this.htmlEditor.state.tr;
//
//   tr.selection.ranges.forEach(range => {
//     const from = range.$from.pos
//     const to = range.$to.pos
//
//     console.log(from + ' ' + to)
//     state.doc.nodesBetween(from, to, (node, pos) => {
//       if (nodeType && nodeType === node.type.name) {
//         tr.setNodeMarkup(pos, undefined, {
//           ...node.attrs,
//           ...attributes,
//         })
//       }
//     })
//   })
//
//   this.htmlEditor.view.dispatch(tr)
//
//   return true
// }
</script>

<style lang="scss">

.drag-handle {
  display: none;
  flex: 0 0 auto;
  position: relative;
  width: 1rem;
  height: 1rem;
  top: 0.3rem;
  margin-right: 0.5rem;
  cursor: grab;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.ProseMirror-selectednode {
  z-index: 1;

  .drag-handle {
    display: block;
  }
}

.tiptap {
  table {
    table-layout: fixed;
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 2em;
      border: 1px solid #ced4da;
      padding: 8px 8px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      z-index: 10;
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    .row-resize-handle {
      position: absolute;
      bottom: -2px;
      left: 0px;
      right: -2px;
      width: 100%;
      height: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.row-resize-cursor {
  cursor: ns-resize;
  cursor: row-resize;
}

// change TextSelection Color
//::selection {
//  color: white; /* Change this to your desired color */
//  background-color: rgba(125, 125, 255, 125); /* Optional: set background color for the selected text */
//}

.ProseMirror-caret {
  background: transparent;
  caret-color: transparent;
}

.ProseMirror {
  //> * + * {
  //  margin-top: 0.75em;
  //}  줄간격인가?

  //img { 이미지 크기
  //  max-width: 50%;
  //  height: auto;
  //
  //  &.ProseMirror-selectednode {
  //    outline: 3px solid #68CEF8;
  //  }
  //}
}

</style>
