import folderApi from "../../api/folderApi"

const state = {
    // 여기서 저장한 folders 에는 폴더목록, 노트북목록, 노트북의 노트목록 표시까지만 사용한다.
    // 노트내의 섹션 데이터는 notes.js 에서 관리한다.
    folders: [],
    selectedNotebook: null,
    /*
    folder: {
        id: "",
        title: "",
        notebooks: []
    },
    notebook: {
        id: "",
        title: "",
        noteList: [
            id: "",
            notebookId: "",
            previewText: "",
            title: "",
        ]
    }
    */
}

const getters = {
    getFolders: (state) => () => {
        return state.folders
    },

    getFolder: (state) => (folderId) => {
        return state.folders.find(folder => folder.id === folderId)
    },

    getSelectedNotebook: (state) => () => {
        return state.selectedNotebook
    },

    getNotebook: (state) => (notebookId) => {
        for (let i = 0; i < state.folders.length; i++) {
            const folder = state.folders[i]
            for (let j = 0; j < folder.notebooks.length; j++) {
                const notebook = folder.notebooks[j]
                if (notebook.id === notebookId) {
                    return notebook
                }
            }
        }

        return null
    }
}

const actions = {
    setFolders({commit}, data) {
      commit('setFolders', data)
    },
    addFolder({commit}, data) {
        commit('addFolder', data)
    },
    updateFolder({commit}, data) {
        commit('updateFolder', data)
    },
    deleteFolder({commit}, id) {
        commit('deleteFolder', id)
    },
    selectNotebook({commit}, id) {
        commit('selectNotebook', id)
    }
}

const mutations = {
    clearAll(state) {
        state.folders = []
        state.selectedNotebook = null
    },

    setFolders(state, folders) {
        state.folders = folders || []
    },

    addFolder(state, folderData) {
        state.folders.push({
            id: folderData.id,
            title: folderData.title,
            notebooks: []
        })
    },

    updateFolder(state, folderData) {
        const folder = state.folders.find(f => f.id === folderData.id)
        if (folder) {
            folder.title = folderData.title
        }
    },

    deleteFolder(state, folderId) {
        state.folders = state.folders.filter(f => f.id !== folderId)
    },

    addNotebook(state, notebookData) {
        console.log('add notebook')
        const folder = state.folders.find(f => f.id === notebookData.folderId)
        if (folder) {
            const notebook = {
                id: notebookData.id,
                folderId: notebookData.folderId,
                title: notebookData.title
            }

            if (folder.notebooks) {
                folder.notebooks.push(notebook)
            } else {
                folder.notebooks = [notebook]
            }
        }
    },

    updateNotebook(state, notebookData) {
        let folder = state.folders.find(f => f.id === notebookData.folderId)
        if (folder === null || folder === undefined) {
            folder = {
                id: notebookData.folderId,
                title: "",
                notebooks: []
            }
            state.folders.push(folder)
        }

        if (folder) {
            const notebook = folder.notebooks.find(n => n.id === notebookData.id)
            if (notebook) {
                notebook.title = notebookData.title
                notebook.noteList = notebookData.noteList
                notebook.isDirty = false
            } else {
                const newNoteBook = {
                    id: notebookData.id,
                    title: notebookData.title,
                    noteList: notebookData.noteList,
                    isDirty: false
                }

                folder.notebooks.push(newNoteBook)
            }
        }
    },

    removeNotebook(state, notebookId) {
        const folder = state.folders.find(f => f.notebooks.some(n => n.id === notebookId))
        if (folder) {
            const notebookIndex = folder.notebooks.findIndex(n => n.id === notebookId)
            if (notebookIndex >= 0) {
                folder.notebooks.splice(notebookIndex, 1)
            }
        }
    },

    selectNotebook(state, notebookId) {
        state.selectedNotebook = null
        state.folders.forEach(f => {
            f.notebooks.forEach(nb => {
                if (nb.id === notebookId) {
                    state.selectedNotebook = nb
                }
            })
        })
    },

    // 폴더가 들고 있는 노트북의 컨텐츠가 변경되었음을 표시한다
    // 노트북 내의 노트 표시 화면 표시등을 위해서 노트북 정보를 가져다 쓰는 쪽에서
    // isDirty 이면 서버에서 다시 가져오게 된다.
    setNotebookContentsIsDirty(state, notebookId) {
        const folder = state.folders.find(f => f.notebooks.some(n => n.id === notebookId))
        if (folder) {
            const notebookIndex = folder.notebooks.findIndex(n => n.id === notebookId)
            if (notebookIndex >= 0) {
                const notebook = folder.notebooks[notebookIndex]
                folder.notebooks[notebookIndex] = {
                    ...notebook,
                    isDirty: true
                }
            }
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
