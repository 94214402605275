<template>
  <div class="w-full">
    <div class="inline-block p-1 h-fit text-lg text-white rounded-sm bg-sky-700 text-white">
      <button @mousedown.prevent="onNoteSpaceMenuClicked(NoteSpaceMenu.TYPING)"
              class="px-1 w-10 h-10">
        <keyboard-svg :stroke-color="getNoteSpaceMenuStyle(NoteSpaceMenu.TYPING)"/>
      </button>
      <button @mousedown.prevent="onNoteSpaceMenuClicked(NoteSpaceMenu.SCRIBBLE)"
              class="px-1 w-10 h-10">
        <brain-svg :stroke-color="getNoteSpaceMenuStyle(NoteSpaceMenu.SCRIBBLE)"/>
      </button>
    </div>

    <div v-if="!data.scribbleMode"
         class="ml-4 inline-block p-1 h-fit text-lg text-white rounded-sm gn-v-center"
         :class="activeEditor ? 'bg-sky-600 text-white pointer-events-auto' : 'bg-gray-200 text-gray-300 pointer-events-none'">
      <div class="inline-block">
        <button class="px-1 w-10 h-10"
                @mousedown.prevent="onEditorMenuClicked(TextMenu.BOLD)">
          <span> {{ TextMenu.BOLD.title }}</span>
        </button>
        <button class="px-1 w-10 h-10"
                @mousedown.prevent="onEditorMenuClicked(TextMenu.ITALIC)">
          <span> {{ TextMenu.ITALIC.title }}</span>
        </button>
        <div class="inline-block">
          <button
              class="px-1 w-10 h-10"
              @mousedown.prevent="">
            <span> {{ data.currentFontSize }}</span>
          </button>
          <v-menu activator="parent">
            <v-list>
              <v-list-item
                  v-for="(size, index) in data.fontSizeOptions"
                  :key="index"
                  :value="size"
                  @mousedown.prevent="onFontSizeClicked(size)"
              >
                <v-list-item-title>
                  {{ size }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="inline-block">
          <button class="px-1 w-10 h-10"
                  @mousedown.prevent="">
            <font-awesome-icon :icon="getFontAwesomeAlignmentIconName(data.currentTextAlignment)"></font-awesome-icon>
          </button>
          <v-menu activator="parent">
            <v-list>
              <v-list-item
                  v-for="(alignment, index) in data.textAlignOptions"
                  :key="index"
                  :value="alignment"
                  @mousedown.prevent="onTextAlignmentChanged(alignment)"
              >
                <v-list-item-title>
                  <font-awesome-icon :icon="getFontAwesomeAlignmentIconName(alignment)"/>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <button v-if="!tableMenuVisible"
                class="px-1 w-10 h-10"
                @mousedown.prevent="createTable">
          <font-awesome-icon icon="table"></font-awesome-icon>
        </button>
      </div>

      <div v-if="tableMenuVisible" class="inline-block gn-v-center">
        <span class="mx-2 text-gray-400">|</span>
        <button @mousedown.prevent="onTableMenuClicked(TableMenu.INSERT_COLUMN)"
                class="px-1 w-8 h-8">
          <insert-column-svg/>
        </button>
        <button @mousedown.prevent="onTableMenuClicked(TableMenu.INSERT_ROW)"
                class="px-1 w-8 h-8">
          <insert-row-svg/>
        </button>
        <button @mousedown.prevent="onTableMenuClicked(TableMenu.DELETE_COLUMN)"
                class="px-1 w-8 h-8">
          <delete-column-svg/>
        </button>
        <button @mousedown.prevent="onTableMenuClicked(TableMenu.DELETE_ROW)"
                class="px-1 w-8 h-8">
          <delete-row-svg/>
        </button>
        <button @mousedown.prevent="onTableMenuClicked(TableMenu.MERGE_CELL)"
                class="px-1 w-8 h-8">
          <merge-cell-svg/>
        </button>
        <button @mousedown.prevent="onTableMenuClicked(TableMenu.SPLIT_CELL)"
                class="px-1 w-8 h-8">
          <split-cell-svg/>
        </button>
        <div class="inline-block">
          <button @mousedown.prevent=""
                  class="px-1 w-8 h-8">
            <fill-cell-svg/>
          </button>
          <v-menu activator="parent">
            <v-list>
              <v-list-item
                  v-for="(color, index) in data.cellColorOptions"
                  :key="index"
                  :value="color"
                  @mousedown.prevent="onTableCellColorSelected(color)"
              >
                <v-list-item-title>
                  <div class="w-8 h-4 gn-v-center" style="border: black 1px dotted" :style="{backgroundColor: color}">
                    <span v-if="color==='transparent'"> x </span>
                  </div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <button @mousedown.prevent="onTableMenuClicked(TableMenu.DELETE_TABLE)"
                class="px-1 w-8 h-8">
          <delete-table-svg/>
        </button>
      </div>

      <input
          class="hidden"
          id="hidden_file_input_image"
          accept=".png,.jpeg,.jpg,.gif"
          type="file"
          ref="imgFileInput"
          @change="onImageFileSelected">
    </div>

    <div v-if="data.scribbleMode"
         class="ml-4 inline-block p-1 h-fit text-lg text-white rounded-sm gn-v-center bg-sky-600 text-white pointer-events-auto">
      <div class="inline-block">
        <button class="px-1 w-10 h-10"
                @mousedown.prevent="onScribbleEditorMenuClicked(false)">
          <finger-svg :stroke-color="getScribbleEditorMenuStyle(false)" />
        </button>
        <button class="px-1 w-10 h-10"
                @mousedown.prevent="onScribbleEditorMenuClicked(true)">
          <finger2-svg :stroke-color="getScribbleEditorMenuStyle(true)" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>

import {TextMenu, NoteSpaceMenu, TableMenu} from "@/components/HtmlEditor/constant";
import {reactive, inject, ref, computed, onMounted} from "vue";
import ImageLogic from "@/components/logic/ImageLogic";
import {selectionIsInTable} from "@/components/HtmlEditor/editorUtil";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import InsertColumnSvg from "@/assets/svg/insertColumn.vue";
import InsertRowSvg from "@/assets/svg/insertRow.vue";
import DeleteColumnSvg from "@/assets/svg/deleteColumn.vue";
import DeleteRowSvg from "@/assets/svg/deleteRow.vue";
import MergeCellSvg from "@/assets/svg/mergeCell.vue";
import SplitCellSvg from "@/assets/svg/splitCell.vue";
import FillCellSvg from "@/assets/svg/fillCell.vue";
import DeleteTableSvg from "@/assets/svg/deleteTable.vue";
import BrainSvg from "@/assets/svg/brain.vue";
import KeyboardSvg from "@/assets/svg/keyboard.vue";
import FingerSvg from "@/assets/svg/finger.vue";
import Finger2Svg from "@/assets/svg/finger2.vue";

const emit = defineEmits(['modeChanged', 'scribbleEditorModeChanged'])
defineExpose({
  setCurrentTextAttribute
})

const textMenus = Object.values(TextMenu)
const tableMenus = Object.values(TableMenu)
const noteSpaceMenus = Object.values(NoteSpaceMenu)

const activeEditor = inject('activeEditor')

const imgFileInput = ref(null)
const imageLogic = new ImageLogic()

const tableMenuVisible = computed(() => {
  const editor = activeEditor.value

  if (editor) {
    return selectionIsInTable(editor.state)
  } else {
    return false
  }
})

const data = reactive({
  scribbleMode: false,
  scribbleEditorMode: false, // scribble mode에서만 유효 true - 클릭시 컨텍스트메뉴도 표시, false - reveal 동작만 수행
  showFontSizeMenu: false,
  currentFontSize: 14,
  fontSizeOptions: [
    12,
    14,
    16,
    20,
    24,
    30,
    36,
    48,
    64
  ],
  currentTextAlignment: "left",
  textAlignOptions: [
    'left',
    'center',
    'right'
  ],
  cellColorOptions: [
    "transparent",
    "#eaeaea",
    "#d4d4d4",
    "#ddd1f3",
    "#fff08c",
    "#cdedd8",
    "#cde6f9",
    "#ccd7ea"
  ]
})

function onNoteSpaceMenuClicked(menu) {
  if (menu === NoteSpaceMenu.TYPING) {
    data.scribbleMode = false
  } else if (menu === NoteSpaceMenu.SCRIBBLE) {
    data.scribbleMode = true
  }
  emit('modeChanged', data.scribbleMode)
}

function getNoteSpaceMenuStyle(menu) {
  if (menu === NoteSpaceMenu.TYPING) {
    return data.scribbleMode ? '#aaaaaa' : '#ffffff'
  } else if (menu === NoteSpaceMenu.SCRIBBLE) {
    return data.scribbleMode ? '#ffffff' : '#aaaaaa'
  }
}

function onEditorMenuClicked(menu) {
  if (!activeEditor) {
    return
  }

  const editor = activeEditor.value

  if (menu.cmd === "Bold") {
    editor.chain().focus().toggleBold().run()
  } else if (menu.cmd === "Italic") {
    editor.chain().focus().toggleItalic().run()
  } else if (menu.cmd === "Underline") {
    editor.chain().focus().toggleUnderline().run()
  } else if (menu.cmd === "AddImage") {
    imgFileInput.value.click()
  }
}

function onTableMenuClicked(menu) {
  if (!activeEditor) {
    return
  }

  const editor = activeEditor.value

  if (menu === TableMenu.INSERT_COLUMN) {
    editor.commands.addColumnAfter()
  } else if (menu === TableMenu.INSERT_ROW) {
    editor.commands.addRowAfter()
  } else if (menu === TableMenu.DELETE_COLUMN) {
    editor.commands.deleteColumn()
  } else if (menu === TableMenu.DELETE_ROW) {
    editor.commands.deleteRow()
  } else if (menu === TableMenu.MERGE_CELL) {
    editor.commands.mergeCells()
  } else if (menu === TableMenu.SPLIT_CELL) {
    editor.commands.splitCell()
  } else if (menu === TableMenu.DELETE_TABLE) {
    editor.commands.deleteTable()
  }
}

function onScribbleEditorMenuClicked(scribbleEditorMode) {
  data.scribbleEditorMode = scribbleEditorMode

  emit('scribbleEditorModeChanged', data.scribbleEditorMode)
}

function getScribbleEditorMenuStyle(scribbleEditorMode) {
  if (scribbleEditorMode) {
    return data.scribbleEditorMode ? '#ffffff' : '#aaaaaa'
  } else {
    return data.scribbleEditorMode ? '#aaaaaa' : '#ffffff'
  }
}

function setCurrentTextAttribute({alignment, fontSize}) {
  if (alignment) {
    data.currentTextAlignment = alignment
  }

  if (fontSize) {
    data.currentFontSize = fontSize.replace("pt", "")
  }
}

function onFontSizeClicked(fontSize) {
  if (activeEditor.value) {
    data.currentFontSize = fontSize
    activeEditor.value.chain().focus().setFontSize(fontSize + 'pt').run()
  }
}

function onTextAlignmentChanged(alignment) {
  if (activeEditor.value) {
    data.currentTextAlignment = alignment
    activeEditor.value.chain().focus().setTextAlign(alignment).run()
  }
}

function getFontAwesomeAlignmentIconName(alignment) {
  switch (alignment) {
    case 'left':
      return 'align-left'
    case 'center':
      return 'align-justify'
    case 'right':
      return 'align-right'
  }
}

function createTable() {
  if (activeEditor.value) {
    activeEditor.value.chain().focus().insertTable({rows: 2, cols: 2, withHeaderRow: false}).run()
  }
}

function onTableCellColorSelected(color) {
  if (activeEditor.value) {
    activeEditor.value.commands.setCellAttribute('backgroundColor', color)
  }
}

function getButtonSourceSvg(cmd) {
  console.log(cmd)
  switch (cmd) {
    case 'InsertColumn':
      return '@/assets/svg/insert-column.svg'
  }
}

function able(menu) {
  const editor = activeEditor.value

  if (menu.cmd === 'MergeCells') {
    return editor.can().mergeCells()
  } else if (menu.cmd == 'SplitCell') {
    return editor.can().splitCell()
  }

  return true
}

async function onImageFileSelected(e) {
  if (!activeEditor) {
    return
  }

  const editor = activeEditor.value
  const file = e.target.files[0]
  if (file) {
    const blobUrl = await imageLogic.loadBlobUrlFromImageFile(file)
    editor.commands.setFabricNode({src: blobUrl, width: 'auto'})
  }
}

</script>
