import {
    Mark,
    Extension,
    mergeAttributes,
    getAttributes,
    elementFromString,
    findParentNodeClosestToPos
} from "@tiptap/core";
import {Plugin} from '@tiptap/pm/state'

const Conceal = Mark.create({
    name: 'conceal',

    addOptions: { revealColor: 'red', concealColor: 'transparent', borderColor: "#000000"},

    addAttributes() {
        const { options } = this
        // Return an object with attribute configuration
        return {
            conceal: {
                // parseHTML: element => element.getAttribute('conceal') || false,
                renderHTML(attributes) {
                    return {
                        style: `background-color: white; color: ${attributes?.conceal ? options.concealColor : options.revealColor}`
                    }
                },
            },
            borderColor: {
                parseHTML: element => element.style.borderColor,
                renderHTML(attributes) {
                    return {
                        style: `border: ${attributes.borderColor ? attributes.borderColor : 'black'} 1px dotted`
                    }
                },
            }
        }
    },

    parseHTML() {
        return [
            {
                tag: 'conceal',
            },
        ]
    },

    renderHTML({HTMLAttributes}) {
        return ['conceal', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },

    addCommands() {
        return {
            setConceal: attributes => ({commands}) => {
                return commands.setMark(this.name, attributes);
            },
            unsetConceal: () => ({commands}) => {
                return commands.unsetMark(this.name);
            },
            toggleConceal: () => ({commands}) => {
                return commands.toggleMark(this.name);
            },
        };
    },

    // 편집모드에서는 conceal 을 클릭해서 reveal 하는 동작을 하지 않도록 스펙 변경함
    // 이유 : 모바일은 편집모드에서 conceal 을 클릭하면 키보드가 올라와서 동작이 애매하다
    // scribble 모드메서만 scribble handler 에 의해서 reveal 이 동작하도록 한다.
    // addProseMirrorPlugins() {
    //     const { editor, options } = this
    //
    //     const plugins = []
    //
    //     plugins.push(
    //         new Plugin({
    //             props: {
    //                 handleClick(view, pos, event) {
    //                     if (event && event.target?.nodeName === 'CONCEAL') {
    //                         const state = view.state
    //                         // 이상한 코드 이유는
    //                         // 예를 들어 '<conceal>집현전</conceal> 학사들을' 일 경우
    //                         // 집현전 의 '전' 글자 위에서도 마우스 포인터가 pointer 로 표시되고 handleClick 으로도 들어오는데
    //                         // 이 때 pos 로 node 를 찾으면 ' 학사들을' 노드가 나오게 된다
    //                         // 이를 보정하기 위해서 노드는 찾았는데 conceal 을 못찾으면 한칸 앞으로 가서 다시 찾도록 했다
    //
    //                         let correctPos = pos
    //                         const nodeAtPos = state.doc.nodeAt(correctPos)
    //                         let correctNode = nodeAtPos
    //                         if (nodeAtPos) {
    //                             let concealMark = nodeAtPos.marks.find(mark => mark.type.name === 'conceal')
    //                             if (!concealMark) {
    //                                 const prevPos = pos - 1
    //                                 let prevNode = state.doc.nodeAt(prevPos)
    //                                 if (prevNode) {
    //                                     correctPos = prevPos
    //                                     correctNode = prevNode
    //                                     concealMark = prevNode.marks.find(mark => mark.type.name === 'conceal')
    //                                 }
    //                             }
    //
    //                             if (concealMark) {
    //                                 const $pos = state.doc.resolve(correctPos)
    //                                 const from = correctPos - $pos.textOffset
    //                                 const to = from + correctNode.nodeSize
    //
    //                                 const center = Math.round(from + (to - from) / 2)
    //                                 editor.commands.setTextSelection({from:center, to:center})
    //                                 editor.emit("onConcealMarkClick", { from, to, text: correctNode.text, attrs: concealMark.attrs })
    //                             }
    //                         }
    //                     }
    //                 }
    //             }
    //         })
    //     )
    //
    //     return plugins
    // }
})

export default Conceal
