import axios from "axios";

export default {

    async createNote(notebookId, title) {
        console.log('request create note ' + notebookId + ' ' + title)
        try {
            const response = await axios.post('/api/v1/notes', {
                notebookId: notebookId,
                title: title
            })
            if (response.status === 200) {
                return response.data.result
            }
        } catch (e) {
            console.log(e)
        }

        return null
    },

    async getNoteWithSections(noteId) {
        console.log('call api : get note')
        try {
            const response = await axios.get(`/api/v1/notes/note/${noteId}`)
            if (response.status === 200 && response.data) {
                return response.data.result
            }
        } catch (e) {
            console.log(e);
        }

        return null
    },

    async updateNoteTitle(noteId, notebookId, title) {
        console.log('call update note api')
        try {
            const response = await axios.put('/api/v1/notes', {
                id: noteId,
                notebookId: notebookId,
                title: title
            })
            if (response.status === 200) {
                return response.data.result
            }
        } catch (e) {
            console.log(e)
        }

        return null
    },

    async delete(id) {
        try {
            const response = await axios.delete('/api/v1/notes/' + id)
            if (response.status === 200) {
                return response.data.result
            }
        } catch (e) {
            console.log(e)
        }

        return null
    }
}
