import TableRow from '@tiptap/extension-table-row'
import {Plugin, PluginKey, Transaction, EditorState} from "prosemirror-state";
import { tableNodeTypes, TableView, TableMap } from 'prosemirror-tables'
import { EditorView, Decoration, DecorationSet } from 'prosemirror-view'

const rowResizingPluginKey = new PluginKey<ResizeState>('tableRowResizing')

type Dragging = { startY: number; startHeight: number };

class ResizeState {
    constructor(public activeHandle: number, public dragging: Dragging | false) {
    }

    apply(tr: Transaction): ResizeState {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const state = this;
        const action = tr.getMeta(rowResizingPluginKey);
        if (action && action.setHandle != null)
            return new ResizeState(action.setHandle, false);
        if (action && action.setDragging !== undefined)
            return new ResizeState(state.activeHandle, action.setDragging);
        if (state.activeHandle > -1 && tr.docChanged) {
            let handle = tr.mapping.map(state.activeHandle, -1);
            if (!pointsAtCell(tr.doc.resolve(handle))) {
                handle = -1;
            }
            return new ResizeState(handle, state.dragging);
        }
        return state;
    }
}

function updateHandle(view: EditorView, value: number): void {
    view.dispatch(
        view.state.tr.setMeta(rowResizingPluginKey, { setHandle: value }),
    );
}

function cellAround($pos: ResolvedPos): ResolvedPos | null {
    for (let d = $pos.depth - 1; d > 0; d--)
        if ($pos.node(d).type.spec.tableRole == 'row')
            return $pos.node(0).resolve($pos.before(d + 1));
    return null;
}

function pointsAtCell($pos: ResolvedPos): boolean {
    return $pos.parent.type.spec.tableRole == 'row' && !!$pos.nodeAfter;
}

function domCellAround(target: HTMLElement | null): HTMLElement | null {
    while (target && target.nodeName != 'TD' && target.nodeName != 'TH')
        target =
            target.classList && target.classList.contains('ProseMirror')
                ? null
                : (target.parentNode as HTMLElement);
    return target;
}

function edgeCell(
    view: EditorView,
    event: MouseEvent,
    side: 'top' | 'bottom',
    handleWidth: number,
): number {
    // posAtCoords returns inconsistent positions when cursor is moving
    // across a collapsed table border. Use an offset to adjust the
    // target viewport coordinates away from the table border.
    const offset = side == 'bottom' ? -handleWidth : handleWidth;
    const found = view.posAtCoords({
        left: event.clientX,
        top: event.clientY + offset,
    });
    if (!found) return -1;
    const { pos } = found;
    const $cell = cellAround(view.state.doc.resolve(pos));
    // console.log('edge cell for row ' + $cell)
    if (!$cell) return -1;
    if (side == 'bottom') {
        // console.log('bottom ' + $cell.pos)
        return $cell.pos;
    }
    const map = TableMap.get($cell.node(-1)), start = $cell.start(-1);
    // console.log('map ' + map.map)
    // console.log('cell pos ' + $cell.pos + ' start ' + start)
    const index = map.map.indexOf($cell.pos - start);
    const isFirstRow = map.map.length / map.height > index
    // console.log('isFirstRow ' + isFirstRow)
    if (isFirstRow) {
        return -1
    }

    const upperRowIndex = index - map.width
    const ret = isFirstRow ? -1 : start + map.map[upperRowIndex];
    // console.log('top ' + ret)
    return ret
}

// Find the top side of the cell at the given position.
function upperRowCount(map: TableMap, pos: number): number {

    for (let i = 0; i < map.map.length; i++) {
        if (map.map[i] == pos) {
            return Math.trunc(i / map.width);
        }
    }
    throw new RangeError(`No cell with offset ${pos} found`);
}

function handleDecorations(
    state: EditorState,
    cell: number,
): DecorationSet {
    // console.log('cell for row ' + cell)
    const decorations = [];
    const $cell = state.doc.resolve(cell);
    const table = $cell.node(-1);
    if (!table) {
        return DecorationSet.empty;
    }
    // console.log(table)
    const map = TableMap.get(table);
    // console.log(map.map)
    // console.log($cell)
    const start = $cell.start(-1);
    // console.log('start  ' + start)
    // console.log('pos ' + ($cell.pos-start))
    let row = upperRowCount(map, $cell.pos - start) + $cell.nodeAfter!.attrs.rowspan;
    // console.log('col ' + col)
    // console.log('row ' + row)
    // console.log('row ' + row)
    // const col = map.colCount($cell.pos - start) + $cell.nodeAfter!.attrs.colspan;
    for (let col = 0; col < map.width; col++) {
        const index = col + ((row-1) * map.width)
        // console.log(index)
        // For positions that have either a different cell or the end
        // of the table to their right, and either the top of the table or
        // a different cell above them, add a decoration
        // console.log(index)
        if (
            (row == map.height || map.map[index] != map.map[index + 1]) &&
            (col == 0 || map.map[index] != map.map[index - map.height])
        ) {
            const cellPos = map.map[index];
            const pos = start + cellPos + table.nodeAt(cellPos)!.nodeSize - 1;
            const dom = document.createElement('div');
            dom.className = 'row-resize-handle';
            decorations.push(Decoration.widget(pos, dom));
        }
    }
    return DecorationSet.create(state.doc, decorations);
}

function handleMouseMove(
    view: EditorView,
    event: MouseEvent,
    handleWidth: number,
    rowMinHeight: number,
    lastRowResizable: boolean,
): void {
    const pluginState = rowResizingPluginKey.getState(view.state);
    if (!pluginState) return;

    if (!pluginState.dragging) {
        const target = domCellAround(event.target as HTMLElement);
        let cell = -1;
        if (target) {
            const { top, bottom } = target.getBoundingClientRect();
            if (event.clientY - top <= handleWidth)
                cell = edgeCell(view, event, 'top', handleWidth);
            else if (bottom - event.clientY <= handleWidth)
                cell = edgeCell(view, event, 'bottom', handleWidth);

            // console.log('cell row row' + cell)
        }

        // console.log('cell for row ' + cell)

        if (cell != pluginState.activeHandle) {
            if (!lastRowResizable && cell !== -1) {
                const $cell = view.state.doc.resolve(cell);
                const table = $cell.node(-1);
                const map = TableMap.get(table);
                const tableStart = $cell.start(-1);
                const row =
                    rowCount(map, $cell.pos - tableStart) +
                    $cell.nodeAfter!.attrs.rowspan -
                    1;

                if (row == map.height - 1) {
                    return;
                }
            }

            updateHandle(view, cell);
        }
    }
}

function currentRowHeightFromCellHeight(
    view: EditorView,
    cellPos: number,
    { rowspan, rowheight }: Attrs,
): number {
    // console.log('cell pos ' + cellPos + ' height ' + rowheight)
    // console.log('rowheight ' + rowheight)
    const height = rowheight && rowheight[rowheight.length - 1];
    // console.log('height ' + height)
    if (height) return height;
    if (!rowspan) {
        rowspan = 1
    }
    // const domTr = view.domAtPos(cellPos);
    // console.log('domTr at pos ' + cellPos + ' ---')
    // console.log(domTr) // tr dom
    // console.log('--- domTr')
    // const domTDNode = domTr.node.childNodes[domTr.offset] as HTMLElement;
    // console.log('domTDNode at offset ' + domTr.offset + ' ----' )
    // console.log(domTDNode) // td node
    // console.log('--- domTDNode')

    // const $cell = view.state.doc.resolve(cellPos);
    // const start = $cell.start(-1)
    // console.log(start)
    // console.log($cell)
    const cellDom = view.domAtPos(cellPos).node;
    // console.log(cellDom.pos)
    // console.log('h ' + cellDom.offsetHeight)
    // let domH = tableRowDom.offsetHeight
    // console.log('off h ' + domH)

    let domHeight = cellDom.offsetHeight, parts = rowspan;
    // console.log('domHeight ' + domTDNode.offsetHeight)
    if (rowheight)
        for (let i = 0; i < rowspan; i++)
            // console.log('colspan ' + colspan)
            if (rowheight[i]) {
                // console.log('index ' + i)
                domHeight -= rowheight[i];
                parts--;
            }
    return domHeight / parts;
}

function draggedHeight(
    dragging: Dragging,
    event: MouseEvent,
    rowMinHeight: number,
): number {
    const offset = event.clientY - dragging.startY;
    // console.log(dragging.startHeight)
    return Math.max(rowMinHeight, dragging.startHeight + offset);
}

/*
const $cell = view.state.doc.resolve(cellPos);
    const tableRow = $cell.node($cell.depth);
    const tableRowDom = view.domAtPos($cell.depth).node;
 */

function displayRowHeight(
    view: EditorView,
    cellPos: number,
    height: number,
    rowMinHeight: number,
): void {
    // console.log('cell ' + cellPos + ' height ' + height)
    const $cell = view.state.doc.resolve(cellPos);
    // console.log($cell)
    const table = $cell.node(-1)
    const trNode = $cell.node($cell.depth)
    // console.log(cellPos + ' ' + $cell.depth)
    // console.log(view.domAtPos($cell.depth))
    const trDom = view.domAtPos($cell.depth).node

    // const $cell = view.state.doc.resolve(cellPos);
    // console.log($cell)
    // const tableRow = $cell.node($cell.depth);
    // const trNode = view.domAtPos($cell.depth).node;
    // console.log('tr ------')
    // console.log(tableRowDom)
    // console.log('-------- tr')

    // const table = $cell.node(-1), start = $cell.start(-1);
    // console.log(table.firstChild)
    // const map = TableMap.get(table);
    // const row = upperRowCount(map, $cell.pos - start) + $cell.nodeAfter!.attrs.rowspan - 1;
    // const row =
    //     TableMap.get(table).rowCount($cell.pos - start) +
    //     $cell.nodeAfter!.attrs.rowspan -
    //     1;
    let tableDom: Node | null = view.domAtPos($cell.start(-1)).node;
    while (tableDom && tableDom.nodeName != 'TABLE') {
        tableDom = tableDom.parentNode;
    }
    if (!tableDom) return;
    // console.log(dom.firstChild)
    updateRowsOnResize(
        view,
        tableDom as HTMLTableElement,
        trNode,
        trDom,
        rowMinHeight,
        height,
    );
}

export function updateRowsOnResize(
    view: EditorView,
    table: HTMLTableElement,
    trNode: Node,
    trDom: HTMLTableRowElement,
    rowMinHeight: number,
    overrideHeight: number,
): void {

    let totalHeight = 0;
    const rowspan = 1
    const start = $cell.start(-1);

    // style=..px 로 잘 설정되지만 화면이 Refresh 되지 않는다
    /*
    for (let j = 0; j < rowspan; j++) {
        const cssHeight = overrideHeight + 'px'
        if (trDom.style.height != cssHeight) {
            trDom.style.height = cssHeight
        }
    }
     */

    const attrs = trNode.attrs
    tr.setNodeMarkup(start + rowPos, null, { ...attrs, rowheight: overrideHeight });
    if (tr.docChanged) view.dispatch(tr);


    // console.log(table)
    // if (fixedHeight) {
    //     table.style.height = totalHeight + 'px';
    //     table.style.minHeight = '';
    // } else {
    table.style.height = '';
    table.style.minHeight = totalHeight + 'px';
    // }
}

function updateRowHeight(
    view: EditorView,
    cellPos: number,
    height: number,
): void {
    const $cell = view.state.doc.resolve(cellPos);
    const tr = view.state.tr;

    const rowPos = cellPos - $cell.parentOffset - 1
    const rowNode = view.state.doc.nodeAt(rowPos);
    const attrs = rowNode.attrs
    tr.setNodeMarkup(rowPos, null, { ...attrs, rowheight: height });
    if (tr.docChanged) view.dispatch(tr);
}

function zeroes(n: number): 0[] {
    return Array(n).fill(0);
}

function handleMouseDown(
    view: EditorView,
    event: MouseEvent,
    rowMinHeight: number,
): boolean {
    const pluginState = rowResizingPluginKey.getState(view.state);
    if (!pluginState || pluginState.activeHandle == -1 || pluginState.dragging)
        return false;

    // const cell = view.state.doc.nodeAt(pluginState.activeHandle)!;
    const $cell = view.state.doc.resolve(pluginState.activeHandle);
    const tableRow = $cell.node($cell.depth);
    if (!tableRow) {
        return
    }

    // console.log(tableRow.attrs)
    const height = currentRowHeightFromCellHeight(view, pluginState.activeHandle, tableRow.attrs);
    // console.log(height)
    view.dispatch(
        view.state.tr.setMeta(rowResizingPluginKey, {
            setDragging: { startY: event.clientY, startHeight: height },
        }),
    );

    function finish(event: MouseEvent) {
        window.removeEventListener('mouseup', finish);
        window.removeEventListener('mousemove', move);
        const pluginState = rowResizingPluginKey.getState(view.state);
        if (pluginState?.dragging) {
            updateRowHeight(
                view,
                pluginState.activeHandle,
                draggedHeight(pluginState.dragging, event, rowMinHeight),
            );
            view.dispatch(
                view.state.tr.setMeta(rowResizingPluginKey, { setDragging: null }),
            );
        }
    }

    function move(event: MouseEvent): void {
        if (!event.which) return finish(event);
        const pluginState = rowResizingPluginKey.getState(view.state);
        if (!pluginState) return;
        if (pluginState.dragging) {
            const dragged = draggedHeight(pluginState.dragging, event, rowMinHeight);
            // displayRowHeight(view, pluginState.activeHandle, dragged, rowMinHeight);
            updateRowHeight(view, pluginState.activeHandle, dragged)
        }
    }

    window.addEventListener('mouseup', finish);
    window.addEventListener('mousemove', move);
    event.preventDefault();
    return true;
}

function handleMouseLeave(view: EditorView): void {
    const pluginState = rowResizingPluginKey.getState(view.state);
    if (pluginState && pluginState.activeHandle > -1 && !pluginState.dragging)
        updateHandle(view, -1);
}

const TableRowEx = TableRow.extend({
    addAttributes() {
        return {
            rowheight: {
                default: null,
                renderHTML(attributes) {
                    if (attributes.rowheight) {
                        return {
                            // height: attributes.rowheight
                            style: `height: ${attributes.rowheight}px`
                        }
                    }
                }
            }
        }
    },

    addProseMirrorPlugins() {
        const {editor, options} = this

        const plugins = []

        const plugin = new Plugin<ResizeState>({
                key: rowResizingPluginKey,
                state: {
                    init(_, state) {
                        new ResizeState(-1, false);
                        plugin.spec!.props!.nodeViews![
                            tableNodeTypes(state.schema).table.name
                            ] = (node, view) => new TableView(node, 25, view);
                        return new ResizeState(-1, false);
                    },
                    apply(tr, prev) {
                        return prev.apply(tr);
                    },
                },
                props: {
                    attributes: (state): Record<string, string> => {
                        const pluginState = rowResizingPluginKey.getState(state);
                        return pluginState && pluginState.activeHandle > -1
                            ? {class: 'row-resize-cursor'}
                            : {};
                    },

                    handleDOMEvents: {
                        mousemove: (view, event) => {
                            handleMouseMove(
                                view,
                                event,
                                5,
                                25,
                                true,
                            );
                        },
                        // mouseleave: (view) => {
                        //     handleMouseLeave(view);
                        // },
                        mousedown: (view, event) => {
                            handleMouseDown(view, event, 25);
                        },
                    },

                    decorations: (state) => {
                        const pluginState = rowResizingPluginKey.getState(state);
                        if (pluginState && pluginState.activeHandle > -1) {
                            return handleDecorations(state, pluginState.activeHandle);
                        }
                    },

                    nodeViews: {},
                },
            })

        plugins.push(plugin)
        return plugins
    },
})

export default TableRowEx
