import {fabric} from 'fabric';
import BaseHandler from "@/components/ImageEditor/controls/draw/BaseHandler";

class SelectHandler extends BaseHandler {

    constructor(canvas: fabric.Canvas) {
        super(canvas, null)
        this.init()
    }

    init() {
        const {canvas} = this
        canvas.on('selection:created', (o) => {
            this.onSelectionUpdated(o.selected)
        })

        canvas.on('selection:updated', (o) => {
            this.onSelectionUpdated(o.selected)
        })
    }

    prepare() {
        this.canvas.isDrawingMode = false
        this.canvas.selectionBorderColor = 'rgba(255, 255, 255, 0.5)'
        this.canvas.selectionColor = 'rgba(100, 100, 255, 0.3)'
    }

    mouseDown(ie) {}

    mouseMove(ie) {}

    mouseUp(ie) {}

    onSelectionUpdated(objs) {
    }
}

export default SelectHandler
