<template>
  <NodeViewWrapper
      class="fabric-resizer"
      :class="{
        'ProseMirror-selectednode': props.selected, // selected 는 nodeViewProps 로부터
      }"
      :style="{width: canvasWidth}">

    <canvas ref="canvasRef">
    </canvas>

    <FabricResizeComponent v-if="isLoaded"
                           :fabricRef="canvasRef"
                           @resizeFinished="onResizeFinished"
    />

    <div class="touch-overlay" style="width: 100%; height: 100%; background: transparent; position: absolute; left: 0; top: 0;">
    </div>
  </NodeViewWrapper>
</template>

<script setup>
import {fabric} from 'fabric'
import {NodeViewWrapper, nodeViewProps} from '@tiptap/vue-3'
import CanvasComposer from "@/components/ImageEditor/controls/core";
import WorkspacePlugin from "@/components/ImageEditor/controls/plugin/WorkspacePlugin";
import {lazyExec} from "@/utils/util";
import DrawPlugin from "@/components/ImageEditor/controls/plugin/DrawPlugin";
import {ViewCommand} from "@/components/ImageEditor/controls/draw/DrawCommand";
import FabricResizeComponent from "@/components/HtmlEditor/FabricNode/FabricResizeComponent.vue";
import {computed, provide, watch, ref, onMounted, onUnmounted, reactive} from "vue";
import {useStore} from 'vuex'
import {convertRemToPixels} from "@/utils/util";
import jsonFile from "@/assets/fabric1.json";

const props = defineProps({
  ...nodeViewProps
});

provide('tiptapProps', props);

const canvasWidth = computed(() => {
  return canvas.value ? canvas.value.width : '90%'
});

const imageSource = computed(() => {
  return props.node.attrs.src
});

const attrWidth = computed(() => {
  return props.node.attrs.width
})

watch(imageSource, () => {
  lazyExec(loadData, 50)
})
watch(attrWidth, () => onAttrWidthChanged())


const store = useStore();
const canvasRef = ref(null)
const isLoaded = ref(false)
const canvas = ref(null)

const data = reactive({
  initialImageUrl: null,
  isScribbleMode: false,
  revealingTimerId: null
})

let canvasCore = null
let workspacePlugin = null
let drawPlugin = null

onMounted(() => {
  const attrWidthString = props.node.attrs.width
  let canvasWidth = 0
  if (attrWidthString === 'auto') {
    canvasWidth = canvasRef.value.getBoundingClientRect().width
  } else {
    canvasWidth = getNumberOnly(attrWidthString)
  }

  fabric.Object.NUM_FRACTION_DIGITS = 8
  canvas.value = new fabric.Canvas(canvasRef.value, {
    isDrawingMode: false,
    stopContextMenu: true, // right click 시에 브라우저 기본 메뉴 표시 방지
    targetFindTolerance: 10,
    selection: false,
    width: canvasWidth,
    height: 0, // image width 기준으로 이미지 비율에 따라서 설정된다.
  })

  initPlugins()
  lazyExec(loadData, 200)

  props.editor.on('changeScribbleMode', (value) => {
    data.isScribbleMode = value
    revealAllConcealRectsIfItsHtmlEditorMode()
  })

  props.editor.on('changeConcealRectBorderColor', (color) =>{
    setConcealBorderColor(color)
  })
});

onUnmounted(() => {
  workspacePlugin.dispose()
  workspacePlugin = null
  drawPlugin = null
  canvas.value = null
})

function initPlugins() {
  canvasCore = new CanvasComposer(canvas.value)
  workspacePlugin = canvasCore.addPlugin(WorkspacePlugin)
  drawPlugin = canvasCore.addPlugin(DrawPlugin, {drawProperty: null, handlers: ['REVEAL']})
}

function loadData() {
  // image editor에서 src 변경 => watch => loadData 도중에 => unmount 되는 경우가 있다
  // 이 때는 다시 mount 되면서 loadData 가 호출되므로 여기서는 리턴한다.
  if (!workspacePlugin) {
    return
  }

  if (props.node.attrs.src) {
    const src = props.node.attrs.src
    if (src.endsWith('.json')) {
      const json = JSON.stringify(jsonFile)
      workspacePlugin.loadFromJson(json, true, false, afterLoaded)
      store.dispatch('images/saveEditingImage', {
        imageUrl: src,
        imageJson: json
      })

      return
    }

    data.initialImageUrl = src
    const savedEditingImageJson = store.getters['images/getSavedImage'](src)
    if (savedEditingImageJson) {
      // HtmlEditor 위에서 image (canvas) 는 가로를 기준으로 한다.
      workspacePlugin.loadFromJson(savedEditingImageJson, true, false, afterLoaded)
    } else {
      workspacePlugin.addBackgroundImageWithCanvasFitToImage(
          {
            url: src, widthFit: true,
          },
          afterLoaded
      )
    }
  }
}

function afterLoaded() {
  props.editor.emit('onFabricNodeCanvasLoaded', {canvasEl: canvasRef.value, canvas: canvas.value})
  isLoaded.value = true
}

function revealAllConcealRectsIfItsHtmlEditorMode() {
  if (canvas && canvas.value) {
    const color = data.isScribbleMode ? 'rgba(255,255,255,255)' : 'rgba(255,255,255,0)'
    canvas.value.getObjects().filter(obj => obj.name === 'ConcealRect')
        .forEach(obj => {
          obj.set({
            fill: color
          })
        })
    canvas.value.requestRenderAll()
  }
}

function getNumberOnly(pixelString) {
  if (!pixelString) {
    return 0
  }
  const amount = pixelString.replace('px', '')
  return parseInt(amount)
}

function fireMouseDownEventToCanvas(ie) {
  const event = new MouseEvent('mousedown', {
    clientX: ie.e.clientX,
    clientY: ie.e.clientY,
  });

  canvas.value.upperCanvasEl.dispatchEvent(event);
}

function onAttrWidthChanged() {
  const newWidth = getNumberOnly(props.node.attrs.width)
  const newHeight = canvas.value.height * newWidth / canvas.value.width

  workspacePlugin.changeCanvasSize(newWidth, newHeight)
}

function onResizeFinished() {
  props.editor.emit('onFabricNodeCanvasScaleFinished', {canvasEl: canvasRef.value, canvas: canvas.value})
}

function updateStrokeColorInJson(id, color) {
  const src = props.node.attrs.src
  const savedEditingImageJson = store.getters['images/getSavedImage'](src)
  const jsonData = JSON.parse(savedEditingImageJson)
  const objects = jsonData.objects
  const targetObject = objects.find(obj => obj.id === id)
  if (targetObject) {
    targetObject.stroke = color

    store.dispatch('images/saveEditingImage', {
      imageUrl: src,
      imageJson: JSON.stringify(jsonData)
    })
  }
}

function removeObjectInJson(id) {
  const src = props.node.attrs.src
  const savedEditingImageJson = store.getters['images/getSavedImage'](src)
  const jsonData = JSON.parse(savedEditingImageJson)
  const objects = jsonData.objects
  const index = objects.findIndex(obj => obj.id === id)
  if (index > -1) {
    objects.splice(index, 1)
    store.dispatch('images/saveEditingImage', {
      imageUrl: src,
      imageJson: JSON.stringify(jsonData)
    })
  }
}

</script>

<style lang="scss">
.fabric-resizer {
  max-width: 100%;
  position: relative;
  width: fit-content;

  &.inline {
    display: inline-block;
  }

  .maxw100 {
    width: 100%;
  }
}

.ProseMirror-selectednode {
  .touch-overlay {
    display: none;
  }
}

</style>
