<template>
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <line x1="0" y1="10" x2="20" y2="10" :style=style />
  </svg>
</template>

<script>
export default {
  props: {
    strokeWidth: {
      type: [Number, String],
      default: 0.5
    },
    stroke: {
      type: String,
      default: '#000000'
    }
  },
  computed: {
    style() {
      return `stroke:${this.stroke}; stroke-width:${this.strokeWidth}`
    }
  }
}
</script>
