export enum SelectEvent {
    ONE = 'selectOne',
    MULTI = 'selectMultiple',
    CANCEL = 'selectCancel',
}

export enum ObjectEvent {
    ADDED = 'added',
    CUSTOM_ADDED = 'custom_added',
    FREEDRAW_ADDED = 'freedraw_added',
    REMOVED = 'removed',
    MODIFIED = 'modified'
}
