<template>
  Image Editor
  <div id="tippyTooltipButton">
    <button class="bg-blue-400" @click="btnClicked">Some Button</button>
  </div>
  <div class="fabric">
    <button @click="toJson" class="bg-gray-200">To Json</button>
    <button @click="toImage" class="bg-gray-200">To Image</button>
    <canvas ref="canvasRef" width="2000" height="1000">
    </canvas>
  </div>
</template>

<style scoped>
.fabric {
  display: flex;
  align-items: center;
  justify-content: center;
}

canvas {
  border: 1px solid #ababab;
  border-radius: 4px;
}
</style>

<script>
import {fabric} from 'fabric'
import jsonFile from "@/assets/sample.json"
import tippy from "tippy.js";

export default {

  data() {
    return {
      canvas: null,
      someImage: "https://avatars.githubusercontent.com/u/1935108?v=4",
    }
  },
  mounted() {
    this.canvas = new fabric.Canvas(this.$refs.canvasRef, {
      isDrawingMode: false
    })

    // canvas.on('object:scaling', this.onObjectScaled)

    // this.addFromJson()
    this.addSampleObjects()

    // this.createTippy()
  },
  methods: {
    onObjectScaled() {
      // const obj = canvas.getActiveObject()
      // const width = obj.width
      // const height = obj.height
      // const scaleX = obj.scaleX
      // const scaleY = obj.scaleY
      // obj.set( {
      //   width: width*scaleX,
      //   height: height*scaleY,
      //   scaleX: 1,
      //   scaleY: 1
      // })
    },
    async toJson() {
      const json = this.canvas.toDatalessJSON(["clipPath"])
      const out = JSON.stringify(json, null, "\t")
      const blob = new Blob([out], {type: "text/plain"})
      const clipboardItemData = {[blob.type]: blob}

      const blobURL = URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = blobURL
      link.download = "fabric_example"
      link.click()
      URL.revokeObjectURL(blobURL)
    },
    toImage() {
      const ext = "png"
      const base64 = this.canvas.toDataURL({
        format: ext,
        enableRetinaScaling: true
      })
      const link = document.createElement("a")
      link.href = base64
      link.download = `image_samble.${ext}`
      link.click()
    },
    addSampleObjects() {
      new fabric.Image.fromURL(this.someImage, (_img) => {
        const img = _img.set({left:50, top:50, width: _img.width, height: _img.height })
        this.canvas.add(img)
      })

      const rect = new fabric.Rect({
        top: 100,
        left: 100,
        width: 50,
        height: 50,
        fill: "transparent",
        stroke: '#0000ff',
        strokeWidth: 4,
        cornerSize: 10 // handle for control
      })
      this.canvas.add(rect)

      const textBox = new fabric.Textbox("FabricJS", {
        left: 20,
        right: 20,
        fill: 'black',
        stroke: 'black',
        strokeWidth: 2,
        fontSize: 24
      })

      this.canvas.add(textBox)

      const text = new fabric.IText('hello world', {
        left: 40,
        top: 40,
        fontFamily: '',
        fontSize: 20,
        fontWeight: 'bold', // normal, 400, 600, 800
        fill: '#ffffff',
        stroke: '#000000'
      });

      this.canvas.add(text);

      this.canvas.add(text)

      this.canvas.setActiveObject(rect)
    },
    addFromJson() {
      this.canvas.loadFromJSON(jsonFile, (e) => {
        this.canvas.renderAll()
      })
    },
    btnClicked() {
      const el = document.getElementById('tippyTooltipButton')
      console.log(el)
      const menu = tippy(el, { content: 'This is a tooltip!', placement: 'top',});
      console.log(menu)
    }
  }
}
</script>
