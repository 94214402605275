<template>
  <div>
    <div v-if="data.user">
      NoteSpace
    </div>
    <div v-else class="w-full h-screen text-center">
      <span class="bg-red-400"> 로그인 상태에서는 이 화면은 나오면 안된다.</span>
      <span class="mt-48 font-bold text-[2rem]">
        <br>
        공부에 진심
        <br>
        공부할 때 공노트
        <br>
      </span>
    </div>
  </div>
</template>

<script setup>

import {onMounted, reactive} from "vue";
import {useStore} from "vuex";

const store = useStore()

store.subscribe((mutation, state) => {
  if (mutation.type === "users/setUser") {
    data.user = mutation.payload
  }
})

const data = reactive({
  user: null
})

</script>
